<template>
  <div>
    <CostProfileSettings />
    <PricingConfigurationSettings class="border-t border-gray-50" />
    <TieredPricingConfigurationSettings class="border-t border-gray-50" />
  </div>
</template>

<script>
import CostProfileSettings from './components/CostProfileSettings.vue';
import PricingConfigurationSettings from './components/PricingConfigurationSettings.vue';
import TieredPricingConfigurationSettings from './components/TieredPricingConfigurationSettings.vue';

export default {
  name: 'CostSettings',

  components: {
    CostProfileSettings,
    PricingConfigurationSettings,
    TieredPricingConfigurationSettings,
  },
};
</script>
