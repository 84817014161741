<template>
  <SparkModal custom="w-[500px]" dismissable :model-value="show" @update:model-value="close" data-test="subscription-modal">
    <template #header>
      <div class="text-18 flex">
        <div class="font-bold" data-test="subscription-modal-title">Create Subscription</div>
        <div v-if="mode === 'selectDetails'" class="font-normal" data-test="subscription-modal-details-title">
          &nbsp;|&nbsp;{{ subscriptionTemplates[selectedTemplate].verbose_name }}
        </div>
      </div>
    </template>

    <template #default>
      <div v-if="mode === 'selectType'">
        <div class="flex gap-8 flex-col">
          <div class="pb-8" data-test="subscription-modal-description">
            The platform allows users to create customizable subscriptions for email notifications, enabling alerts to
            be sent when specific events occur. Notifications can be directed to the user configuring the subscription,
            another user within the platform, or an external email address.
          </div>
          <div class="flex gap-8 justify-center pb-16">
            <div
              class="border rounded-8 border-gray-50 p-20 cursor-pointer w-1/3 hover:border-primary-600 hover:bg-primary-50"
              :class="selectedTemplate === 'part_check_out' ? 'border-primary-300 bg-primary-50' : ''"
              @click="selectedTemplate = 'part_check_out'"
              data-test="subscription-modal-part-checkout-option"
            >
              <div class="pb-8">Order Checkout</div>
              <div class="text-13 text-gray-600">
                Everytime a customer checks out an order through PRP an Email is sent.
              </div>
            </div>
            <div
              class="border rounded-8 border-gray-50 p-20 cursor-pointer w-1/3 hover:border-primary-600 hover:bg-primary-50"
              :class="selectedTemplate === 'part_created' ? 'border-primary-300 bg-primary-50' : ''"
              @click="selectedTemplate = 'part_created'"
              data-test="subscription-modal-part-created-option"
            >
              <div class="pb-8">Part Uploaded</div>
              <div class="text-13 text-gray-600">Everytime a new part is uploaded an Email is sent.</div>
              <div class="text-11 text-gray-600 pt-4">
                You can decide if you only want to get this E-Mails for parts uploaded in PRP or every part.
              </div>
            </div>
            <div
              class="border rounded-8 border-gray-50 p-20 cursor-pointer w-1/3 hover:border-primary-600 hover:bg-primary-50"
              :class="selectedTemplate === 'part_status_update' ? 'border-primary-300 bg-primary-50' : ''"
              @click="selectedTemplate = 'part_status_update'"
              data-test="subscription-modal-part-status-update-option"
            >
              <div class="pb-8">Pipeline Status Update</div>
              <div class="text-13 text-gray-600">
                You can select special pipeline statuses, so that everytime the status is reached an Email is sent.
              </div>
              <div class="text-11 text-gray-600 pt-4">
                You can decide if you only want to get this E-Mails for parts uploaded in PRP or every part.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="mb-12">
        <div data-test="subscription-modal-email-container">
        <div class="text-16 pb-8 font-bold" data-test="subscription-modal-email-properties-title">E-Mail Properties</div>
        <div v-if="selectedTemplate === 'part_status_update'">
          <div class="text-13 text-gray-500 pt-8" data-test="subscription-modal-email-properties-status-label">Select a Status</div>
          <SparkSelect v-model="selectedPipelineState" :options="pipelineOptions" name="pipeline" data-test="subscription-modal-email-properties-status-dropdown" />
        </div>

        <div class="text-13 text-gray-500" data-test="subscription-modal-email-properties-subject-label">E-Mail Subject</div>
        <SparkInput v-model="name" name="name" custom="pb-2 mb-0" data-test="subscription-modal-email-properties-subject-input" />
        <div v-if="user.user_role === 'manager'" class="text-11 text-gray-400 mt-0 pt-0 pb-16">
          Customize the subject of the received E-Mail to your needs.
        </div>

        <SparkCheckbox v-if="selectedTemplate !== 'part_check_out'" v-model="is_external_only" name="external_only" />
        <div v-if="selectedTemplate !== 'part_check_out'" class="text-gray-500 pb-16">
          Only send E-Mails for parts uploaded in PRP
        </div>

        </div>
        <div data-test="subscription-modal-subscribers-container">
          <hr class="border-gray-200 pb-16" />
          <div class="text-16 pb-8 font-bold">Subscribers</div>
          <SparkCheckbox
          v-model="subscribeYourself"
          name="subscribe_yourself"
          @update:model-value="handleSubscribeYourself"
          />
          <div class="text-gray-500">Subscribe Yourself</div>
          
          <VueMultiselect
          v-model="selectedSubscriber"
          class="pb-12 pt-8 multiselect-input"
          :options="subscribers"
          name="subscriber"
          multiple
          placeholder="Select subscriber by user name"
          @remove="handleSubscriberRemoval"
          />
          <SparkInput
          v-model="sentToEMail"
          name="name"
          label=""
          placeholder="Send to external E-Mail Address"
          custom="pb-2 mb-0"
          data-test="subscription-modal-email-properties-external-email-input"
          />
          <div v-if="user.user_role === 'manager'" class="text-11 text-gray-400 mt-0">
            Enter recipient email addresses, semicolon separated.
          </div>
          <div v-if="email_regex_error != ''" class="text-red-500 text-11">{{ email_regex_error }}</div>
          <hr class="border-gray-200 pb-16" />
        </div>
      </div>
        <div class="flex" :class="{ 'justify-between': mode === 'selectDetails', 'justify-end': mode === 'selectType' }">
          <SparkButton v-if="mode === 'selectDetails'" variant="outlined" @click="mode = 'selectType'">
            <div v-text="'Back'" />
          </SparkButton>
        <div class="flex gap-16">
          <SparkButton variant="outlined" @click="close">
            <div v-text="'Cancel'" />
          </SparkButton>
          <SparkButton variant="secondary" :disabled="!selectedTemplate" @click="action()" data-test="subscription-modal-next-button">
            <div v-text="mode === 'selectType' ? 'Next' : 'Create Subscription'" />
          </SparkButton>
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import VueMultiselect from 'vue-multiselect';
import { mapState, mapActions } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'SubscriptionModal',

  components: { SparkModal, SparkInput, SparkSelect, SparkButton, VueMultiselect, SparkCheckbox },

  props: {
    show: { type: Boolean },
  },

  emits: ['close'],

  data() {
    return {
      name: '',
      selectedTemplate: '',
      selectedSubscriber: [],
      sentToEMail: '',
      mode: 'selectType',
      selectedPipelineState: '',
      subscribeYourself: false,
      is_external_only: false,
      email_regex_error: '',
    };
  },

  computed: {
    ...mapState(['subscriptionTemplates', 'subscribers', 'user', 'pipeline']),

    pipelineOptions() {
      return this.pipeline.map(pipeline => ({
        value: pipeline.uid,
        label: pipeline.name,
      }));
    },
  },

  watch: {
    // subscriptionToEdit: {
    //   handler(newVal) {
    //     if (newVal !== null && newVal !== undefined && newVal !== '') {
    //       if (Object.keys(newVal).length > 0) {
    //         console.log(newVal);
    //         this.name = newVal?.name;
    //         this.selectedTemplate = newVal?.subscription_type;
    //         this.selectedSubscriber = newVal?.subscribers;
    //         this.sentToEMail = newVal?.email_subscribers;
    //         this.mode = 'Edit';
    //       }
    //     } else {
    //       this.mode = 'Create';
    //     }
    //   },

    //   deep: true,
    // },

    selectedTemplate: {
      handler(newVal) {
        if (newVal !== undefined && newVal !== '') {
          this.name = this.subscriptionTemplates[newVal].verbose_name;
        }
      },
    },
  },

  methods: {
    ...mapActions(['createSubscription', 'updateSubscription']),

    close() {
      this.name = '';
      this.selectedTemplate = '';
      this.selectedSubscriber = [];
      this.sentToEMail = '';
      this.mode = 'selectType';
      this.subscribeYourself = false;
      this.$emit('close');
    },

    action() {
      if (this.mode === 'selectType') {
        this.mode = 'selectDetails';
      } else {
        this.save();
      }
    },

    handleSubscribeYourself(value) {
      this.subscribeYourself = value;
      if (value) {
        if (!this.selectedSubscriber.includes(this.user.username)) {
          this.selectedSubscriber.push(this.user.username);
        }
      } else {
        this.handleSubscriberRemoval(this.user.username);
      }
    },

    async save() {
      let emailSubscribers = this.sentToEMail
        .split(';')
        .map(email => email.trim())
        .filter(email => email !== '');
      const invalidEmails = emailSubscribers.filter(email => {
        return !email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
      });

      if (invalidEmails.length > 0) {
        this.email_regex_error = 'Invalid email address(es) detected: ' + invalidEmails.join(', ');
        return;
      } else {
        this.email_regex_error = '';
      }

      let formData = {
        name: this.name,
        subscription_type: this.selectedTemplate,
        subscribers: this.selectedSubscriber,
        email_subscribers: emailSubscribers,
        condition_is_external: this.is_external_only,
      };
      if (this.selectedTemplate === 'part_status_update') {
        formData['condition_pipeline_status'] = this.selectedPipelineState;
      }

      await this.createSubscription(formData);

      this.close();
      this.email_regex_error = '';
    },

    handleSubscriberRemoval(removedSubscriber) {
      if (this.selectedSubscriber.includes(removedSubscriber)) {
        this.selectedSubscriber = this.selectedSubscriber.filter(subscriber => subscriber !== removedSubscriber);
      }
    },
  },
};
</script>

<style lang="scss">
.multiselect-input {
  .multiselect__tag {
    background: #b8ce45;
    color: white;
    font-size: 0.9375rem;
  }
}
</style>
