<template>
  <div class="bg-white border border-gray-100 mb-80 mr-8 relative rounded-8">
    <div class="overflow-x-auto">
      <table class="w-full border-collapse min-w-[1000px]">
        <thead class="h-56 text-16">
          <tr>
            <th class="w-56 min-w-56 max-w-56" />
            <template v-for="column in selectedComparisonTableColumns" :key="column.key">
              <th class="w-[1fr] border-l border-gray-50">
                <InvestigationTitle
                  :title="column.title"
                  :active-sorting="sortingName == column?.sorting"
                  :ascending="sortingAscending"
                  :sortby="column?.sorting"
                  @sort="sorting($event)"
                />
              </th>
            </template>
            <th class="w-64 min-w-64 max-w-64 border-l border-b border-gray-50 right-0 sticky bg-white z-30">
              <SelectColumns />
            </th>
          </tr>
        </thead>

        <tbody class="text-13 relative">
          <tr v-for="(key, index) in sortedChainIds" :key="key" class="h-64">
            <td v-if="processChains[key] === undefined" colspan="8"><div class="ml-16 text-gray-500">Deleted</div></td>
            <template v-else>
              <td
                class="min-w-56 max-w-56 border border-gray-50 hover:bg-gray-50 hover:cursor-pointer mr-auto"
                @click="selectProcessChain(key)"
              >
                <div class="flex items-center justify-center">
                  <input
                    type="checkbox"
                    :checked="activeInvestigationDetails?.uid === key"
                    class="h-16 w-16 rounded-6 border-gray-300 text-primary-600 focus:ring-primary-500"
                    @change.prevent
                  />
                </div>
              </td>
              <template v-for="column in selectedComparisonTableColumns" :key="column.key">
                <td
                  class="w-[300px] min-w-[100px] max-w-[500px] border border-gray-50 hover:bg-gray-50 hover:cursor-pointer"
                  @mouseenter="mouseHover[key] = column?.sorting"
                  @mouseleave="mouseHover[key] = ''"
                >
                  <component
                    :is="column.component"
                    :chain="processChains[key]"
                    editable
                    :mouse-hover="mouseHover[key]"
                  />
                </td>
              </template>

              <td
                class="min-w-64 max-w-64 border border-gray-50 right-0 sticky bg-white text-right"
                :style="{ 'z-index': 20 - index }"
              >
                <Checkout :chain="processChains[key]" />
              </td>
            </template>
          </tr>
          <tr>
            <td colspan="7" :title="lockedForUser ? lockedTitle : ''">
              <div
                :class="lockedForUser ? 'text-gray-700' : '  hover:cursor-pointer hover:text-primary-600'"
                class="font-bold p-12 w-fit"
                @click="showModal"
              >
                <SparkButton v-if="sortedChainIds.length === 0" variant="secondary" :disabled="lockedForUser">
                  <i v-if="lockedForUser" class="fas fa-lock pr-4" /><i v-else class="fas fa-plus pr-4" />Add production
                  option
                </SparkButton>
                <div v-else>
                  <i v-if="lockedForUser" class="fas fa-lock" /><i v-else class="fas fa-plus" /> Add production option
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <CreateProcessChainModal :show="showCreateProcessChainModal" @close="closeModal" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import SparkButton from '../../../SparkComponents/SparkButton.vue';

import CreateProcessChainModal from './CreateProcessChainModal.vue';
import InvestigationTitle from './InvestigationTitle.vue';

import SelectColumns from '@/components/PartWorkflow/Comparison/components/SelectColumns.vue';
import Checkout from '@/components/PartWorkflow/Comparison/tablecells/actions/Checkout.vue';
import CarbonFootprint from '@/components/PartWorkflow/Comparison/tablecells/carbon/CarbonFootprint.vue';
import Cost from '@/components/PartWorkflow/Comparison/tablecells/cost/Cost.vue';
import Feasibility from '@/components/PartWorkflow/Comparison/tablecells/feasibility/Feasibility.vue';
import Leadtime from '@/components/PartWorkflow/Comparison/tablecells/leadtime/Leadtime.vue';
import LotSize from '@/components/PartWorkflow/Comparison/tablecells/lotsize/LotSize.vue';
import ProcessChainName from '@/components/PartWorkflow/Comparison/tablecells/name/ProcessChainName.vue';
import Price from '@/components/PartWorkflow/Comparison/tablecells/price/Price.vue';
import TrafficSignal from '@/components/PartWorkflow/Comparison/tablecells/trafficsignal/TrafficSignal.vue';
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import { analysisFinished } from '@/composables/processChainHandling.js';

export default {
  name: 'InvestigationTable',

  components: {
    ProcessChainName,
    LotSize,
    Feasibility,
    Cost,
    Leadtime,
    CarbonFootprint,
    Checkout,
    InvestigationTitle,
    CreateProcessChainModal,
    SparkButton,
    SelectColumns,
    SparkCheckbox,
    Price,
    TrafficSignal,
  },

  data() {
    return {
      sortedChainIds: [],
      mouseHover: {},
      lastSortedBy: null,
      lastSortingAscending: null,

      showCreateProcessChainModal: false,
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails', 'user']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    previousPageWasOverview() {
      let previousRoute = this.$router.options.history.state.back;
      let prevWasOverview = false;
      if (previousRoute != null) {
        let prev = previousRoute.toString();
        prevWasOverview = prev.includes('/ov/');
      }
      return prevWasOverview;
    },

    primary_process_chain_id() {
      return this.part?.primary_process_chain_id;
    },

    processChains() {
      return this.part.process_chains;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    sortingName() {
      return this.part.sorting_name;
    },

    sortingAscending() {
      return this.part.sorting_ascending;
    },

    comparisonTableColumns() {
      return this.user?.user_settings?.comparison_table_columns;
    },

    selectedComparisonTableColumns() {
      let shown = [];
      for (let col in this.comparisonTableColumns) {
        if (this.comparisonTableColumns[col].selected === true) {
          shown.push(this.comparisonTableColumns[col]);
        }
      }
      // sort selected Columns
      shown.sort((a, b) => {
        return a.index - b.index;
      });
      return shown;
    },
  },

  watch: {
    processChains: {
      handler(newVal, oldVal) {
        const newKeys = Object.keys(newVal);
        const oldKeys = Object.keys(oldVal);

        if (newKeys.length !== oldKeys.length || !newKeys.every(key => oldKeys.includes(key))) {
          // Only resort if the number of chains has changed or if there are new/removed chains
          this.sortByHeader(this.lastSortedBy || this.sortingName, this.lastSortingAscending ?? this.sortingAscending);
        } else {
          // If the chains are the same, just update the sorted order without changing it
          this.updateSortedOrder();
        }
      },

      deep: true,
    },
  },

  mounted() {
    this.initializeSort();
  },

  beforeMount() {
    if (!this.previousPageWasOverview) {
      this.$store.commit('changeInvestigationDetails', '');
    }
  },

  methods: {
    ...mapMutations(['changeInvestigationDetails']),

    selectProcessChain(uid) {
      this.changeInvestigationDetails({ uid: uid });
    },

    showModal() {
      if (!this.lockedForUser) {
        this.changeInvestigationDetails({ uid: '' });
        this.showCreateProcessChainModal = true;
      }
    },

    closeModal() {
      this.showCreateProcessChainModal = false;
    },

    analysisFinished(uid) {
      return analysisFinished(uid);
    },

    sorting(event) {
      this.$store.commit('setProcessChainSorting', { sorting_name: event.sortby, sorting_ascending: event.ascending });
      this.axiosInstance.put(`/api/v1/part/${this.part.part_id}/`, {
        sorting_name: event.sortby,
        sorting_ascending: event.ascending,
      });

      if (event.sortby) {
        this.sortByHeader(event.sortby, event.ascending);
      } else {
        this.sortByMain();
      }
    },

    sortByHeader(newSortBy, newSortingAscending) {
      this.lastSortedBy = newSortBy;
      this.lastSortingAscending = newSortingAscending;

      this.changeInvestigationDetails('');
      if (!this.previousPageWasOverview) {
        this.changeInvestigationDetails('');
      }

      this.updateSortedOrder();
    },

    updateSortedOrder() {
      const newSortBy = this.lastSortedBy || this.sortingName;
      const newSortingAscending = this.lastSortingAscending ?? this.sortingAscending;

      let loopUpValueUid = {};
      let process_chain_ids = Object.keys(this.part.process_chains);

      for (const [key, value] of Object.entries(this.processChains)) {
        if (process_chain_ids.includes(key)) {
          let v = null;
          if (newSortBy) {
            // Handle special cases first
            if (newSortBy === 'cost_pl' || newSortBy === 'cost.cost_pl') {
              v = value.cost?.cost_pl ?? null;
            } else if (newSortBy === 'market_price_pl' || newSortBy === 'market_price.market_price_pl') {
              v = value.market_price?.market_price_pl?.user ?? value.market_price?.market_price_pl?.calculated ?? null;
            } else {
              // For all other cases, traverse the object using path segments
              const pathSegments = newSortBy.split('.');
              v = pathSegments.reduce((obj, segment) => obj?.[segment], value) ?? null;
            }
          }
          loopUpValueUid[key] = v;
        }
      }

      let sortedEntries = Object.entries(loopUpValueUid).sort((a, b) => {
        const aVal = a[1];
        const bVal = b[1];

        // Handle null/undefined values
        if (aVal === null && bVal === null) return 0;
        if (aVal === null) return 1; // Always put null values at the end
        if (bVal === null) return -1;

        // Both values are numbers
        if (typeof aVal === 'number' && typeof bVal === 'number') {
          const comparison = aVal - bVal;
          return newSortingAscending ? comparison : -comparison;
        }

        // Convert to strings for comparison if not numbers
        const aStr = String(aVal).toLowerCase();
        const bStr = String(bVal).toLowerCase();
        const comparison = aStr.localeCompare(bStr);
        return newSortingAscending ? comparison : -comparison;
      });

      this.sortedChainIds = sortedEntries.map(entry => entry[0]);
    },

    sortByMain() {
      this.changeInvestigationDetails('');
      if (!this.previousPageWasOverview) {
        this.changeInvestigationDetails('');
      }

      let process_chain_ids = Object.keys(this.part.process_chains);

      let sorted_ids = [];
      if (
        this.primary_process_chain_id != null &&
        this.primary_process_chain_id != '' &&
        process_chain_ids.includes(this.primary_process_chain_id)
      ) {
        sorted_ids = [this.primary_process_chain_id];
      }
      const other_ids = process_chain_ids.filter(element => !sorted_ids.includes(element));

      let all_sorted_ids = sorted_ids.concat(other_ids);

      this.sortedChainIds = all_sorted_ids;
    },

    initializeSort() {
      if (!this.sortingName) {
        this.sortByMain();
      } else {
        this.sortByHeader(this.sortingName, this.sortingAscending);
      }
    },
  },
};
</script>
