<template>
  <div>
    <div class="flex justify-between items-center mt-24">
      <p class="font-bold text-14 pr-4">Break Even Chart</p>
      <div
        class="text-13"
        :class="[
          processChainsAnalysisFinished && !loading
            ? 'text-primary-500 cursor-pointer hover:text-primary-600'
            : 'text-gray-500 cursor-not-allowed',
        ]"
        @click="startBreakEvenAnalysis"
      >
        Calculate Break Even
      </div>
    </div>

    <div class="flex flex-col items-center p-16 gap-8">
      <p v-if="!processChainsAnalysisFinished" class="text-gray-500">Please analyze all process chains first</p>
      <p v-if="processChainsAnalysisFinished && !breakEvenAnalysisFinished && !loading" class="text-gray-500">
        Break even analysis not yet performed
      </p>
      <div v-if="loading"><img class="w-48 h-48" src="@/assets/img/loading.gif" /></div>
    </div>
    <div v-if="breakEvenAnalysisFinished && processChainsAnalysisFinished && !loading" id="chart" class="chart">
      <apexchart type="line" height="auto" :options="chartOptions" :series="series" />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'BreakEvenChart',

  data() {
    return {
      loading: false,
      chartOptions: {
        chart: {
          height: 500,
          type: 'area',
          stacked: false,
          zoom: {
            enabled: true,
            type: 'xy',
            //autoScaleYaxis: true,
          },

          fontFamily: 'Poppins, Arial, sans-serif',
          toolbar: {
            show: true,
          },
        },

        legend: {
          position: 'bottom',
        },

        // colors: ["#035e7b", "#009766", "#8E0045"],
        noData: {
          text: 'No data available',
          align: 'center',
          verticalAlign: 'middle',
          offsetY: 0,
        },

        stroke: {
          width: [2, 2, 2],
          curve: 'straight',
        },

        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        fill: {
          type: 'gradient',
          opacity: [0.9, 0.9, 0.9],
          gradient: {
            shade: 'light',
            shadeIntensity: 0,
            opacityFrom: 0.1,
            opacityTo: 0.4,
            stops: [0, 5, 100],
            type: 'vertical',
          },
        },

        markers: {
          size: 0,
        },

        xaxis: {
          type: 'numeric',
          min: 0,
          title: {
            text: 'Parts',
          },

          decimalsInFloat: 0,
        },

        yaxis: {
          title: {
            text: 'Cost per Part [€]',
          },

          min: 0,
          decimalsInFloat: 0,
        },

        tooltip: {
          shared: true,
          intersect: false,
          fixed: {
            enabled: true,
            position: 'bottomRight',
            offsetY: 150,
          },

          y: {
            formatter: function (y) {
              if (typeof y !== 'undefined') {
                return y.toFixed(0) + ' €';
              }
            },
          },

          x: {
            formatter: function (x) {
              return x + ' Parts';
            },
          },
        },

        annotations: {
          xaxis: {},
        },
      },
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapGetters(['processChainsAnalysisFinished']),

    breakEvenAnalysisFinished() {
      if (this.part.process_chains !== undefined) {
        for (const value of Object.values(this.part.process_chains)) {
          if (value.break_even !== undefined) {
            if (value.break_even.y_values !== undefined) {
              return true;
            }
          }
        }
      }
      return false;
    },

    processChains() {
      if (this.breakEvenAnalysisFinished) {
        return this.part.process_chains;
      }
      return undefined;
    },

    series() {
      let dataArray = [];
      if (this.breakEvenAnalysisFinished && this.processChainsAnalysisFinished) {
        for (let value of Object.values(this.processChains)) {
          if (value.break_even !== undefined) {
            if (value.break_even.y_values && value.break_even.x_values) {
              let y_values = Object.values(value.break_even.y_values);
              let x_values = Object.values(value.break_even.x_values);
              let data = x_values.map(function (x, idx) {
                return [x, y_values[idx]];
              });
              let dataObject = {
                name: value.prc + '|' + value.material_name + '|' + value.machine_name,
                type: 'area',
                data: data,
              };
              dataArray.push(dataObject);
            }
          }
        }
      }
      return dataArray;
    },
  },

  watch: {
    breakEvenAnalysisFinished(newVal) {
      if (newVal) {
        this.loading = false;
      }
    },
  },

  mounted() {
    if (this.processChainsAnalysisFinished) {
      this.addIntersections();
    }
  },

  methods: {
    async startBreakEvenAnalysis() {
      if (!this.loading && this.processChainsAnalysisFinished) {
        try {
          await this.$store.state.application.axiosInstance.post(`/api/v1/analysis/${this.part.part_id}/break-even/`);
          this.$root.notify('success', 'Break Even Analysis', 'Break even analysis started.', 3000);
          this.loading = true;
        } catch (error) {
          this.$root.notifyError('Break Even Analysis', error);
        }
      }
    },

    addIntersections() {
      let intersections = [];
      let maxIntersection = 0;
      if (this.processChains != undefined) {
        for (let value of Object.values(this.processChains)) {
          let name = value.prc + '|' + value.material_name + '|' + value.machine_name;
          let firstChainName = name.substring(0, 15) + '...';
          for (let [intKey, intValue] of Object.entries(value.break_even.intersections)) {
            let name =
              this.processChains[intKey].prc +
              '|' +
              '|' +
              this.processChains[intKey].material_name +
              '|' +
              '|' +
              this.processChains[intKey].machine_name;
            let secondChainName = name.substring(0, 15) + '...';
            let intersection = {};
            if (intValue[0] != null && intValue[1] != null) {
              if (maxIntersection < intValue[0]) {
                maxIntersection = intValue[0];
              }
              intersection = {
                x: intValue[0],
                x2: null,
                strokeDashArray: 0,
                borderColor: '#383e46',
                label: {
                  style: {
                    fontSize: '9px',
                  },

                  text: firstChainName + ' | ' + secondChainName + ' (' + intValue[0] + ' Parts)',
                },
              };
              intersections.push(intersection);
            }
          }
        }
      }
      let x_max = 0;
      if (maxIntersection != 0) {
        x_max = maxIntersection * 1.2;
      } else {
        x_max = 200;
      }

      this.chartOptions.xaxis.max = x_max;

      this.chartOptions.annotations.xaxis = intersections;
    },
  },
};
</script>

<style lang="scss" scoped>
.chart {
  margin-top: 1px;
  border: 1px solid #e4e5e8;
  border-radius: 3px;
  width: 100%;
}
</style>
