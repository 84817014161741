<template>
  <SparkModal dismissable :model-value="show" @update:model-value="closeModal">
    <template #header>
      <div class="flex items-center justify-between">
        <h2 class="text-24 font-bold">Additional CAD File</h2>
      </div>
    </template>
    <template #default>
      <div>
        <div>
          More than one CAD file detected. Please choose whether to overwrite {{ actualPartName }} with
          {{ newFileName }} or to attach as an asset.
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end gap-4">
        <SparkButton variant="outlined" @click="attachFile">Attach</SparkButton>
        <SparkButton variant="secondary" @click="createNewPart">Create New Part</SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'AdditionalCADFileModal',

  components: {
    SparkModal,
    SparkButton,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },

    actualPartName: {
      type: String,
      required: true,
    },

    newFileName: { type: String, default: null },
  },

  emits: ['close', 'attach-file', 'create-new-part'],

  methods: {
    closeModal() {
      this.$emit('close');
    },

    attachFile() {
      this.$emit('attach-file');
      this.closeModal();
    },

    createNewPart() {
      this.$emit('create-new-part');
      this.closeModal();
    },
  },
};
</script>
