<template>
  <div class="pl-12 flex text-13 items-center flex-wrap">
    <SummaryItem heading="CAD Files"> {{ numberOfCads }} File{{ addSToEnd(numberOfCads) }} </SummaryItem>
    <SummaryItem heading="Technical Drawings/ Assets">
      {{ numberOfAssets }} File{{ addSToEnd(numberOfAssets) }}</SummaryItem
    >
    <SummaryItem heading="Categories">
      <SparkBadge :variant="badgeStyle" custom="w-150 h-120">
        <router-link
          v-if="numberOfRequiredCategories !== 0"
          :to="{ name: 'Input', params: { part_id: part.part_id } }"
          @click="setHighlightCategories()"
          ><div :title="title">
            {{ numberOfRequiredAndFilledCategories }}/{{ numberOfRequiredCategories }}
            {{ category }}
          </div></router-link
        >
      </SparkBadge>
    </SummaryItem>
    <SummaryItem v-if="pipelineStatusesExist" heading="Pipeline Status"> <PipelineStatus /> </SummaryItem>
    <SummaryItem heading="Restriction">
      <i v-if="lockedForUser" class="fas fa-lock" :title="lockedTitle" />
      <div v-else>-</div>
    </SummaryItem>
    <SummaryItem v-if="part.batch_name != null" heading="Group">
      <div>{{ part.batch_name }}</div>
    </SummaryItem>
    <SummaryItem class="mr-0 ml-auto">
      <DropdownMenu
        v-if="!lockedForUser"
        :list-options="partOptions"
        title-icon="fas fa-ellipsis-h"
        close-click-style="always"
        @update-option="action($event)"
      />
    </SummaryItem>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import DropdownMenu from '../../Reusable/DropdownMenu.vue';
import SparkBadge from '../../SparkComponents/SparkBadge.vue';

import PipelineStatus from './PipelineStatus.vue';
import SummaryItem from './SummaryItem.vue';

export default {
  name: 'InputSummary',

  components: { SummaryItem, PipelineStatus, DropdownMenu, SparkBadge },

  data() {
    return {
      partOptions: [{ text: 'Delete Part', value: 'delete', icon: 'fas fa-trash', disabled: false }],
    };
  },

  computed: {
    ...mapState(['part', 'pipeline', 'lockedForUser', 'popup']),
    ...mapState('application', ['axiosInstance']),

    numberOfCads() {
      if (this.part.basename != '') {
        return 1;
      } else {
        return 0;
      }
    },

    pipelineStatusesExist() {
      if (this.pipeline !== undefined) {
        return this.pipeline?.length !== 0;
      } else return true;
    },

    assets() {
      return this.part.assets;
    },

    numberOfAssets() {
      return Object.keys(this.assets).length;
    },

    comboCategories() {
      return this.part.categories.combo_categories;
    },

    textCategories() {
      return this.part.categories.text_categories;
    },

    numberOfRequiredCategories() {
      return this.filterRequired(this.comboCategories) + this.filterRequired(this.textCategories);
    },

    numberOfRequiredAndFilledCategories() {
      return this.filterRequiredAndFilled(this.comboCategories) + this.filterRequiredAndFilled(this.textCategories);
    },

    category() {
      if (this.numberOfRequiredCategories == 1) {
        return 'Category';
      } else {
        return 'Categories';
      }
    },

    percentageOfFilledCategories() {
      return this.numberOfRequiredAndFilledCategories / this.numberOfRequiredCategories;
    },

    badgeStyle() {
      if (this.percentageOfFilledCategories < 1) {
        return 'secondary';
      } else if (this.percentageOfFilledCategories == 1) {
        return 'primary';
      } else {
        return 'outlined';
      }
    },

    title() {
      if (this.percentageOfFilledCategories != 1) {
        return 'Click to add the missing categories.';
      }
      return '';
    },
  },

  watch: {
    popup: {
      handler(popup) {
        if (popup?.key === this.$options.name && popup?.formData === this.part.part_id) {
          if (popup?.clicked === 'ok') {
            this.triggerPopup('');
            this.deletePart();
          } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
            this.triggerPopup('');
          }
        }
      },

      deep: true,
    },
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    confirmDelete() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete Part',
        message: 'The part ' + this.part.name + ' will be deleted. Are you sure?',
        buttons: true,
        formData: this.part.part_id,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Delete', type: 'secondary', value: 'ok' },
        ],
      });
    },

    action(event) {
      if (event.value === 'delete') {
        this.confirmDelete();
      }
    },

    async deletePart() {
      await this.axiosInstance
        .delete(`/api/v1/part/${this.part.part_id}/`)
        .then(() => {
          this.$root.notify('success', 'Part deleted', '', 6000);
          this.$router.push({ name: 'part-library' });
        })
        .catch(error => {
          this.$root.notify('error', 'Delete part error', error, 6000);
        });
    },

    ...mapMutations(['highlightCategories']),
    addSToEnd(value) {
      if ((value == 0) | (value > 1)) return 's';
    },

    filterRequired(items) {
      return Object.values(items).filter(item => item.required === true).length;
    },

    filterRequiredAndFilled(items) {
      return Object.values(items).filter(
        item =>
          item.required === true && Object.prototype.hasOwnProperty.call(item, 'value') && item?.value != undefined
      ).length;
    },

    setHighlightCategories() {
      this.highlightCategories(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  position: relative;
  display: flex;
  margin: 0px var(--grid-outer-whitespace);
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow: hidden;
}

.item {
  display: flex;
  align-self: center;
  padding: 5px 5px 5px 0px;
  margin: 0px 10px 0px 0px;
  font-size: var(--14px);
  white-space: nowrap;
}

i {
  padding: 0px 10px;
  align-self: center;
}

.badge {
  border-radius: 5px;
  padding: 3px;
  &:hover {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }
}
</style>
