const feasData = {
  fits_mac: {
    name: 'Fits Machine',
    additionalInfo: "Verifies that the part dimensions are within the machine's build volume",
    showBar: false,
  },
  size: {
    name: 'Fits Process',
    additionalInfo: "Checks if the part's overall dimensions are compatible with the manufacturing process",
    showBar: true,
  },
  thickness: {
    name: 'Wall Thickness',
    additionalInfo: 'Evaluates if all walls meet the thickness requirements',
    showBar: true,
  },
  gap_size: {
    name: 'Gap Size',
    additionalInfo: 'Ensures that spaces between walls meet minimum manufacturing requirements',
    showBar: true,
  },
  support_occluded: {
    name: 'Support Removability',
    additionalInfo: 'Checks if support structures can be accessed and removed after manufacturing',
    showBar: false,
  },
  radii: {
    name: 'Sharp Edges',
    additionalInfo: 'Verifies that edge curvatures meet minimum radius requirements',
    showBar: true,
  },
  thickness_mean: {
    name: 'Mean Wall Thickness',
    additionalInfo: 'Checks if the average wall thickness meets the minimum requirements',
    showBar: true,
  },
  material_agglom: {
    name: 'Material Agglomeration',
    additionalInfo: 'Checks for areas with excessive material buildup',
    showBar: true,
    hideInWeightDialogIfLimitUnset: true, // can be removed as soon as material agglom check is included in software properly
  },
  feature_aspect_ratios: {
    name: 'Feature Aspect Ratios',
    additionalInfo: 'Identifies protruding features (like pins) that are too thin relative to their height',
    showBar: true,
    hideInWeightDialogIfLimitUnset: true, // can be removed as soon as material agglom check is included in software properly
  },
};

export default function parseFeasDict(feasJson) {
  let flatDict = {};
  Object.entries(feasJson).forEach(([feasKey, feasValue]) => {
    if (feasEntryIsCheck(feasKey)) {
      let minMaxPresent = minAndMaxPresent(feasValue);
      const hideInWeightDialogIfLimitUnset = feasData[feasKey].hideInWeightDialogIfLimitUnset ? true : false;

      flatDict[feasKey] = {
        name: feasData[feasKey].name,
        additionalInfo: feasData[feasKey].additionalInfo,
        minAndMaxPresent: minMaxPresent,
        hideInWeightDialogIfLimitUnset: hideInWeightDialogIfLimitUnset,
      };

      Object.entries(feasValue).forEach(([minMaxKey, minMaxValue]) => {
        let limitIsSet = checkhasValidLimit(feasJson, feasKey, minMaxKey);
        if (minMaxPresent) {
          flatDict[feasKey][minMaxKey] = minMaxValue;
          flatDict[feasKey][minMaxKey]['limitIsSet'] = limitIsSet;
        } else {
          flatDict[feasKey] = {
            ...flatDict[feasKey],
            ...minMaxValue,
            limitIsSet: limitIsSet,
            minOrMax: minMaxKey,
            hideInWeightDialogIfLimitUnset: hideInWeightDialogIfLimitUnset,
          };
        }
      });
    }
  });
  return flatDict;
}

function feasEntryIsCheck(feasKeyOfBackendJson) {
  return feasKeyOfBackendJson in feasData;
}

function minAndMaxPresent(feasDictValue) {
  return Object.keys(feasDictValue).length > 1;
}

function checkhasValidLimit(feasJson, feasKey, minMaxKey) {
  const check = feasJson[feasKey][minMaxKey];
  const isValid = check?.lim !== null;
  return isValid;
}

export function getWeightString(weightNumber) {
  if (weightNumber == 0) {
    return 'Neglected';
  } else if (weightNumber == 0.5) {
    return 'Low';
  } else if (weightNumber == 1) {
    return 'Regular';
  } else if (weightNumber == 10) {
    return 'High';
  } else if (weightNumber == 1000) {
    return 'Crucial';
  }
}
