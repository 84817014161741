export function floatFormat(value, digits) {
  /* A function that takes any float and rounds it to a given number of digits  */
  if (value || value == 0) {
    return parseFloat(value).toFixed(digits);
  } else {
    return '0.00';
  }
}

export function getExtension(filename) {
  if (filename) {
    let parts = filename.split('.');
    return parts[parts.length - 1];
  } else {
    console.log('Undefiend filename in getExtension in helpers.');
    return '';
  }
}

export function isCAD(filename) {
  let ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'stl':
    case 'stp':
    case 'step':
    case 'obj':
    case '3mf':
    case 'ply':
      return true;
  }
}

export function isExcel(filename) {
  let ext = getExtension(filename);
  switch (ext.toLowerCase()) {
    case 'xlsx':
      return true;
  }
  return false;
}

// returns either the chain of the given techname or undefined
export function getProcessChain(chains, techname) {
  let processChainName = Object.keys(chains).find(key => chains[key].tech == techname);
  return chains[processChainName];
}

// returns either the chain of the given process_chain_id or undefined
export function getProcessChainById(chains, chain_id) {
  return chains[chain_id];
}

export function getChoicebyUid(objects, uid) {
  let key = Object.keys(objects).find(key => objects[key].value == uid);
  return objects[key]?.text;
}

export function getValueOfNestedObject(obj, key) {
  let value = key.split('.').reduce((value, el) => value[el], obj);
  return value;
}

export function compareValues(val1, val2) {
  if (typeof val1 === 'number' && typeof val2 === 'number') {
    return val1 - val2;
  } else if (typeof val1 === 'number') {
    return -1;
  } else if (typeof val2 === 'number') {
    return 1;
  } else {
    return val1.toLowerCase().localeCompare(val2.toLowerCase());
  }
}

export function sortNumbersAsc(a, b) {
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }
  return a - b;
}
export function sortNumbersDesc(a, b) {
  if (a === null) {
    return 1;
  }
  if (b === null) {
    return -1;
  }
  return b - a;
}

export function sortStringDesc(a, b) {
  if (a < b) {
    return 1;
  }
  if (a > b) {
    return -1;
  }
  return 0;
}

export function totalFeasFromObj(feasObj) {
  let listOfFeasChecks = [];
  Object.values(feasObj).forEach(singleFeas => {
    Object.values(singleFeas).forEach(minMaxEntry => {
      listOfFeasChecks.push(minMaxEntry.percent_violation);
    });
  });

  let totalFeas = totalFeasFromValues(...listOfFeasChecks);
  return totalFeas;
}

export function totalFeasFromValues(...values) {
  let totalFeas = null;
  for (const value of values) {
    if (value !== undefined && value !== null) {
      if (value <= warning_thresh_percent) {
        totalFeas = true;
      } else {
        totalFeas = false;
        break;
      }
    }
  }
  return totalFeas;
}

export const warning_thresh_percent = 2;

export function copyToClipboard(text) {
  //Deprecated API (for old browser support)
  const textArea = document.createElement('textarea');
  textArea.value = text;
  textArea.setAttribute('readonly', '');
  textArea.style.position = 'absolute';
  textArea.style.left = '-9999px';
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand('copy');
  document.body.removeChild(textArea);

  //New API
  navigator.clipboard.writeText(text);
}

export function capitalizeFirstLetter(string) {
  if (typeof string !== 'string' || string.length === 0) {
    return string;
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getDayAndMonthFromDate(startDate, endDate, format = 2) {
  const startPeriod = new Date(startDate);
  const endPeriod = new Date(endDate);

  const startDay = startPeriod.getUTCDate().toString().padStart(2, '0');
  const startMonth = (startPeriod.getUTCMonth() + 1).toString().padStart(2, '0');
  const endDay = endPeriod.getUTCDate().toString().padStart(2, '0');
  const endMonth = (endPeriod.getUTCMonth() + 1).toString().padStart(2, '0');

  const dayAndMonth = `${startDay}.${startMonth} - ${endDay}.${endMonth}`;
  const daYMonthAndYear = `${startDay}.${startMonth}.${startPeriod.getUTCFullYear()} - ${endDay}.${endMonth}.${endPeriod.getUTCFullYear()}`;

  return format === 3 ? daYMonthAndYear : dayAndMonth;
}

export function checkAllProcessChainStatus(chains, status) {
  return checkProcessChainStatus(chains, status, allElementsTrue);
}

export function checkAnyProcessChainStatus(chains, status) {
  return checkProcessChainStatus(chains, status, anyElementsTrue);
}

function checkProcessChainStatus(chains, status, checkFunc) {
  let statusArray = [];
  let keys = Object.keys(chains);
  if (keys.length == undefined || keys.length == 0) return false;
  keys.forEach(key => {
    statusArray.push(chains[key].status == status);
  });
  return checkFunc(statusArray);
}

function allElementsTrue(statusArray) {
  return statusArray.every(Boolean);
}

function anyElementsTrue(statusArray) {
  return statusArray.some(Boolean);
}

export function notify(theme, title, message, duration = 3000) {
  window.createNotification({
    closeOnClick: true,
    displayCloseButton: false,
    positionClass: 'nfc-bottom-right',
    showDuration: duration,
    theme: theme,
  })({
    title: title,
    message: message,
  });
}

export function notifyError(messageHeader, error) {
  var error_message = '';
  if (error.response.data instanceof Blob) {
    error_message = handleExpectedFileResponse(error);
  } else if (error.response.data instanceof Object) {
    error_message = error.response.data.error_message;
  }
  notify('error', messageHeader, error_message);
}

function handleExpectedFileResponse(error) {
  const text = error.response.data.text();
  const errorData = JSON.parse(text);
  return errorData.error_message;
}

export function transformObjectToArray(inputObject) {
  const result = [];
  for (const key in inputObject) {
    if (Object.prototype.hasOwnProperty.call(inputObject, key)) {
      const item = inputObject[key];
      result.push({
        label: item.text,
        value: item.value,
      });
    }
  }

  return result;
}
