<template>
  <div
    v-if="!isExternal"
    class="bg-white items-center gap-8 pt-12 pb-12 pl-20 top-56 flex border border-solid border-b-gray-200 fixed w-full z-40 flex-wrap"
  >
    <img src="@/assets/img/Logo_Transparent.png" alt="arrow_right" class="h-40 w-40" />
    <NavigationItem
      label="1. Inputs"
      :active="currentRouteName === 'Input'"
      route-to="Input"
      :disabled="false"
      :params="params"
      class="pl-20"
    />

    <i class="fas fa-chevron-right text-14 mx-12" :class="currentRouteName == 'Input' ? 'text-primary-500' : ''"></i>

    <NavigationItem
      label="2. Analysis"
      :active="currentRouteName === 'Comparison' || currentRouteName === 'Supplier'"
      :disabled="productionDisabled"
      :params="params"
      route-to="Comparison"
    />
    <i
      class="fas fa-chevron-right text-14 mx-12"
      :class="currentRouteName == 'Comparison' || currentRouteName == 'Supplier' ? 'text-primary-500' : ''"
    ></i>
    <NavigationItem
      label="3. Checkout"
      :active="currentRouteName === 'Overview'"
      :disabled="checkoutDisabled"
      :params="paramsOverview"
      route-to="Overview"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import NavigationItem from './NavigationItem.vue';
export default {
  name: 'PartNavigation',

  components: {
    NavigationItem,
  },

  computed: {
    ...mapState(['user', 'activeId', 'part']),

    currentRouteName() {
      return this.$route.name;
    },

    isExternal() {
      return this.user.is_external;
    },

    productionDisabled() {
      if (this.part.part_id == 0) {
        return true;
      } else {
        return false;
      }
    },

    checkoutDisabled() {
      if (this.part.primary_process_chain_id == '' || this.part.primary_process_chain_id == null) {
        return true;
      } else {
        return false;
      }
    },

    params() {
      return { part_id: this.part.part_id };
    },

    paramsOverview() {
      return { part_id: this.part.part_id, process_chain_id: this.part.primary_process_chain_id };
    },
  },

  methods: {
    // highlightProcessChain() {
    //   this.changeInvestigationDetails({ uid: this.$route.params?.process_chain_id, content: '' });
    // },
  },
};
</script>
