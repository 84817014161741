import { notifyError, notify } from '@/helpers.js';

const trafficSignalActions = {
  async fetchTrafficSignalOptions({ rootState, commit }) {
    const response = await rootState.application.axiosInstance.get('api/v1/traffic-signal-template-input-choices/');
    commit('setTrafficSignalOptions', response.data);
  },

  async fetchTrafficSignalTemplates({ rootState, commit }) {
    const response = await rootState.application.axiosInstance.get('api/v1/list-traffic-signal-templates/');
    const trafficSignalTemplates = Object.entries(response.data.templates).map(([id, data]) => ({
      id,
      name: data.name,
      type: data.type,
      trafficLights: data.traffic_lights,
    }));
    response.data = trafficSignalTemplates;
    commit('setTrafficSignalTemplates', response.data);
  },

  async addTrafficSignalTemplate({ rootState, dispatch }, data) {
    await rootState.application.axiosInstance
      .post('api/v1/traffic-signal-template/', data)
      .then(() => {
        notify('success', 'Traffic Signal Template', 'Successfully added.');
      })
      .catch(error => {
        notifyError('Adding Traffic Signal Template', error);
      });
    await dispatch('fetchTrafficSignalTemplates');
  },

  async editTrafficSignalTemplate({ rootState, dispatch }, data) {
    await rootState.application.axiosInstance
      .put(`api/v1/traffic-signal-template/${data.uid}/`, data.formData)
      .then(() => {
        notify('success', 'Traffic Signal Template', 'Successfully updated.');
      })
      .catch(error => {
        notifyError('Editing Traffic Signal Template', error);
      });
    await dispatch('fetchTrafficSignalTemplates');
  },

  async deleteTrafficSignalTemplate({ rootState, dispatch }, uid) {
    await rootState.application.axiosInstance
      .delete(`api/v1/traffic-signal-template/${uid}/`)
      .then(() => {
        notify('success', 'Traffic Signal Template', 'Successfully deleted.');
      })
      .catch(error => {
        notifyError('Deleting Traffic Signal Template', error);
      });
    await dispatch('fetchTrafficSignalTemplates');
  },

  async addTrafficLight({ rootState, dispatch }, data) {
    await rootState.application.axiosInstance
      .post('api/v1/traffic-light-template/', data.formData)
      .then(() => {
        notify('success', 'Traffic Light Template', 'Successfully added.');
      })
      .catch(error => {
        notifyError('Adding Traffic Light Template', error);
      });
    await dispatch('fetchTrafficSignalTemplates');
  },

  async editTrafficLight({ rootState, dispatch }, data) {
    await rootState.application.axiosInstance
      .put(`api/v1/traffic-light-template/${data.uid}/`, data.formData)
      .then(() => {
        notify('success', 'Traffic Light Template', 'Successfully updated.');
      })
      .catch(error => {
        notifyError('Editing Traffic Light Template', error);
      });
    await dispatch('fetchTrafficSignalTemplates');
  },

  async deleteTrafficLight({ rootState, dispatch }, uid) {
    await rootState.application.axiosInstance
      .delete(`api/v1/traffic-light-template/${uid}/`)
      .then(() => {
        notify('success', 'Traffic Light Template', 'Successfully deleted.');
      })
      .catch(error => {
        notifyError('Deleting Traffic Light Template', error);
      });
    await dispatch('fetchTrafficSignalTemplates');
  },
};
export default trafficSignalActions;
