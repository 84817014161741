<template>
  <div class="w-full">
    <div class="flex flex-col">
      <div class="mb-16">
        <h3 class="font-semibold text-15">Pricing Configuration</h3>
        <SparkSelect
          v-model="selectedPricingConfigId"
          :options="availablePricingConfigs"
          name="pricing-config"
          class="w-1/2"
          @update:model-value="updatePricingConfig"
        />
      </div>
      <!-- Price Comparison Table -->
      <div class="mb-24">
        <div class="flex justify-between items-center">
          <h3 class="font-semibold text-15 mb-16">Price Overview</h3>
        </div>
        <SparkSmallTable :columns="comparisonColumns" :rows="comparisonData">
          <template #cell-perPart="{ value, row }">
            <template v-if="row.name === 'Price' && activeProcessChain.custom_price_text">
              {{ activeProcessChain.custom_price_text }}
            </template>
            <template
              v-else-if="
                row.name === 'Pricing Configuration' || row.name === 'Gross Margin' || typeof value === 'string'
              "
            >
              {{ value }}
            </template>
            <template v-else> {{ $formatTwoDecimalPlaces(value) }} € </template>
          </template>
          <template #cell-perLot="{ value, row }">
            <template v-if="row.name === 'Price' && activeProcessChain.custom_price_text">
              {{ activeProcessChain.custom_price_text }}
            </template>
            <template
              v-else-if="
                row.name === 'Pricing Configuration' || row.name === 'Gross Margin' || typeof value === 'string'
              "
            >
              {{ value }}
            </template>
            <template v-else> {{ $formatTwoDecimalPlaces(value) }} € </template>
          </template>
        </SparkSmallTable>
      </div>
      <!-- Tiered Pricing Section -->
      <div>
        <div class="flex justify-between items-center mb-16">
          <h3 class="font-semibold text-15">Tiered pricing</h3>
        </div>

        <SparkSmallTable v-if="!loading" :columns="tableColumns" :rows="tieredPricingData">
          <template #cell-pricePerPart="{ value, row }">
            <template v-if="row.is_custom_text">
              {{ value }}
            </template>
            <template v-else>
              {{ isValidNumber(value) ? $formatTwoDecimalPlaces(value) + ' €' : '-' }}
            </template>
          </template>
          <template #cell-costPerPart="{ value }">
            {{ isValidNumber(value) ? $formatTwoDecimalPlaces(value) + ' €' : '-' }}
          </template>
        </SparkSmallTable>
        <div v-else class="flex justify-center py-16">
          <SparkSpinner />
        </div>
      </div>

      <!-- Price Chart - Only show if no custom price text -->
      <div v-if="!activeProcessChain.custom_price_text" class="mt-24 mb-40">
        <h3 class="font-semibold text-15 mb-16">Price Analysis</h3>
        <PriceChart :x-values="chartData.x_values" :y-values="chartData.y_values" :loading="isLoadingPrices" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PriceChart from './PriceChart.vue';

import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import SparkSmallTable from '@/components/SparkComponents/SparkSmallTable.vue';
import SparkSpinner from '@/components/SparkComponents/SparkSpinner.vue';

export default {
  name: 'PriceCard',

  components: {
    PriceChart,
    SparkSelect,
    SparkSmallTable,
    SparkSpinner,
  },

  data() {
    return {
      selectedPricingConfigId: null,
      tableColumns: [
        {
          key: 'lotSize',
          title: 'Lot size',
          sortable: true,
        },
        {
          key: 'costPerPart',
          title: 'Cost per Part',
          sortable: true,
          unit: '€',
        },
        {
          key: 'pricePerPart',
          title: 'Price per part',
          sortable: true,
          unit: '€',
        },
      ],

      loading: false,
      isLoadingPrices: false,
      priceAnalysis: {
        x_values: [],
        y_values: [],
      },

      priceAnalysisData: null,
      lotSizes: [1, 5, 10],

      comparisonColumns: [
        {
          key: 'name',
          title: '',
          sortable: false,
        },
        {
          key: 'perPart',
          title: 'Per Part',
          sortable: false,
        },
        {
          key: 'perLot',
          title: 'Per Lot',
          sortable: false,
        },
      ],
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part', 'pricingOptions']),

    activeProcessChain() {
      return this.part.process_chains[this.investigationDetails.uid];
    },

    availablePricingConfigs() {
      return this.pricingOptions.map(config => ({
        value: config.uid,
        label: config.name,
      }));
    },

    tieredPricingData() {
      if (!this.priceAnalysis?.results) return [];
      return this.priceAnalysis.results.map(result => ({
        lotSize: result.lot_size,
        costPerPart: result.cost_pp,
        pricePerPart: result.price_pp,
        is_custom_text: result.is_custom_text,
      }));
    },

    comparisonData() {
      const lotSize = this.activeProcessChain?.lot_size || 1;
      const costPerPart = this.activeProcessChain?.cost?.cost_pp || 0;
      const costPerLot = this.activeProcessChain?.cost?.cost_pl || 0;

      const customPriceText = this.activeProcessChain?.custom_price_text;

      if (customPriceText) {
        return [
          {
            name: 'Cost',
            perPart: costPerPart,
            perLot: costPerLot,
          },
          {
            name: 'Gross Margin',
            perPart: '-',
            perLot: '-',
          },
          {
            name: 'Pricing Configuration',
            perPart: this.pricingOptions.find(config => config.uid === this.selectedPricingConfigId)?.name || '-',
            perLot: this.pricingOptions.find(config => config.uid === this.selectedPricingConfigId)?.name || '-',
          },
          {
            name: 'Price',
            perPart: customPriceText,
            perLot: customPriceText,
          },
        ];
      }

      const pricePerLot =
        this.activeProcessChain.market_price?.market_price_pl?.user ||
        this.activeProcessChain.market_price?.market_price_pl?.calculated ||
        0;
      const pricePerPart = pricePerLot / lotSize;

      const marginPerPart = pricePerPart - costPerPart;
      const marginPercentage = costPerPart > 0 ? ((marginPerPart / costPerPart) * 100).toFixed(1) : 0;

      return [
        {
          name: 'Cost',
          perPart: costPerPart,
          perLot: costPerLot,
        },
        {
          name: 'Gross Margin',
          perPart: `${marginPercentage}%`,
          perLot: `${marginPercentage}%`,
        },
        {
          name: 'Pricing Configuration',
          perPart: this.pricingOptions.find(config => config.uid === this.selectedPricingConfigId)?.name || '-',
          perLot: this.pricingOptions.find(config => config.uid === this.selectedPricingConfigId)?.name || '-',
        },
        {
          name: 'Price',
          perPart: pricePerPart,
          perLot: pricePerLot,
        },
      ];
    },

    chartData() {
      if (!this.priceAnalysis?.results) return { x_values: [], y_values: [] };

      // Filter out any results with custom text prices
      const numericalResults = this.priceAnalysis.results.filter(result => !result.is_custom_text);

      return {
        x_values: numericalResults.map(result => result.lot_size),
        y_values: numericalResults.map(result => result.price_pp),
      };
    },
  },

  watch: {
    selectedPricingConfigId: {
      immediate: true,
      handler(newConfigId) {
        if (newConfigId) {
          this.calculatePrices();
        }
      },
    },
  },

  async mounted() {
    this.initializePricingConfig();
  },

  methods: {
    isValidNumber(value) {
      return value !== null && value !== undefined && !isNaN(value);
    },

    initializePricingConfig() {
      this.selectedPricingConfigId =
        this.activeProcessChain.pricing_config_id || this.availablePricingConfigs[0]?.value;
    },

    async calculatePrices() {
      if (!this.selectedPricingConfigId || !this.activeProcessChain?.process_chain_id) return;

      try {
        this.loading = true;
        this.isLoadingPrices = true;
        const response = await this.$store.state.application.axiosInstance.post(
          `/api/v1/pricing-config-analysis/${this.selectedPricingConfigId}/`,
          {
            process_chain_id: this.activeProcessChain.process_chain_id,
          }
        );

        this.priceAnalysis = response.data;
      } catch (error) {
        this.$root.notifyError('Price Analysis', error);
        this.priceAnalysis = { x_values: [], y_values: [], cost_pp_values: [] };
      } finally {
        this.loading = false;
        this.isLoadingPrices = false;
      }
    },

    async updatePricingConfig(configId) {
      try {
        await this.$store.state.application.axiosInstance.put(
          `/api/v1/process-chain/${this.activeProcessChain.process_chain_id}/`,
          { pricing_config_id: configId }
        );

        await this.calculatePrices();
      } catch (error) {
        this.$root.notifyError('Update Pricing Config', error);
      }
    },
  },
};
</script>
