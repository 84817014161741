<template>
  <div class="flex flex-col gap-16 items-center align-baseline">
    <div>
      <div class="flex gap-64">
        <div class="flex gap-8">
          <SparkSelect
            v-model="filters.mat_id"
            custom="!w-[200px] !mx-auto p-0"
            label="Material"
            :options="materialOptions"
            name="selection"
          />
          <SparkSelect
            v-model="filters.prc_acronym"
            custom="!w-[200px] !mx-auto"
            label="Technology"
            :options="prcAcronymOptions"
            name="selection"
          />

          <SparkButton class="self-center mt-12 !w-25 items-center" small variant="outlined" @click="clearFilters">
            <span>Clear</span>
          </SparkButton>

          <SparkButton
            class="self-center mt-12 w-150 items-center"
            small
            variant="secondary"
            :disabled="requestRunning"
            @click="sendData"
          >
            <div>
              <img v-if="requestRunning" src="@/assets/img/loading.gif" class="w-20 h-20 ml-auto mr-auto" />
              <p v-else>Update Prices</p>
            </div>
          </SparkButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';

import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'PartActionButtons',

  components: { SparkButton, SparkSelect },

  data() {
    return {
      prcAcronymOptions: [],
      materialOptions: [],
    };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance']),
    ...mapState('supplier', ['selectedFilters', 'requestRunning']),

    filters: {
      get() {
        return this.selectedFilters;
      },

      set(newVal) {
        this.setSelectedFilters(newVal);
      },
    },
  },

  watch: {
    prcAcronymOptions: {
      handler(newOptions) {
        if (newOptions.length > 0 && this.filters.prc_acronym) {
          const selected = newOptions.find(option => option.value === this.filters.prc_acronym);
          if (selected) {
            const currentValue = this.filters.prc_acronym;
            this.setSelectedFilters({ ...this.filters, prc_acronym: null });
            this.$nextTick(() => {
              this.setSelectedFilters({ ...this.filters, prc_acronym: currentValue });
            });
          }
        }
      },

      immediate: true,
    },
  },

  mounted() {
    this.fetchMaterials();
    this.fetchPrcAcronyms();
    this.$nextTick(() => {
      this.requestPriceData({
        partId: this.part.part_id,
        data: { mat_id: this.filters.mat_id, prc_acronym: this.filters.prc_acronym },
      });
    });
  },

  methods: {
    ...mapMutations('supplier', ['setSelectedFilters']),
    ...mapActions('supplier', ['requestPriceData']),

    async fetchMaterials() {
      const prefiltered = true; // prefilter by material level 2
      const distinct = true; // distinct mat_names
      await this.axiosInstance
        .get(`api/v1/default-materials/?prefiltered=${prefiltered}&distinct=${distinct}`)
        .then(response => {
          this.materialOptions = response.data.map(entry => ({
            value: entry.mat_id,
            label: entry.mat_name,
          }));
          this.materialOptions.unshift({ label: ' ', value: null }); // No Material
        })
        .catch(error => {
          console.log(error);
        });
    },

    async fetchPrcAcronyms() {
      const prefiltered = true; // prefilter by template prc
      await this.axiosInstance
        .get(`api/v1/supplier-prc-acronyms/?prefiltered=${prefiltered}`)
        .then(response => {
          this.prcAcronymOptions = response.data.map(entry => ({
            value: entry.prc_acronym,
            label: entry.prc_name,
          }));
          this.prcAcronymOptions.unshift({ label: ' ', value: null }); // No Process
        })
        .catch(error => {
          console.log(error);
        });
    },

    async sendData() {
      if (!this.requestRunning) {
        const data = {
          mat_id: this.filters.mat_id,
          prc_acronym: this.filters.prc_acronym,
        };

        this.requestPriceData({ partId: this.part.part_id, data });
      }
    },

    clearFilters() {
      this.setSelectedFilters({
        ...this.filters,
        mat_id: null,
        prc_acronym: null,
      });
      this.requestPriceData({ partId: this.part.part_id, data: { mat_id: null, prc_acronym: null } });
    },
  },
};
</script>
