<template>
  <div class="m-16 overflow-scroll max-h-[400px]">
    <table v-if="part.process_proposal != undefined" class="text-11 w-full">
      <tbody>
        <tr v-for="(result, prcUid) in proposalsFilteredSorted" :key="prcUid" class="h-24 even:bg-gray-50">
          <td>{{ user.organization.process_synonyms[prcUid].prc_name }}</td>
          <td class="pl-8">{{ user.organization.process_synonyms[prcUid].prc_acronym }}</td>
          <td>{{ $formatZeroDecimalPlaces(result.score) }}<span class="text-gray-600 pl-4">%</span></td>
          <td>
            <PercentagePieChart :percentage="result.score" :stroke-color="getColor(result.score)" />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>Proposals for this part are not calculated.</div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PercentagePieChart from '@/components/PartWorkflow/Feasibility/PercentagePieChart.vue';

export default {
  name: 'TechnologyCard',

  components: {
    PercentagePieChart,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState(['part', 'user']),
    proposalsFilteredSorted() {
      let filteredProcesses = Object.keys(this.part.process_proposal)
        .filter(key => this.part.process_proposal[key].score !== null)
        .reduce((result, key) => {
          result[key] = this.part.process_proposal[key];
          return result;
        }, {});

      let sortedKeys = Object.keys(filteredProcesses).sort((a, b) => {
        // First sort by score
        const scoreDiff = filteredProcesses[b].score - filteredProcesses[a].score;
        if (scoreDiff !== 0) return scoreDiff;

        // If scores are equal, sort alphabetically by process acronym
        return this.user.organization.process_synonyms[a].prc_acronym.localeCompare(
          this.user.organization.process_synonyms[b].prc_acronym
        );
      });

      let sortedData = sortedKeys.reduce((result, key) => {
        result[key] = filteredProcesses[key];
        return result;
      }, {});

      return sortedData;
    },
  },

  methods: {
    getColor(percentage) {
      if (percentage >= 80) return 'green';
      if (percentage >= 50) return 'yellow';
      return 'red';
    },
  },
};
</script>
