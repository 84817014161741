<template>
  <div class="w-full">
    <div class="text-13 text-gray-700 mb-16">
      Prices have been moved to their own tab. Check them out <span 
        class="text-primary-500 cursor-pointer hover:text-primary-600"
        @click="openPriceModal"
      >here</span>.
    </div>
    <div class="flex">
      <AMManufacturingCost v-if="activeProcessChain.tech === 'additive_manufacturing'" class="w-full" />
      <MillingManufacturingCost v-if="activeProcessChain.tech === 'milling'" class="w-full" />
    </div>
    <div class="flex justify-start items-baseline">
      <p class="font-bold text-14 mt-24 pr-4">Process Chain</p>
      <Toggle :possible-classes="levels" :selected-class="selectedLevel" @selected="selectLevel($event)" />
    </div>
    <ProcessChain :chain="activeProcessChain" editable :selected-level="selectedLevel" property="cost" />
    <BreakEvenChart />
    <GlassBox :process-chain-id="activeProcessChain.process_chain_id" />
  </div>
</template>

<script>
import { markRaw } from 'vue';
import { mapState, mapMutations } from 'vuex';

import PricePopup from '../../../tablecells/price/components/PricePopup.vue';

import GlassBox from './GlassBox.vue';

import AMManufacturingCost from '@/components/PartWorkflow/Cost/AMManufacturingCost.vue';
import MillingManufacturingCost from '@/components/PartWorkflow/Cost/MillingManufacturingCost.vue';
import BreakEvenChart from '@/components/PartWorkflow/Info/BreakEvenChart.vue';
import ProcessChain from '@/components/PartWorkflow/ProcessChain/ProcessChain.vue';
import Toggle from '@/components/Reusable/Toggle.vue';

export default {
  name: 'CostCard',

  components: {
    AMManufacturingCost,
    MillingManufacturingCost,
    ProcessChain,
    Toggle,
    GlassBox,
    BreakEvenChart,
  },

  data() {
    return {
      selectedLevel: {
        _pp: 'Part',
      },
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part']),
    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    levels() {
      let labels = {
        _pp: 'Part',
        _pj: '',
        _pl: 'Lot [' + this.activeProcessChain.lot_size + ']',
      };

      if (this.activeProcessChain.lot_only) {
        labels['_pj'] = 'Job [Ø ' + this.activeProcessChain.parts_pj + ']';
      } else {
        labels['_pj'] = 'Job [' + this.activeProcessChain.parts_pj + ']';
      }
      return labels;
    },
  },

  mounted() {
    this.changeDisplayInfo('breakEvenChart');
  },

  methods: {
    ...mapMutations(['triggerPopup','changeDisplayInfo', 'setCalculationDetails']),
    
    openPriceModal() {
      this.triggerPopup({
        key: 'Price',
        show: true,
        title: 'Price',
        type: 'component',
        buttons: true,
        buttonContent: [{ text: 'Close', type: 'outlined', value: 'cancel' }],
        data: markRaw(PricePopup),
      });
    },

    selectLevel(event) {
      this.selectedLevel = event;
    },
  },
};
</script>
