<template>
  <div class="organization-settings bg-white border border-gray-50 rounded-8">
    <div class="border-b border-gray-50">
      <div class="text-16 font-bold pt-20 pl-20">Organization Settings</div>
      <div class="text-13 text-gray-400 pl-20 pb-20">
        These settings will affect your entire Organization. Please be careful with editing and deleting. There may be
        information loss.
      </div>
    </div>
    <SettingsNavigation @set-active-tab="setActiveTab" />

    <CostSettings v-if="activeTab === 'cost'" />
    <CategorySettings v-if="activeTab === 'category'" />
    <CustomDocumentSettings v-if="activeTab === 'documents'" />
    <TrafficSignalConfiguratorSettings v-if="activeTab === 'traffic'" />
    <OtherSettings v-if="activeTab === 'other'" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import CategorySettings from './category/CategorySettings.vue';
import CostSettings from './cost/CostSettings.vue';
import CustomDocumentSettings from './documents/CustomDocumentSettings.vue';
import OtherSettings from './other/OtherSettings.vue';
import SettingsNavigation from './SettingsNavigation.vue';
import TrafficSignalConfiguratorSettings from './traffic-light/TrafficSignalConfiguratorSettings.vue';

export default {
  name: 'OrganizationSettings',

  components: {
    CostSettings,
    CategorySettings,
    OtherSettings,
    CustomDocumentSettings,
    SettingsNavigation,
    TrafficSignalConfiguratorSettings,
  },

  beforeRouteLeave() {
    this.fetchOrganization();
  },

  data() {
    return {
      activeTab: 'cost',
    };
  },

  mounted() {
    this.fetchTrafficSignalOptions();
  },

  methods: {
    ...mapActions(['fetchOrganization']),
    ...mapActions('quotation', ['fetchTrafficSignalOptions']),

    setActiveTab(tabId) {
      this.activeTab = tabId;
    },
  },
};
</script>
