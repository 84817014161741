<template>
  <SparkModal dismissable :model-value="show" @update:model-value="closeModal">
    <template #header>Edit Production Option</template>
    <template #default>
      <div>
        <SparkInput v-model="templateName" label="Template Name" name="name" disabled />
        <SparkSelect
          v-model="trafficSignalQuotation"
          name="trafficSignalQuotation"
          label="Traffic Signal Quotation"
          :options="trafficSignalOptions"
        />
        <div class="flex justify-end gap-16">
          <SparkButton variant="outlined" @click="closeModal">
            <div v-text="'Cancel'" />
          </SparkButton>
          <SparkButton custom="!min-w-[80px]" variant="secondary" @click="save">
            <div v-text="'Save'" />
          </SparkButton>
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
export default {
  name: 'EditProductionOptionModal',

  components: { SparkSelect, SparkModal, SparkInput, SparkButton },

  props: {
    template: { type: Object, required: true },
    show: { type: Boolean, required: true },
  },

  emits: ['close'],

  data() {
    return {
      trafficSignalQuotation: '',

      templateName: '',
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['quotation']),
    trafficSignalOptions() {
      const templates = this.quotation.trafficSignalTemplates || {};
      return Object.keys(templates).map(key => ({
        value: templates[key].id,
        label: templates[key].name,
      }));
    },
  },

  watch: {
    template(newVal) {
      this.templateName = newVal.name;
      if (newVal.traffic_signal_templates.length > 0) {
        this.template.traffic_signal_templates.forEach(trafficSignal => {
          console.log(trafficSignal);
          if (trafficSignal.type === 'quotation') {
            this.trafficSignalQuotation = trafficSignal.uid;
          }
        });
      } else {
        this.trafficSignalQuotation = '';
      }
    },
  },

  methods: {
    closeModal() {
      this.$emit('close');
    },

    save() {
      this.saveTrafficSignal();
    },

    async saveTrafficSignal() {
      try {
        await this.axiosInstance.put(`api/v1/traffic-signal-template/${this.trafficSignalQuotation}/`, {
          process_chain_template_id: this.template.uid,
        });
        this.closeModal();
      } catch (error) {
        console.error('Error saving traffic signal:', error);
      }
    },
  },
};
</script>
