<template>
  <div class="flex flex-col w-full border border-gray-100 rounded-4 p-8">
    <div class="flex justify-end">
      <i class="fas fa-times cursor-pointer hover:text-gray-500" @click="close" />
    </div>
    <div v-if="firstTrafficLight">
      <div class="text-13 text-gray-500 bg-gray-50 rounded-8 p-4 my-4">
        No traffic lights added to the signal yet. Please add one traffic light as fallback.
      </div>
    </div>
    <SparkInput v-model="name" label="Name" name="name" />
    <CustomExpression
      v-if="!firstTrafficLight && trafficLightToEdit?.order_index != 0"
      :reset="reset"
      :incoming-actual-value="rule"
      :incoming-words="ruleWords"
      :options="ruleOptions"
      label="Rule"
      @input="setRule"
    />

    <div class="mt-12">
      <SparkSelect v-model="color" label="Color" name="color" :options="colorOptions" />
    </div>
    <div class="flex gap-8 mt-24 justify-end">
      <SparkButton variant="outlined" @click="save">Save Traffic Light</SparkButton>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import CustomExpression from '@/components/General/CustomExpression.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'TrafficLightForm',

  components: {
    SparkButton,
    SparkInput,
    SparkSelect,
    CustomExpression,
  },

  props: {
    show: { type: Boolean },
    mode: { type: String, default: 'Add' },
    trafficSignalUid: { type: String, default: null },
    trafficLightToEdit: { type: Object, default: null },
    firstTrafficLight: { type: Boolean },
  },

  emits: ['close'],

  data() {
    return {
      name: '',
      rule: '',
      ruleWords: [],
      color: '',
      trafficLight: null,
      reset: 0,
    };
  },

  computed: {
    ...mapState(['ruleOptions']),
    ...mapState('application', ['axiosInstance']),
    ...mapState('quotation', ['trafficSignalOptions']),

    colorOptions() {
      return this.trafficSignalOptions?.traffic_light?.color || [];
    },
  },

  watch: {
    trafficLightToEdit: {
      handler() {
        if (this.mode === 'Edit') {
          this.name = this.trafficLightToEdit?.name || '';
          this.color = this.trafficLightToEdit?.color || '';
          this.rule = this.trafficLightToEdit?.rule || '';
          this.ruleWords = this.processCondition().ruleWords;
        } else {
          this.name = '';
          this.color = '';
          this.rule = '';
        }
      },

      immediate: true,
    },

    show: {
      handler(value) {
        if (value && this.trafficLightToEdit && this.mode === 'edit') {
          this.reset++;
          this.rule = this.trafficLightToEdit?.rule || '';
          this.trafficLight = this.trafficLightToEdit;
        }
      },

      immediate: true,
    },
  },

  methods: {
    ...mapActions(['fetchOrganization']),
    ...mapActions('quotation', ['addTrafficLight', 'editTrafficLight']),

    processCondition() {
      if (this.trafficLightToEdit?.rule?.length === 0) return [];
      const ruleWords = this.trafficLightToEdit.rule
        ? this.trafficLightToEdit.rule.split(/\s+/).map(word => {
            const matchedOption = this.ruleOptions.find(option => option.variable === word);
            return matchedOption ? matchedOption.verbose : word;
          })
        : [];

      return {
        ruleWords,
      };
    },

    close() {
      this.name = '';
      this.color = '';
      this.rule = '';
      this.$emit('close');
    },

    async save() {
      let formData = {};
      formData.name = this.name;
      formData.color = this.color;
      formData.traffic_signal_template_id = this.trafficSignalUid;
      formData.rule = this.rule;

      if (this.mode === 'Edit') {
        await this.editTrafficLight({ uid: this.trafficLightToEdit.uid, formData });
      } else {
        await this.addTrafficLight({ formData });
      }
      this.$emit('close');
    },

    setRule(rule) {
      this.rule = rule;
    },
  },
};
</script>
