<template>
  <div class="flex p-20">
    <div class="flex flex-col w-1/3">
      <div class="text-14 font-bold">Traffic Signals</div>
      <div class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" @click="addTrafficSignal">Add</div>
    </div>

    <div v-if="trafficSignalTemplates.length > 0" class="w-2/3 border border-gray-50 rounded-8">
      <table class="w-full">
        <thead class="text-gray-600 border-b border-gray-50">
          <tr class="h-48 text-14 font-medium">
            <th class="text-left pl-16">Name</th>
            <th class="text-left">Type</th>
            <th class="text-left">Number Traffic Lights</th>
            <th class="w-48" />
          </tr>
        </thead>
        <tbody class="divide-y divide-gray-50">
          <tr v-for="config in trafficSignalTemplates" :key="config.id" class="h-56 hover:bg-gray-50">
            <td class="pl-16">
              <div class="flex flex-col">
                <div class="text-14 font-medium">{{ config.name }}</div>
              </div>
            </td>
            <td class="text-14 font-medium">{{ parseTypeOptions(config.type) }}</td>
            <td class="text-14 font-medium">
              <SparkButton v-if="config.trafficLights.length === 0" variant="primary" @click="editConfig(config)"
                >Add Traffic Lights</SparkButton
              >
              <div v-else>{{ config.trafficLights.length }}</div>
            </td>
            <td>
              <DropdownMenu
                :list-options="dropdownOptions"
                title-icon="fas fa-ellipsis-h"
                close-click-style="always"
                @update-option="handleAction($event, config)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <GeneralTrafficSignalFormModal
      :show="showTrafficSignalModal"
      :mode="trafficSignalMode"
      :traffic-signal-to-edit="configToEdit"
      :traffic-signal-uid-to-edit="trafficSignalUidToEdit"
      @close="close"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import GeneralTrafficSignalFormModal from './components/GeneralTrafficSignalFormModal.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';

export default {
  name: 'TrafficSignalConfiguratorSettings',

  components: { DropdownMenu, GeneralTrafficSignalFormModal, SparkButton },

  data() {
    return {
      dropdownOptions: [
        { text: 'View', value: 'view', icon: 'fas fa-glasses' },
        { text: 'Edit', value: 'edit', icon: 'fas fa-pen' },
        { text: 'Delete', value: 'delete', icon: 'fas fa-trash' },
      ],

      trafficSignalUidToEdit: null,
      configToEdit: null,
      trafficSignalMode: 'Add',

      showTrafficSignalModal: false,
    };
  },

  computed: {
    ...mapState('quotation', ['trafficSignalTemplates', 'trafficSignalOptions']),
  },

  methods: {
    ...mapActions('quotation', ['deleteTrafficSignalTemplate']),

    addTrafficSignal() {
      this.showTrafficSignalModal = true;
    },

    parseTypeOptions(type) {
      const typeOptionsDict = this.trafficSignalOptions?.traffic_signal?.type;
      const label = typeOptionsDict.find(option => option.value === type)?.label;
      return label;
    },

    editConfig(config) {
      this.trafficSignalMode = 'Edit';
      this.configToEdit = config;
      this.trafficSignalUidToEdit = config.id;
      this.showTrafficSignalModal = true;
    },

    viewConfig(config) {
      this.trafficSignalMode = 'View';
      this.configToEdit = config;
      this.trafficSignalUidToEdit = config.id;
      this.showTrafficSignalModal = true;
    },

    async deleteConfig(config) {
      await this.deleteTrafficSignalTemplate(config.id);
    },

    handleAction(option, signal) {
      switch (option.value) {
        case 'edit':
          this.editConfig(signal);
          break;
        case 'delete':
          this.deleteConfig(signal);
          break;
        case 'view':
          this.viewConfig(signal);
          break;
      }
    },

    close() {
      this.showTrafficSignalModal = false;
      this.trafficSignalMode = 'Add';
      this.configToEdit = null;
      this.trafficSignalUidToEdit = null;
    },
  },
};
</script>
