<template>
  <SparkModal
    custom="cursor-default sm:!max-w-[70rem]"
    dismissable
    :model-value="show"
    @update:model-value="handleClose"
  >
    <template #header>
      <div class="text-18 font-bold" v-text="'Change Inputs'" />
    </template>

    <template #default>
      <div class="flex flex-col mx-20">
        <div class="flex flex-col text-13 p-4 z-10 pb-12">
          <SparkInput v-model.lazy="processChainName" name="name" label="Name" class="w-full" />
        </div>

        <div class="mb-16 border-b border-gray-50">
          <SparkTabs
            v-model="selectedTab"
            class="flex flex-row gap-10 text-14 text-gray-600"
            :items="[
              { value: 'material', label: 'Change Material' },
              { value: 'technology', label: 'View Process Suitability' },
              { value: 'machine', label: 'View Machine' },
            ]"
          >
            <template #default="{ item, isActive }">
              <div
                class="px-12 h-40 flex flex-col items-center justify-center hover:cursor-pointer hover:bg-gray-100 rounded-6 hover:text-primary-1000 mb-8"
                :class="isActive ? 'text-primary-1000 bg-gray-50 rounded-6' : ''"
              >
                {{ item.label }}
              </div>
              <div v-if="isActive" class="h-4 bg-primary-400 rounded-4 w-full" />
            </template>
          </SparkTabs>
        </div>
        <div class="flex flex-col">
          <MaterialCard v-if="selectedTab === 'material' && chainIsActive" :prc-uid="chain?.prc_uid" />
          <MachineCard v-if="selectedTab === 'machine' && chainIsActive" />
          <TechnologyCard v-if="selectedTab === 'technology' && chainIsActive" />
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import MachineCard from './MachineCard.vue';
import MaterialCard from './MaterialCard.vue';
import TechnologyCard from './TechnologyCard.vue';

import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkTabs from '@/components/SparkComponents/SparkTabs.vue';

export default {
  name: 'ChangeProcessChainPopup',

  components: {
    MachineCard,
    MaterialCard,
    TechnologyCard,
    SparkInput,
    SparkModal,
    SparkTabs,
  },

  props: {
    show: { type: Boolean, required: true },
    chain: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['close'],

  data() {
    return {
      selectedTab: 'material',
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part', 'investigationDetails']),
    ...mapState('application', ['axiosInstance']),

    chainIsActive() {
      return this.investigationDetails?.uid === this.chain?.process_chain_id;
    },

    processChainName: {
      get() {
        return this.chain.name;
      },

      set(value) {
        this.changeProcessName({ uid: this.chain.process_chain_id, name: value });
        this.saveProcessName();
      },
    },
  },

  methods: {
    ...mapMutations(['changeProcessName']),

    saveProcessName() {
      let uid = this.chain.process_chain_id;
      let name = this.processChainName;
      if (uid != 0) {
        this.axiosInstance.put(`/api/v1/process-chain/${uid}/`, {
          name,
        });
      }
    },

    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
