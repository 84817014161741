<template>
  <div class="flex gap-4 items-center w-full">
    <div class="font-bold truncate w-1/5" :title="template.name">
      <div v-if="template.owner_username == 'material_proposal'">
        <SparkBadge
          v-if="materialProposalWithHighestSimilarity == template.material_uid"
          custom="inline-flex items-center p-0"
          variant="success"
          small
          text="Best Match"
          >Material Proposal Best Match
        </SparkBadge>
        <div v-else>
          <SparkBadge custom="inline-flex items-center" small variant="outlined">Material Proposal</SparkBadge>
        </div>
      </div>
      {{ template.name }}
      <div class="text-gray-400 text-11 font-normal">1 Option</div>
    </div>
    <div v-if="template.restriction" class="w-1/6 text-left truncate" :title="template.restriction">
      {{ template.restriction }}
    </div>
    <div v-else class="w-1/6">-</div>
    <div class="w-1/6 text-left truncate" :title="getProcessSynonym(template.prc_uid)">
      {{ getProcessSynonym(template.prc_uid) }}
    </div>
    <div class="w-1/6 text-left truncate" :title="template.material_name">{{ template.material_name }}</div>
    <div class="w-1/6 text-left truncate" :title="template.machine">
      {{ template.machine }}
    </div>
    <div class="w-1/12 justify-end flex truncate" :title="`${avgCostPerKg} €/kg`">
      {{ avgCostPerKg }} <span class="text-gray-400 ml-4">€/kg</span>
    </div>
    <div class="w-1/12 text-right flex justify-center">
      <SparkBadge custom="inline-flex items-center" :variant="score > 80 ? 'success' : 'error'">
        <span class="whitespace-nowrap">{{ $formatZeroDecimalPlaces(score) }} %</span>
      </SparkBadge>
    </div>
    <div class="flex flex-col w-1/12">
      <div class="text-11 truncate" :title="$dayjs(template.updated).format('DD.MM.YYYY')">
        {{ $dayjs(template.updated).format('DD.MM.YYYY') }}
      </div>
      <div class="text-11 text-gray-400 truncate" :title="template.owner_username">{{ template.owner_username }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';

export default {
  name: 'TemplateDetails',

  components: { SparkBadge },

  props: {
    template: {
      type: Object,
      default() {
        return {};
      },
    },

    materialProposalWithHighestSimilarity: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapState(['part', 'user', 'organization']),

    score() {
      if (this.part.process_proposal != undefined) {
        return this.part.process_proposal[this.template.prc_uid]?.score;
      } else {
        return null;
      }
    },

    avgCostPerKg() {
      if (this.template.avg_cost_per_kg == null || this.template.avg_cost_per_kg == '') {
        return '-';
      } else {
        return this.$formatTwoDecimalPlaces(this.template.avg_cost_per_kg);
      }
    },

    processSynonyms() {
      return this.organization.process_synonyms;
    },
  },

  methods: {
    getProcessSynonym(uid) {
      return this.processSynonyms[uid].prc_acronym;
    },
  },
};
</script>
