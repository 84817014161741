import posthog from 'posthog-js';

import { parseQuery, stringifyQuery } from '@/plugins/query';
import store from '@/store'; // Import your Vuex store

export default {
  install(app) {
    // Initialize PostHog
    app.config.globalProperties.$posthog = posthog.init('phc_PRQrybTVLEKPYE2Up8SLJUnQlCDwhC4T4XV1ttYPCkP', {
      api_host: 'https://eu.i.posthog.com',
      session_recording: {
        maskAllInputs: true,
        maskTextSelector: '*',
      },
      mask_ip: true,
      autocapture: true,
      persistence: 'memory',
      sanitize_properties: (properties, event_name) => {
        if (properties.$current_url) {
          properties.$current_url = cleanUrl(properties.$current_url);
        }
        if (properties.$pathname) {
          properties.$pathname = cleanUrl(properties.$pathname);
        }
        return properties;
      },
    });

    // Add tracking method to app instance
    app.config.globalProperties.$track = (eventName, properties = {}) => {
      // Get user from Vuex store
      const user = store.state.auth?.user;

      // Add user information to properties if available
      if (user) {
        properties.username = user.product_fruits_id;
        properties.role = user.user_role
      }

      // Add timestamp
      properties.timestamp = new Date().toISOString();

      // Track the event in PostHog
      posthog.capture(eventName, properties);
    };
  },
};

function cleanUrl(url) {
  const parsedUrl = new URL(url, window.location.origin);
  const path = parsedUrl.pathname;
  const queryParams = parseQuery(parsedUrl.search);

  // Remove uid from query parameters
  delete queryParams.uid;

  // Remove uid from path segments
  const cleanPath = path
    .split('/')
    .filter(segment => !segment.match(/[a-zA-Z0-9]{24}/))
    .join('/');

  // Reconstruct the URL without uid
  const cleanedUrl = `${cleanPath}${stringifyQuery(queryParams) ? `?${stringifyQuery(queryParams)}` : ''}`;

  return cleanedUrl;
}
