<template>
  <div>
    <div class="text-11 bg-gray-50 p-12 rounded-8">
      You can change the material using the same process. To add a new combination, simply create a new production
      option.
    </div>
    <ComparisonPageMaterialTable type="change" />
  </div>
</template>

<script>
import ComparisonPageMaterialTable from '@/components/PartWorkflow/Material/ComparisonPageMaterialTable.vue';

export default {
  name: 'MaterialCard',

  components: {
    ComparisonPageMaterialTable,
  },
};
</script>
