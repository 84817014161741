<template>
  <div v-if="primaryOrEmptyProcessChain" class="flex flex-col w-full h-full">
    <div v-if="loading" class="flex justify-center items-center min-h-200"><SparkSpinner /></div>
    <div v-else class="mt-16 ml-8">
      <div>
        <div class="text-14 text-bold mb-8">{{ machineData.mac_name }} by {{ machineData.oem_name }}</div>
        <div class="flex flex-row gap-8">
          <IconWithText
            class="mb-4"
            description="Build Envelope"
            :value="machineData.bld_size_x + 'mm x ' + machineData.bld_size_y + 'mm x ' + machineData.bld_size_z + 'mm'"
            :icon="buildEnvelopeSvg"
          />
        </div>

        <div class="flex flex-row gap-8 mb-4">
          <IconWithText
            class="w-1/4"
            description="Time per Layer"
            :value="machineData.mac_prc_t_plyr + 's'"
            :icon="layerTimeSvg"
          />
          <IconWithText
            class="w-1/4"
            description="Setup Time"
            :value="machineData.setup_time + 'h'"
            :icon="setupTimeSvg"
          />
          <IconWithText
            class="w-1/4"
            description="Unloading Time"
            :value="machineData.unloading_time + 'h'"
            :icon="unloadingTimeSvg"
          />
          <IconWithText
            class="w-1/4"
            description="Supervised Time"
            :value="machineData.staff_time_factor + '%'"
            :icon="supervisedTimeSvg"
          />
        </div>

        <div class="flex flex-row gap-8">
          <IconWithText
            class="w-1/4"
            description="Hourly Rate"
            :value="machineData.mac_cost_ph + '€/h'"
            :icon="hourlyRateSvg"
          />
          <IconWithText
            class="w-1/4"
            description="Fixed Cost"
            :value="machineData.fix_mac_cost_pj + '€'"
            :icon="fixedCostSvg"
          />
          <IconWithText
            class="w-1/4"
            description="Power Consumption"
            :value="machineData.power_consumption + 'kW'"
            :icon="powerConsumptionSvg"
          />
          <div class="w-1/4" />
        </div>
      </div>
    </div>
  </div>
  <div v-else>Standard Machine for this process is selected.</div>
</template>

<script>
import { mapState } from 'vuex';

import IconWithText from '../../../components/IconWithText.vue';

import BuildEnvelope from '@/assets/icons/comparison/build_envelope.svg';
import FixedCost from '@/assets/icons/comparison/fixed_cost.svg';
import HourlyRate from '@/assets/icons/comparison/hourly_rate.svg';
import LayerTime from '@/assets/icons/comparison/layer_time.svg';
import PowerConsumption from '@/assets/icons/comparison/power_consumption.svg';
import SetupTime from '@/assets/icons/comparison/setup_time.svg';
import SupervisedTime from '@/assets/icons/comparison/supervised_time.svg';
import UnloadingTime from '@/assets/icons/comparison/unloading_time.svg';
import SparkSpinner from '@/components/SparkComponents/SparkSpinner.vue';

export default {
  name: 'MachineCard',

  components: {
    IconWithText,
    SparkSpinner,
  },

  data() {
    return {
      machineData: {},
      loading: false,
    };
  },

  computed: {
    ...mapState(['investigationDetails', 'part']),
    ...mapState('application', ['axiosInstance']),

    powerConsumptionSvg() {
      return PowerConsumption;
    },

    unloadingTimeSvg() {
      return UnloadingTime;
    },

    supervisedTimeSvg() {
      return SupervisedTime;
    },

    buildEnvelopeSvg() {
      return BuildEnvelope;
    },

    fixedCostSvg() {
      return FixedCost;
    },

    hourlyRateSvg() {
      return HourlyRate;
    },

    layerTimeSvg() {
      return LayerTime;
    },

    setupTimeSvg() {
      return SetupTime;
    },

    processChains() {
      return this.part.process_chains;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      if (this.activeInvestigationDetails != '') {
        return this.processChains[this.activeInvestigationDetails.uid];
      }
      return {};
    },

    activeProcessChainIsAM() {
      return this.activeProcessChain?.tech == 'additive_manufacturing';
    },

    machineUid() {
      let actualProcessUid = this.activeInvestigationDetails.uid;
      let actualProcessChain = this.processChains[actualProcessUid];
      return actualProcessChain?.mac_id;
    },

    primaryOrEmptyProcessChain() {
      return this.activeProcessChainIsAM || this.activeInvestigationDetails.uid == 0;
    },
  },

  mounted() {
    this.getMachine(this.machineUid);
  },

  methods: {
    getMachine(uid) {
      this.loading = true;
      this.axiosInstance.get(`/api/v1/machine/${uid}`).then(response => {
        this.machineData = { ...response.data };
        this.loading = false;
      });
    },
  },
};
</script>
