<template>
  <div class="file-drop">
    <div class="div-dashed-upload">
      <div class="wrapper flex justify-center">
        <div class="drop" :class="cadComputationRunning || putAndWaitForResponse ? 'close-up' : 'show-up'">
          <FileDropDisplay v-if="!lockedForUser" />
          <div v-else class="cont" :title="lockedForUser ? lockedTitle : ''">
            <i class="file-drop__lock fas fa-lock" :title="lockedTitle" />
          </div>
          <output id="list" />
          <input
            v-if="!lockedForUser"
            id="drag-and-drop-file-input"
            type="file"
            accept=".stl,.stp,.step,.obj,.3mf,.ply,.jpg,.jpeg,.png,.tif,.tiff,.pdf,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.ppt,.pptx,.doc,.docx,.mp4,.mpg,.mpeg"
            multiple
            @input="preUploadFiles"
            @click="resetFileUpload"
            @drop="resetFileUpload"
          />
        </div>
        <div v-if="cadComputationRunning || putAndWaitForResponse" class="flex flex-col gap-16 justify-center">
          <img src="@/assets/img/Logo_Loop_Transparent.gif" class="w-80 mx-auto" />
          <div class="status" data-test="upload-progress" v-text="uploadProcessStatus" />
        </div>
      </div>
    </div>
    <AdditionalCADFileModal
      :show="showAdditionalCADFileModal"
      :actual-part-name="part.basename"
      @close="closeAdditionalCADFileModal"
      @attach-file="attachFile"
      @create-new-part="createNewPart"
    />
    <ChooseAssetTypeModal
      :show="showChooseAssetTypeModal"
      @close="closeChooseAssetTypeModal"
      @attach-asset="attachAsset($event)"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';

import FileDropDisplay from '../FileDropDisplay.vue';

import AdditionalCADFileModal from './AdditionalCADFileModal.vue';
import ChooseAssetTypeModal from './ChooseAssetTypeModal.vue';

export default {
  name: 'FileDrop',

  components: { FileDropDisplay, AdditionalCADFileModal, ChooseAssetTypeModal },

  data() {
    return {
      eventFiles: {},
      cadCounter: 0,
      assetCounter: 0,
      storedFile: null,
      assetType: null,
      uploadCounter: 0,
      putAndWaitForResponse: false,
      uploadProgressFrontendSide: 0,
      uploadStatus: '',
      showAdditionalCADFileModal: false,
      showChooseAssetTypeModal: false,
    };
  },

  computed: {
    ...mapState(['part', 'unsavedChanges']),
    ...mapState('application', ['axiosInstanceFileUpload', 'useNewMesherForDebugging']),
    ...mapGetters([
      'cadComputationRunning',
      'cadStatus',
      'fileConversionFinished',
      'assetAmount',
      'assetAnalysisFinished',
      'lockedForUser',
    ]),

    overallCadUploadProgress() {
      return this.part.cad_upload_progress;
    },

    uploadProcessStatus() {
      if (this.uploadProgressFrontendSide >= 1 && this.uploadProgressFrontendSide < 100) {
        return `${this.uploadStatus} (${this.uploadProgressFrontendSide} %)`;
      } else if (this.overallCadUploadProgress != '' && this.overallCadUploadProgress != null) {
        return `${this.uploadStatus} (${this.overallCadUploadProgress})`;
      } else {
        return this.uploadStatus;
      }
    },
  },

  watch: {
    cadStatus: {
      handler(status) {
        if (status) {
          this.uploadStatus = status;
        }
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['updatePart', 'updateActiveId', 'updateUnsavedChanges', 'setIsCadless', 'resetPart']),

    resetFileUpload(event) {
      /* Reset the input field so that even multiple uploads of the same file in a row are detected as inputs
          and the upload event is handeled properly */
      event.target.value = null;
    },

    preUploadFiles(event) {
      /*
      This function requires at least one file, that is uploaded.
      */
      this.cadCounter = 0;
      this.uploadCounter = 0;
      this.assetCounter = 0;
      this.uploadStatus = 'Uploading file(s)';

      this.eventFiles = event.target.files;
      if (this.eventFiles.length > 0) {
        // Check number of files, if cad file already exists and for number of cad files
        if (!this.checkFiles(event, this.eventFiles)) {
          this.resetFileUpload(event);
          return;
        }
        
        // Sort files to process CAD files first, then assets
        const sortedFiles = Array.from(this.eventFiles).sort((a, b) => {
          const aIsCAD = this.isCAD(a.name);
          const bIsCAD = this.isCAD(b.name);
          return bIsCAD - aIsCAD; // CAD files first
        });
        
        // Convert FileList to array and replace with sorted files
        this.eventFiles = new DataTransfer();
        sortedFiles.forEach(file => this.eventFiles.items.add(file));
        this.eventFiles = this.eventFiles.files;
        
        this.upload(this.eventFiles);
      } else {
        this.$root.notify('error', 'No file available', 'No file available for upload. Please try again.', 6000);
      }
    },

    upload() {
      /*
      Upload the eventfiles.
      */
      this.storedFile = this.eventFiles[this.uploadCounter];

      // only upload if filecheck is passed
      if (!this.checkFile(this.storedFile)) {
        // if filecheck is not passed, the counters have to be updated accordingly
        this.uploadCounter++;
        if (this.isCAD(this.storedFile.name)) {
          this.cadCounter -= 1;
        } else {
          this.assetCounter -= 1;
        }
        this.nextUpload();
        return;
      }
      // Upload CAD
      if (this.isCAD(this.storedFile.name)) {
        if (this.part.basename === '') {
          // if first CAD upload without questioning
          this.uploadCad(this.storedFile, this.part.part_id);
        } else {
          // if additional CAD ask what to do
          this.showAdditionalCADFileModal = true;
        }
      } else {
        // choose Asset Type and upload asset from response
        // this.chooseAssetType(this.storedFile);
        this.showChooseAssetTypeModal = true;
      }
    },

    nextUpload() {
      // trigger next upload as long as more files exist
      if (this.uploadCounter < this.eventFiles.length) {
        this.upload();
      }
    },

    checkFile(file) {
      // Check for file-format. Any supported file format should be listed here.
      let cadFileSizeLimit = 250000000; // 50 Mb
      let assetFileSizeLimit = 50000000; // 50 Mb
      let supported = this.isCAD(file.name) || this.isImage(file.name) || this.isSupportedFile(file.name);
      if (!supported) {
        this.$root.notify(
          'error',
          'Invalid format',
          'The file ' +
            file.name +
            ' has an invalid file format. Please upload supported file formats (' +
            'CAD: .stl, .stp, .step, .obj, .3mf and .ply, ' +
            'IMG: .jpeg, .jpg, .png, .tif, .tiff, ' +
            'PDF: .pdf, ' +
            'EXCEL: .xlsx, .xlsm, .xlsb, .xltx, .xltm, .xls, ' +
            'POWERPOINT: .ppt, .pptx, ' +
            'WORD: .doc, .docx, ' +
            'VIDEO: .mp4, .mpg, .mpeg',
          10000
        );
        return false;
      }
      // Check for file-size, currently limit by 50mb
      if ((this.isImage(file.name) || this.isSupportedFile(file.name)) && file.size > assetFileSizeLimit) {
        this.$root.notify(
          'error',
          'File too large',
          'The file ' + file.name + ' is too large. Maximum allowed file size is 250 Mb for assets.',
          6000
        );
        return false;
      }
      if (this.isCAD(file.name) && file.size > cadFileSizeLimit) {
        this.$root.notify(
          'error',
          'File too large',
          'The file ' + file.name + ' is too large. Maximum allowed file size is 250 Mb for CAD files.',
          6000
        );
        return false;
      }
      return true;
    },

    checkFiles(event, eventFiles) {
      /* This function checks for number of files uploaded (general) */
      let uploadBool = true;

      // Check for number of files
      if (eventFiles.length > 10) {
        this.$root.notify('error', 'Too many files', 'Only 10 files can be uploaded at a time.', 6000);
        /* Reset the input field so that even multiple uploads of the same file in a row are detected as inputs
          and the upload event is handeled properly */
        this.resetFileUpload(event);
        uploadBool = false;
      }

      // Check for number of cad files
      Array.from(eventFiles).forEach(file => {
        if (this.isCAD(file.name)) {
          this.cadCounter++;
        } else {
          this.assetCounter++;
        }
      });

      return uploadBool;
    },

    getExtension(filename) {
      let parts = filename.split('.');
      return parts[parts.length - 1];
    },

    isCAD(filename) {
      // check for supported file formats
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'stl':
        case 'stp':
        case 'step':
        case 'obj':
        case '3mf':
        case 'ply':
          return true;
      }
      return false;
    },

    isImage(filename) {
      // check for most common file formats
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'jpeg':
        case 'jpg':
        case 'png':
        case 'tiff':
        case 'tif':
          // case "psd":
          // case "eps":
          // case "ai":
          return true;
      }
      return false;
    },

    isSupportedFile(filename) {
      // check for supported file formats
      let ext = this.getExtension(filename);
      switch (ext.toLowerCase()) {
        case 'pdf':
        case 'xlsx':
        case 'xlsm':
        case 'xlsb':
        case 'xltx':
        case 'xltm':
        case 'xls':
        case 'ppt':
        case 'pptx':
        case 'doc':
        case 'docx':
        case 'mp4':
        case 'mpg':
        case 'mpeg':
          return true;
      }
      return false;
    },

    attachFile() {
      this.assetType = 'cad';
      this.uploadAsset(this.storedFile, this.part.part_id);
    },

    createNewPart() {
      this.resetPart();
      this.upload(this.storedFile);
    },

    attachAsset(assetType) {
      this.assetType = assetType;
      this.uploadAsset(this.storedFile, this.part.part_id);
    },

    cancelCADUpload() {
      this.uploadCounter++;
      this.cadCounter -= 1;
      this.nextUpload();
    },

    cancelAssetUpload() {
      // If Asset upload was canceled it will not be uploaded and proceed to next file
      this.uploadCounter++;
      this.assetCounter -= 1;
      this.nextUpload();
    },

    async uploadCad(file, part_id) {
      this.uploadCounter++;
      let formData = new FormData();
      formData.append('file', file);
      formData.append('part_id', part_id);
      formData.append('lot_size', this.part.lot_size);

      // if additional CAD file is uploaded and the user chooses to overwrite the cad file,
      // overwrite has to be set in formdata
      if (this.part.basename !== '') {
        formData.append('overwrite_cad', true);
      }

      if (this.useNewMesherForDebugging) {
        formData.append('homogen_mesh', true); // uses netgen mesher
        // formData.append('max_number_faces', 4000); // sets a upper limit to which (if given) the mesh is reduced to
      }

      this.putAndWaitForResponse = true;
      try {
        const response = await this.axiosInstanceFileUpload.post('/api/v1/part/', formData, {
          onUploadProgress: progressEvent => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            this.uploadProgressFrontendSide = percentCompleted;
          },
        });
        
        this.updatePart(response.data);
        this.putAndWaitForResponse = false;
        if (!part_id || part_id === '' || part_id === 0 || part_id === '0') {
          this.updateActiveId(response.data.part_id);
        }
        this.cadCounter -= 1;

        // check for unsaved changes
        if (this.unsavedChanges) {
          this.savePart(this.unsavedChanges);
          this.updateUnsavedChanges(undefined);
        }

        // Wait a moment to ensure part_id is properly updated in the store
        await this.$nextTick();
        this.nextUpload();
      } catch (error) {
        this.putAndWaitForResponse = false;
        this.$root.notify('error', error.response.data.error_message, 6000);
        this.nextUpload();
      }
    },

    savePart(formData) {
      // This function will parse the current 'part' instance to the database.
      this.axiosInstanceFileUpload
        .put(`/api/v1/part/${this.part.part_id}/`, formData)
        .then(response => {
          this.updatePart(response.data);
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },

    closeAdditionalCADFileModal() {
      this.showAdditionalCADFileModal = false;
      this.cancelCADUpload();
    },

    closeChooseAssetTypeModal() {
      this.showChooseAssetTypeModal = false;
      this.cancelAssetUpload();
    },

    async uploadAsset(file, part_id) {
      this.uploadCounter++;

      // Throw warning when the file already exists - currently it will be overwritten
      if (this.assetAmount > 0) {
        let assets = this.part.assets;
        for (let key in assets) {
          // skip loop if the property is from prototype
          if (!Object.prototype.hasOwnProperty.call(assets, key)) continue;

          let obj = assets[key];

          if (file.name === obj.basename) {
            this.$root.notify('warning', 'Duplicate', 'The file ' + obj.basename + ' was overwritten.', 3000);
          }
        }
      }

      // decrease number of cad files, if a cad file is uploaded as an asset
      if (this.isCAD(file.name)) {
        this.cadCounter -= 1;
        // if a cad file is handeled as an asset, the asset counter has to be increased
        this.assetCounter += 1;
      }
      let formData = new FormData();
      formData.append('file', file);
      formData.append('asset_type', this.assetType);
      formData.append('overwrite', 'true'); // true: if there is a duplicate - overwrite the file

      // reset asset type, so that dropdown selection is clean for each new asset
      this.assetType = null;

      // Use the current part_id from the store if available
      const currentPartId = this.part.part_id || part_id;
      
      // make sure to have a valid url to post on
      if (!currentPartId || currentPartId === '') {
        part_id = 0;
      } else {
        part_id = currentPartId;
      }

      // upload any supported asset file
      try {
        const response = await this.axiosInstanceFileUpload.post(`/api/v1/part/${part_id}/assets/`, formData);
        this.updatePart(response.data);
        this.updateActiveId(response.data.part_id);
        this.assetCounter -= 1;
        this.$root.notify('success', 'File uploaded', 'The file was successfully uploaded.', 3000);
        this.nextUpload();
      } catch (error) {
        this.$root.notify('error', 'File upload failed', 'Please try again.', 6000);
        this.nextUpload();
      }
    },
  },
};
</script>

<style scoped lang="scss">
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.file-drop {
  padding-bottom: 5px;
}

.drop {
  width: 100%;
  min-width: 250px;
  height: 220px;
  border: 2px dashed var(--light-color);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  background: transparent;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;

  margin-left: auto;
  margin-right: auto;

  &:hover {
    border: 2px dashed var(--primary-color);
  }
}

.wrapper {
  height: 100%;
}

.uploading {
  width: 100%;
  height: 220px;
  overflow: hidden;
  text-align: center;
  background: transparent;
  position: relative;
  top: 30%;
}

.drop .cont {
  width: 100%;
  height: 170px;
  color: #8e99a5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.file-drop__lock {
  color: var(--primary-color) !important;
  margin-top: 60px;
}

.drop .cont i {
  font-size: 40px;
  color: #787e85;
  position: relative;
}

.drop .cont .tit {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 900;
}

.drop .cont .desc {
  color: #787e85;
  font-size: 18px;
}

.drop .cont .browse {
  margin: 10px 25%;
  color: var(--light-color);
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--primary-color-light);
}

.drop input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: red;
  opacity: 0;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#list {
  width: 100%;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
}

.div-dashed-upload {
  height: 220px;
}

.show-up {
  position: relative;
  padding-top: 106px;
  height: 220px;
  overflow: hidden;
  text-align: center;
  display: block;
  opacity: 0;
  /* transition: all 0.5s ease-out; */
  animation-name: showing-up;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

.close-up {
  position: relative;
  height: 220px;
  overflow: hidden;
  text-align: center;
  display: none;
  opacity: 1;
  /* transition: all 0.5s ease-out; */
  animation-name: closing-up;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-delay: 0.1s;
}

@keyframes showing-up {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes closing-up {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.status {
  font-size: var(--12px);
  text-align: center;
  position: relative;
}

.logo-gif {
  position: relative;
  width: 85px;
}
</style>
