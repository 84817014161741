<template>
  <SparkModal dismissable :model-value="show" @update:model-value="closeModal">
    <template #header>
      <div class="flex items-center justify-between">
        <h2 class="text-24 font-bold">Asset Type</h2>
      </div>
    </template>
    <template #default>
      <div class="mb-[200px]">
        <div>Please Choose the type of asset you want to upload.</div>
        <SparkSelect v-model="assetType" name="assetType" :options="assetTypeOptions" />
      </div>
    </template>
    <template #footer>
      <div class="flex items-center justify-end gap-4">
        <SparkButton variant="outlined" @click="closeModal">Cancel</SparkButton>
        <SparkButton variant="secondary" @click="attachAsset">Upload Asset</SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'ChooseAssetTypeModal',

  components: {
    SparkModal,
    SparkButton,
    SparkSelect,
  },

  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['close', 'attach-asset'],

  data() {
    return {
      assetType: null,
    };
  },

  computed: {
    ...mapState(['assetTypes']),

    assetTypeOptions() {
      return Object.entries(this.assetTypes).map(([key, value]) => ({
        label: value.text,
        value: value.value,
      }));
    },
  },

  methods: {
    closeModal() {
      this.assetType = null;
      this.$emit('close');
    },

    attachAsset() {
      this.$emit('attach-asset', this.assetType);
      this.closeModal();
    },
  },
};
</script>
