<template>
  <div ref="tieredPriceSettings" class="flex p-20">
    <div class="flex flex-col w-1/3">
      <div class="text-16 font-bold">Tiered Pricing Configuration</div>
      <div 
        v-if="!tieredPricingConfigs?.length"
        class="text-13 text-primary-500 cursor-pointer hover:text-primary-600" 
        @click="addTieredPricingConfiguration"
      >
        Add
      </div>
    </div>
    <div class="flex flex-col w-2/3 border border-gray-50 rounded-8">
      <div
        v-for="config in tieredPricingConfigs"
        :key="config.uid"
        class="p-12 flex flex-col border-b border-gray-50"
      >
        <div class="flex justify-between">
          <div class="flex items-center">
            <i class="fas fa-layer-group mr-8 text-gray-600" />
            <div class="text-13 font-bold">{{ config.name }}</div>
            <SparkBadge 
              v-if="config.use_smart_values" 
              class="ml-8" 
              variant="outlined"
            >
              Smart
            </SparkBadge>
          </div>
          <DropdownMenu
            :list-options="dropdownOptions"
            title-icon="fas fa-ellipsis-h"
            close-click-style="always"
            @update-option="handleAction($event, config)"
          />
        </div>
        <div class="text-12 text-gray-500 mt-4">
          {{ `Lot sizes: ${config.lot_sizes?.join(', ') || 'No lot sizes defined'}` }}
        </div>
      </div>
      <div v-if="!tieredPricingConfigs?.length" class="p-12 text-13 text-gray-500">
        No tiered pricing configurations defined
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';

export default {
  name: 'TieredPricingConfigurationSettings',

  components: {
    DropdownMenu,
    SparkBadge,
  },

  data() {
    return {
      dropdownOptions: [
        { text: 'Edit', value: 'edit', icon: 'fas fa-edit' },
        { text: 'Delete', value: 'delete', icon: 'fas fa-trash' },
      ],
      tieredPriceSettings: null,
    };
  },

  computed: {
    ...mapState(['tieredPricingConfigs']),
  },

  mounted() {
    this.tieredPriceSettings = this.$refs.tieredPriceSettings;
    this.scrollToTieredPricingIfNeeded();
    this.fetchTieredPricingConfigs();
  },

  methods: {
    async fetchTieredPricingConfigs() {
      try {
        await this.$store.dispatch('fetchTieredPricingConfigs');
      } catch (error) {
        this.$root.notifyError('Error fetching tiered pricing configurations', error);
      }
    },

    scrollToTieredPricingIfNeeded() {
      if (this.$route.query.scrollToTieredPricing) {
        this.$nextTick(() => {
          this.scrollToTieredPricingSection();
        });
      }
    },

    scrollToTieredPricingSection() {
      if (this.tieredPriceSettings) {
        this.tieredPriceSettings.scrollIntoView({ behavior: 'smooth' });
      }
    },

    addTieredPricingConfiguration() {
      this.$router.push({ name: 'tiered-pricing' });
    },

    editConfig(config) {
      this.$router.push({
        name: 'tiered-pricing-edit',
        params: { configUid: config.uid },
      });
    },

    async deleteConfig(config) {
      try {
        await this.$store.dispatch('deleteTieredPricingConfig', config.uid);
        this.$root.notify('success', 'Tiered pricing configuration deleted');
      } catch (error) {
        this.$root.notifyError('Error deleting tiered pricing configuration', error);
      }
    },

    handleAction(option, config) {
      switch (option.value) {
        case 'edit':
          this.editConfig(config);
          break;
        case 'delete':
          this.deleteConfig(config);
          break;
      }
    },
  },
};
</script> 