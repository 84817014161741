<template>
  <div>
    <ErrorPage
      title="Sorry, "
      description=""
      back-to="/login"
      back-to-title="Back To Login"
      :image-name="errorImage"
      image-description="Unlicensed"
    >
      <p class="mt-32 font-bold">Your organizations license has expired.</p>
      <p class="mt-32">To continue using the service, please contact:</p>
      <ul class="list-disc list-inside">
        <li class="m-8"><b>Business Process Owner</b></li>
        <li class="m-8"><b>IT Process Owner</b></li>
        <li class="m-8"><b>Purchasing Department</b></li>
      </ul>
      <p>for assistance with license renewal.</p>
    </ErrorPage>
  </div>
</template>

<script>
import ErrorImage from '@/assets/img/error_pages/unlicensed.png';
import ErrorPage from '@/components/Reusable/ErrorPage.vue';

export default {
  name: 'SomethingWentWrong',

  components: { ErrorPage },

  computed: {
    errorImage() {
      return ErrorImage;
    },
  },
};
</script>
