<template>
  <div>
    <SparkModal dismissable :model-value="show" @update:model-value="close">
      <template #header>
        <div class="flex flex-col">
          <div>{{ mode }} Traffic Signal</div>
        </div>
      </template>
      <template #default>
        <div>
          <SparkInput v-model="formData.name" label="Name" name="name" :disabled="mode === 'View'" />

          <div class="mt-12">
            <div class="mt-12">
              <SparkSelect
                v-model="formData.type"
                label="Type"
                name="type"
                :options="signalTypeOptions"
                :disabled="mode === 'View'"
              />
            </div>
          </div>

          <div v-if="mode != 'Add'" class="mt-12">
            <div class="flex justify-between items-center">
              <div class="text-13 font-bold">Traffic Lights</div>
              <div
                v-if="mode === 'Edit'"
                class="text-13 text-primary-500 cursor-pointer hover:text-primary-600"
                @click="handleInstanceAction({ value: 'add' }, null)"
              >
                Add Traffic Light
              </div>
            </div>

            <TrafficLightForm
              v-if="showTrafficLightForm"
              :mode="trafficLightMode"
              :traffic-signal-uid="trafficSignalUidToEdit"
              :traffic-light-to-edit="trafficLightToEdit"
              :first-traffic-light="formData.trafficLights.length == 0"
              @close="closeTrafficLightForm"
            />
            <div class="mt-8 border border-gray-100 rounded-4">
              <div
                v-if="firstTrafficLight && firstTrafficLight.uid != trafficLightToEdit?.uid"
                class="border-b border-gray-100"
              >
                <div class="flex items-center gap-16 p-16">
                  <div class="flex items-center w-full gap-16 px-8">
                    <div class="text-white mr-2">&#x2630;</div>
                    <div class="flex-grow">
                      <div class="flex items-center gap-8">
                        <div class="w-8 h-8 rounded-full" :style="{ backgroundColor: firstTrafficLight.color }" />
                        <div class="text-13">{{ firstTrafficLight.name }}</div>
                      </div>
                      <div class="flex items-center gap-4 text-13 text-gray-500">Fall Back</div>
                    </div>
                    <DropdownMenu
                      v-if="mode == 'Edit'"
                      :list-options="dropdownOptions"
                      title-icon="fas fa-ellipsis-h"
                      close-click-style="always"
                      @update-option="handleInstanceAction($event, firstTrafficLight)"
                    />
                  </div>
                </div>
              </div>
              <draggable
                v-if="formData.trafficLights.length > 0"
                v-model="draggableTrafficLights"
                item-key="name"
                :disabled="mode === 'View'"
                @end="reindexTrafficLights"
              >
                <template #item="{ element }">
                  <div v-if="element.uid != trafficLightToEdit?.uid" class="border-b border-gray-100 last:border-b-0">
                    <div class="flex items-center gap-16 p-16">
                      <div class="flex items-center w-full gap-16 px-8">
                        <div class="cursor-move mr-2">&#x2630;</div>
                        <div class="flex-grow">
                          <div class="flex items-center gap-8">
                            <div class="w-8 h-8 rounded-full" :style="{ backgroundColor: element.color }" />
                            <div class="text-13">{{ element.name }}</div>
                            <div class="text-gray-400">
                              {{ parseActionTypeOption(element.type) }}
                            </div>
                          </div>
                          <div class="flex items-center gap-4 text-13 text-gray-500">
                            {{ element.rule }}
                          </div>
                        </div>
                        <DropdownMenu
                          v-if="mode == 'Edit'"
                          :list-options="dropdownOptions"
                          title-icon="fas fa-ellipsis-h"
                          close-click-style="always"
                          @update-option="handleInstanceAction($event, element)"
                        />
                      </div>
                    </div>
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>

        <div class="flex gap-8 mt-24 justify-end">
          <SparkButton variant="outlined" @click="close">
            {{ mode === 'View' ? 'Close' : 'Cancel' }}
          </SparkButton>
          <SparkButton v-if="mode !== 'View'" variant="secondary" @click="save"> Save </SparkButton>
        </div>
      </template>
    </SparkModal>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapState, mapActions } from 'vuex';

import TrafficLightForm from './TrafficLightForm.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';
import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import { notify } from '@/helpers';

export default {
  name: 'GeneralTrafficSignalFormModal',

  components: {
    SparkModal,
    SparkButton,
    SparkInput,
    SparkSelect,
    draggable,
    DropdownMenu,
    TrafficLightForm,
  },

  props: {
    show: { type: Boolean },
    mode: { type: String, default: 'Add' },
    trafficSignalToEdit: { type: Object, default: null },
    trafficSignalUidToEdit: { type: String, default: null },
  },

  emits: ['close'],

  data() {
    return {
      formData: {
        name: '',
        type: 'approval',
        trafficLights: [],
      },

      trafficLightToEdit: null,

      dropdownOptions: [
        { text: 'Edit', value: 'edit', icon: 'fas fa-edit' },
        { text: 'Delete', value: 'delete', icon: 'fas fa-trash' },
      ],

      showTrafficLightForm: false,
      trafficLightMode: 'Add',
      loading: false,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState('quotation', ['trafficSignalOptions', 'trafficSignalTemplates']),

    signalTypeOptions() {
      return this.trafficSignalOptions?.traffic_signal?.type;
    },

    draggableTrafficLights: {
      get() {
        return this.formData.trafficLights.slice(1);
      },

      set(value) {
        // Keep the first item and update the rest
        this.formData.trafficLights = [this.formData.trafficLights[0], ...value];
      },
    },

    firstTrafficLight() {
      if (this.formData.trafficLights.length > 0 && this.formData.trafficLights[0].order_index === 0) {
        return this.formData.trafficLights[0];
      }
      return null;
    },
  },

  watch: {
    show(newValue) {
      if (newValue) {
        if (this.mode === 'Add') {
          // Clear form data when modal is opened in Add mode
          this.formData = {
            name: '',
            type: 'approval',
            trafficLights: [],
          };
        } else if (this.trafficSignalToEdit) {
          // Set data only if trafficSignalToEdit exists
          this.formData = {
            name: this.trafficSignalToEdit.name || '',
            type: this.trafficSignalToEdit.type || 'approval',
            trafficLights: this.trafficSignalToEdit.trafficLights || [],
          };
        }
      }
    },

    trafficSignalUidToEdit() {
      if ((this.mode === 'Edit' || this.mode === 'View') && this.trafficSignalToEdit) {
        this.formData = {
          name: this.trafficSignalToEdit.name || '',
          type: this.trafficSignalToEdit.type || 'approval',
          trafficLights: this.trafficSignalToEdit.trafficLights || [],
        };
      }
    },

    trafficSignalTemplates: {
      handler() {
        if (this.trafficSignalUidToEdit) {
          const template = this.trafficSignalTemplates.find(template => template.id === this.trafficSignalUidToEdit);
          if (template) {
            this.formData = {
              name: template.name || '',
              type: template.type || 'approval',
              trafficLights: template.trafficLights || [],
            };
          }
        }
      },

      deep: true,
    },
  },

  methods: {
    ...mapActions(['fetchOrganization']),
    ...mapActions('quotation', [
      'addTrafficSignalTemplate',
      'editTrafficSignalTemplate',
      'editTrafficLight',
      'deleteTrafficLight',
    ]),

    close() {
      this.closeTrafficLightForm();
      this.$emit('close');
    },

    parseActionTypeOption(action_type) {
      const optionsDict = this.trafficSignalOptions?.traffic_light_action?.action_type;
      const label = optionsDict.find(option => option.value == action_type)?.label;
      return label;
    },

    async save() {
      let formData = new FormData();
      formData.append('name', this.formData.name);
      formData.append('type', this.formData.type);

      if (!this.loading) {
        if (this.mode === 'Edit') {
          this.loading = true;
          await this.editTrafficSignalTemplate({ uid: this.trafficSignalUidToEdit, formData });
          this.loading = false;
        } else {
          this.loading = true;
          await this.addTrafficSignalTemplate(formData);
          this.loading = false;
        }
        this.close();
      }
    },

    reindexTrafficLights() {
      // Update order after drag if needed
      this.formData.trafficLights = this.formData.trafficLights.map((trafficLight, index) => ({
        ...trafficLight,
        order_index: index,
      }));
      // Only update the order_index for draggable items (skip the first one)
      this.formData.trafficLights.forEach(trafficLight => {
        this.editTrafficLight({
          uid: trafficLight.uid,
          formData: { order_index: trafficLight.order_index },
        });
      });
    },

    handleInstanceAction(option, trafficLight) {
      switch (option.value) {
        case 'add':
          this.showTrafficLightForm = true;
          this.trafficLightMode = 'Add';
          this.trafficLightToEdit = null;
          break;
        case 'edit':
          this.trafficLightMode = 'Edit';
          this.trafficLightToEdit = trafficLight;
          this.showTrafficLightForm = true;
          break;
        case 'delete':
          if (trafficLight.order_index == 0) {
            notify('error', 'Traffic Light Template', 'Cannot delete fallback traffic light.');
            return;
          }
          this.formData.trafficLights.splice(trafficLight.index, 1);
          this.deleteTrafficLight(trafficLight.uid);
          break;
      }
    },

    closeTrafficLightForm() {
      this.showTrafficLightForm = false;
      this.trafficLightMode = 'Add';
      this.trafficLightToEdit = null;
    },
  },
};
</script>
