<template>
  <div class="flex flex-col gap-16">
    <div
      class="bg-white rounded-8 border-solid border border-gray-100 max-w-full flex justify-end !max-h-[70px] !min-h-[70px]"
    >
      <div class="flex mr-auto items-center gap-10 ml-8">
        <div
          class="h-40 px-10 flex items-center justify-center rounded-8 hover:cursor-pointer text-gray-600 hover:text-primary-1000"
          :class="currentRouteName === 'Comparison' ? 'bg-gray-50 text-primary-1000' : ''"
          @click="$router.push({ name: 'Comparison', params: { part_id: part.part_id } })"
        >
          <img src="@/assets/icons/supplier/building-05.svg" class="w-20 h-20 mr-8" />
          Make
        </div>
        <div
          class="h-40 px-10 flex items-center justify-center rounded-8 hover:cursor-pointer text-gray-600 hover:text-primary-1000"
          :class="currentRouteName === 'Supplier' ? 'bg-gray-50 text-primary-1000' : ''"
          @click="$router.push({ name: 'Supplier', params: { part_id: part.part_id } })"
        >
          <img src="@/assets/icons/supplier/receipt-check.svg" class="w-20 h-20 mr-8" />Buy
        </div>
      </div>

      <PartActionButtonsProduction v-if="currentRouteName === 'Comparison'" class="p-8" />
      <PartActionButtonsSuppliers v-if="currentRouteName === 'Supplier'" class="p-4" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import PartActionButtonsProduction from './PartActionButtonsProduction.vue';
import PartActionButtonsSuppliers from './PartActionButtonsSuppliers.vue';

export default {
  name: 'PartActionButtons',

  components: { PartActionButtonsProduction, PartActionButtonsSuppliers },

  computed: {
    ...mapState(['part']),
    currentRouteName() {
      return this.$route.name;
    },
  },

  mounted() {},

  methods: {},
};
</script>
