<template>
  <div class="batch-table">
    <div v-for="batch in batches" :key="batch">
      <div class="batch-title">
        <div
          v-if="selectedBatchId !== batch.batch_pk"
          class="icon-button"
          @click="select(batch.batch_pk, batch.batch_name)"
        >
          <span>{{ batch.batch_name }}</span>
        </div>
        <SparkInput
          v-else
          v-model="batch.batch_name"
          :title="batch.batch_name"
          name="batch-name"
          custom="!h-40 ml-10 mr-10"
          @keyup.enter="saveBatchName(batch.batch_pk, batch.batch_name)"
          @blur="saveBatchName(batch.batch_pk, batch.batch_name)"
        />
        <SparkBadge v-if="batch.prp_order_uid" custom="!cursor-default" class="mx-2" variant="success"
          ><div v-text="'PRP'"
        /></SparkBadge>
        <SparkBadge v-if="batch.order_finalized" custom="!cursor-default" class="mx-2" variant="success"
          ><div v-text="'Offer Requested'"
        /></SparkBadge>
        <SparkBadge
          v-if="batch.prp_order_uid"
          class="m-8"
          variant="outlined"
          @click="openOrderDetailsModal(batch.prp_order_uid)"
          ><div v-text="'View details'"
        /></SparkBadge>
        <div v-if="selectedBatchId === batch.batch_pk" class="batch-buttons">
          <AnalysisButton v-if="showAnalysisButton" :checked-parts="checkedParts" />
          <i class="icon-button fas fa-trash" @click="confirmDelete(batch.batch_pk)" />
          <i class="icon-button fas fa-times" @click="selectedBatchId = ''" />
        </div>
      </div>
      <div v-if="selectedBatchId === batch.batch_pk" class="batch-content">
        <PartTable
          :filter-key="Number(batch.batch_pk)"
          :show-title="false"
          @update:checkedParts="handleCheckedPartsUpdate"
        />
      </div>
      <div v-else class="border-bottom" />
    </div>
    <OrderDetailsModal
      :order-details="currentOrderDetails"
      :combo-categories="currentOrderDetails.prp_combo_categories"
      :text-categories="currentOrderDetails.prp_text_categories"
      :show="showOrderDetailsModal"
      :load-order-details="loadOrderDetails"
      @close="closeOrderDetailsModal"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import AnalysisButton from './AnalysisButton.vue';
import OrderDetailsModal from './OrderDetailsModal.vue';

import defaultImage from '@/assets/img/Logo_Transparent.png';
import PartTable from '@/components/PartLibrary/PartTable.vue';
import SparkBadge from '@/components/SparkComponents/SparkBadge.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'BatchTable',

  components: {
    PartTable,
    AnalysisButton,
    SparkBadge,
    SparkInput,
    OrderDetailsModal,
  },

  emits: ['selected-batch'],

  data() {
    return {
      selectedBatchId: '',
      checkedParts: [],
      showOrderDetailsModal: false,
      currentOrderDetails: {},
      allOrderDetails: [],
      loadOrderDetails: false,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['user', 'batches', 'popup']),

    showAnalysisButton() {
      return Object.keys(this.checkedParts).length > 0;
    },
  },

  watch: {
    popup: {
      handler(popup) {
        if (popup?.key === this.$options.name && popup?.formData === this.selectedBatchId) {
          if (popup?.clicked === 'ok') {
            this.triggerPopup('');
            this.deleteBatch(this.selectedBatchId);
          } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
            this.triggerPopup('');
          }
        }
      },

      deep: true,
    },

    allOrderDetails: {
      handler(value) {
        if (value.length > 0) {
          this.calculateTotalOrderPrice();
        }
      },
    },
  },

  async mounted() {
    // await this.fetchAllOrderDetails();
  },

  methods: {
    ...mapMutations(['triggerPopup']),
    ...mapActions(['fetchBatches']),

    confirmDelete(batchPk) {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete Group',
        message: 'Deleting this group will delete all the parts in the group. Are you sure?',
        buttons: true,
        formData: batchPk,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Delete', type: 'secondary', value: 'ok' },
        ],
      });
    },

    select(id) {
      this.selectedBatchId = id;
      this.$emit('selected-batch', this.selectedBatchId);
    },

    async deleteBatch(batchPk) {
      await this.axiosInstance
        .delete(`api/v1/batch/${batchPk}/`)
        .then(() => {
          this.fetchBatches();
          this.$root.notify('success', 'Group Deleted', 3000);
        })
        .catch(error => {
          if (error.response) {
            this.$root.notify('warning', error.response.data.error_message, 3000);
          }
        });
    },

    async saveBatchName(batchPk, batchName) {
      await this.axiosInstance.put(`api/v1/batch/${batchPk}/`, { batch_name: batchName });
      this.fetchBatches();
      this.batches.find(batch => batch.batch_pk === batchPk).batch_name = batchName;
      this.selectedBatchId = '';
    },

    handleCheckedPartsUpdate(checkedParts) {
      this.checkedParts = { ...checkedParts };
    },

    async openOrderDetailsModal(orderUid) {
      try {
        this.loadOrderDetails = true;
        this.showOrderDetailsModal = true;
        this.currentOrderDetails = {
          prp_parts: [],
          summary: [],
        };

        const response = await this.axiosInstance.get(`api/v1/prp-order-summary/${orderUid}/`);
        this.currentOrderDetails = response.data;

        this.currentOrderDetails.prp_parts = this.currentOrderDetails.prp_parts.map(part => {
          const chains = part.process_chains;
          const uniqueTexts = [...new Set(chains.map(chain => chain.custom_price_text).filter(text => text))];
          const numericChains = chains.filter(chain => !chain.custom_price_text);

          part.originalTexts = uniqueTexts;

          if (uniqueTexts.length === 1 && chains.every(chain => chain.custom_price_text === uniqueTexts[0])) {
            part.priceRangePerPart = uniqueTexts[0];
            part.priceRangePerLot = uniqueTexts[0];
          } else if (uniqueTexts.length > 1) {
            part.priceRangePerPart = 'Please refine your filters';
            part.priceRangePerLot = 'Please refine your filters';
          } else if (numericChains.length > 0) {
            const pricesPerLot = numericChains.map(chain => chain.market_price_pl).filter(Boolean);
            const pricesPerPart = numericChains.map(chain => chain.market_price_pp).filter(Boolean);

            part.priceRangePerPart = pricesPerPart.length
              ? `${this.$formatPrice(Math.min(...pricesPerPart))}${
                  pricesPerPart.length > 1 ? ` - ${this.$formatPrice(Math.max(...pricesPerPart))}` : ''
                }/Part`
              : 'Please refine your filters';

            part.priceRangePerLot = pricesPerLot.length
              ? `${this.$formatPrice(Math.min(...pricesPerLot))}${
                  pricesPerLot.length > 1 ? ` - ${this.$formatPrice(Math.max(...pricesPerLot))}` : ''
                }/Lot`
              : 'Please refine your filters';
          }

          return part;
        });

        const parts = this.currentOrderDetails.prp_parts;
        const partsWithOnlyText = parts.filter(
          part => part.originalTexts.length > 0 && part.process_chains.every(chain => chain.custom_price_text)
        );

        if (partsWithOnlyText.length === parts.length) {
          const allTexts = parts.flatMap(part => part.originalTexts);
          const uniqueTexts = [...new Set(allTexts)];

          if (uniqueTexts.length === 1) {
            this.currentOrderDetails.summary = [{ name: 'Total Price', value: uniqueTexts[0] }];
          } else if (uniqueTexts.length > 1) {
            this.currentOrderDetails.summary = [];
          }
        } else {
          const minTotal = this.currentOrderDetails.min_total_price;
          const maxTotal = this.currentOrderDetails.max_total_price;
          this.currentOrderDetails.summary = [
            {
              name: 'Total Price',
              value:
                minTotal !== maxTotal
                  ? `${this.$formatPrice(minTotal)} - ${this.$formatPrice(maxTotal)}`
                  : this.$formatPrice(minTotal),
            },
          ];
        }

        await Promise.all(
          this.currentOrderDetails.prp_parts.map(async part => {
            try {
              const response = await this.axiosInstance.get(`api/v1/part-thumbnail/${part.part_id}/`, {
                responseType: 'blob',
              });
              const fileReader = new FileReader();
              fileReader.readAsDataURL(response.data);
              fileReader.onload = e => {
                part.thumbnail = e.target.result || defaultImage;
              };
            } catch (error) {
              part.thumbnail = defaultImage;
            }

            try {
              const response = await this.axiosInstance.get(`/api/v1/prp-part/${part.part_id}/`);
              part.partDescription = response.data.part_description_items;
            } catch (error) {
              console.error('Error fetching part details:', error);
            }
          })
        );

        this.loadOrderDetails = false;
      } catch (error) {
        console.error('Error in openOrderDetailsModal:', error);
        this.$root.notify('warning', 'Order details not found', 3000);
      }
    },

    calculateTotalOrderPrice() {
      this.allOrderDetails.forEach(order => {
        order.minTotalPrice = order.prp_parts.reduce((acc, part) => acc + part.min_part_price_pl, 0);
      });

      this.allOrderDetails.forEach(order => {
        order.maxTotalPrice = order.prp_parts.reduce((acc, part) => acc + part.max_part_price_pl, 0);
      });

      this.allOrderDetails.forEach(order => {
        if (order.minTotalPrice !== order.maxTotalPrice) {
          order.summary = [
            {
              name: 'Total Price',
              value: `${this.$formatPrice(order.minTotalPrice)} - ${this.$formatPrice(order.maxTotalPrice)}`,
            },
          ];
        } else {
          order.summary = [
            {
              name: 'Total Price',
              value: this.$formatPrice(order.minTotalPrice),
            },
          ];
        }
      });
    },

    closeOrderDetailsModal() {
      this.showOrderDetailsModal = false;
      this.currentOrderUid = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.batch-table {
  border-radius: 10px;
  border: 1px solid var(--light-color);
  overflow: hidden;
}

.border-bottom {
  border-bottom: 1px solid var(--light-color);
}

.batch-title {
  display: flex;
  // padding: 5px 0px 5px 10px;
  background-color: white;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.batch-buttons {
  display: flex;
  align-items: center;
  // justify-items: end;
}

.batch-content {
  background-color: white;
  padding: 20px 20px 20px 25px;
  border-bottom: 1px solid var(--light-color);
}
</style>
