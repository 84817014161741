<template>
  <div class="flex flex-col am-manufacturing-cost">
    <div class="flex items-center justify-between">
      <p class="font-bold text-14 mr-8">Manufacturing Cost per Part</p>
      <SparkButton v-if="toggle" small variant="plain" @click="toggleTableChart()"
        ><div class="text-11" v-text="'Show as Chart'"
      /></SparkButton>
      <SparkButton v-else variant="plain" @click="toggleTableChart()"
        ><div class="text-11" v-text="'Show as Table'"
      /></SparkButton>
    </div>
    <div v-if="activeProcessChainIsAM">
      <CostTable v-if="toggle" :chain="activeProcessChain" :label="label" />
      <CostChart v-else :chain="activeProcessChain" :label="label" />
    </div>

    <p class="font-bold text-14 mt-20">Details</p>

    <table class="spark-table">
      <tbody>
        <tr>
          <td colspan="4">Manufacturing Cost per Part</td>
          <td class="right-aligned">
            <transition name="value-update" mode="out-in">
              {{ $formatPrice(activeProcessChain.cost.cost_pp) }}
            </transition>
          </td>
        </tr>
        <tr>
          <td colspan="4">Manufacturing Cost per Lot</td>
          <td class="right-aligned">
            <transition name="value-update" mode="out-in">
              {{ $formatPrice(activeProcessChain.cost.cost_pl) }}
            </transition>
          </td>
        </tr>
        <tr>
          <td colspan="4">Maximum Parts per Job</td>
          <td class="right-aligned">
            {{ partsPerJob }}
          </td>
        </tr>
        <tr>
          <td colspan="4">Number of Build Jobs</td>
          <td class="right-aligned">
            {{ numberOfJobs }}
          </td>
        </tr>
        <tr>
          <td colspan="4">Part cost per kg</td>
          <td class="right-aligned">{{ $formatPrice(pricePerKg) }}/kg</td>
        </tr>
        <tr>
          <td colspan="4">Part cost per cm³</td>
          <td class="right-aligned">{{ $formatPrice(pricePerVol) }}/cm³</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import SparkButton from '../../SparkComponents/SparkButton.vue';

import CostChart from '@/components/PartWorkflow/Cost/CostChart.vue';
import CostTable from '@/components/PartWorkflow/Cost/CostTable.vue';

export default {
  name: 'AMManufacturingCost',

  components: {
    CostChart,
    CostTable,
    SparkButton,
  },

  data() {
    return {
      toggle: false,
      label: {
        labor: 'Labor',
        machine: 'Machine',
        other: 'Other',
        build_material: 'Build Material',
        support_material: 'Support Material',
        waste_material: 'Waste Material',
      },
    };
  },

  computed: {
    ...mapState(['part', 'investigationDetails']),
    ...mapGetters(['processChainsInitializationFinished']),

    partsPerJob() {
      return this.activeProcessChain?.parts_pj || 0;
    },

    pricePerKg() {
      return this.activeProcessChain.cost.cost_pp / (this.activeProcessChain.part_mass / 1000);
    },

    pricePerVol() {
      return this.activeProcessChain.cost.cost_pp / this.part.part_vol;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    activeProcessChain() {
      let allChains = this.part.process_chains;
      return allChains[this.activeInvestigationDetails.uid];
    },

    activeProcessChainIsAM() {
      return this.activeProcessChain?.tech == 'additive_manufacturing';
    },

    numberOfJobs() {
      if (this.partsPerJob != 0) {
        return Math.ceil(this.activeProcessChain.lot_size / this.partsPerJob);
      } else {
        return 'n/a';
      }
    },
  },

  methods: {
    toggleTableChart() {
      this.toggle = !this.toggle;
    },
  },
};
</script>
