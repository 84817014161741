<template>
  <SparkModal dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="flex flex-col">
        <div>Add customized document</div>
      </div>
    </template>
    <template #default>
      <div>
        <TypeSelection @set-active-template-type="setActiveTemplateType($event)" />
        <div>
          <div>
            <SparkInput v-model="templatePostName" custom="!my-16" name="report-name" label="Template Name" />

            <SparkInput
              v-model="templatePostDefaultFileName"
              name="template-file-name"
              label="Template File Name"
              readonly
            />
            <input id="fileinput" type="file" accept=".docx" @change="updateCurrentUploadTemplateFile" />
            <div
              class="text-13 text-primary-500 hover:underline cursor-pointer hover:text-primary-600"
              @click="downloadDefaultTemplateDocx"
            >
              Download template for custom document
            </div>
          </div>
        </div>
      </div>
      <!-- Buttons at the bottom -->
      <div class="flex gap-8 my-8 justify-end">
        <SparkButton variant="outlined" @click="close"><div v-text="'Cancel'" /></SparkButton>
        <SparkButton variant="secondary" @click="createTemplate()"><div v-text="'Upload'" /></SparkButton>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState, mapActions } from 'vuex';

import TypeSelection from './TypeSelection.vue';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';

export default {
  name: 'AddDocumentModal',

  components: {
    SparkModal,
    SparkButton,
    SparkInput,
    SparkSelect,
    TypeSelection,
  },

  props: {
    show: { type: Boolean },
    mode: { type: String, default: 'Add' },
  },

  emits: ['close', 'select'],

  data() {
    return {
      activeTemplateType: 'part',
      showUploadForm: false,
      templatePostName: '',
      currentUploadTemplateFile: null,
      options: [
        { value: 'part', label: 'Part' },
        { value: 'part_list', label: 'Part List' },
      ],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstanceFileUpload', 'axiosInstance']),

    templatePostDefaultFileName: {
      get() {
        return this.templatePostName.replace(/ /g, '_').toLowerCase();
      },

      set() {},
    },
  },

  watch: {},

  methods: {
    close() {
      this.$emit('close');
    },

    setActiveTemplateType(type) {
      this.activeTemplateType = type;
    },

    async downloadDefaultTemplateDocx() {
      this.axiosInstance
        .get(`api/v1/doc-default-template/${this.activeTemplateType}/`, {
          responseType: 'blob',
        })
        .then(response => {
          if (this.activeTemplateType === 'part') {
            saveAs(response.data, 'spark_template_v5.docx');
          } else if (this.activeTemplateType === 'part_list') {
            saveAs(response.data, 'spark_list_template_v0.docx');
          }
          this.close();
        })
        .catch(error => {
          console.log(error);
        });
    },

    async updateCurrentUploadTemplateFile(e) {
      this.currentUploadTemplateFile = e.target.files[0];
    },

    // POST TemplateApi
    async createTemplate() {
      if (!this.currentUploadTemplateFile) {
        this.$root.notify('error', 'No file selected', 3000);
        return;
      }
      if (!this.templatePostName) {
        this.$root.notify('error', 'Please enter a Template Name', 3000);
        return;
      }
      if (!this.templatePostDefaultFileName) {
        this.$root.notify('error', 'Please Enter a Default File Name', 3000);
        return;
      }
      let formData = {
        file: this.currentUploadTemplateFile,
        name: this.templatePostName,
        default_file_name: this.templatePostDefaultFileName,
      };
      this.axiosInstanceFileUpload.post(`api/v1/doc-template-upload/${this.activeTemplateType}/`, formData).then(() => {
        this.templatePostName = '';
        this.templatePostDefaultFileName = '';
        this.currentUploadTemplateFile = null;
        document.getElementById('fileinput').value = '';
        this.close();
      });
    },
  },
};
</script>
