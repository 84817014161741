import trafficSignalActions from './actions';
import trafficSignalGetters from './getters';
import trafficSignalMutations from './mutations';
import trafficSignalState from './state';

const trafficSignalModule = {
  state: trafficSignalState,
  getters: trafficSignalGetters,
  mutations: trafficSignalMutations,
  actions: trafficSignalActions,
  namespaced: true,
};

export default trafficSignalModule;
