<template>
  <div>
    <teleport v-if="isMounted" to="#sub-navigation__action-buttons">
      <div class="flex items-center justify-center gap-8 h-56">
        <LibraryFilter @filter="filterTemplates($event)" />
      </div>
    </teleport>
    <table v-if="!loading" class="bg-white rounded-8 w-full text-13">
      <thead class="border-b border-gray-50 h-56 text-gray-400 font-normal">
        <th class="px-8 w-1/6">Name</th>
        <th class="px-8 w-1/6">Owner</th>
        <th class="px-8 w-1/6">Restriction</th>
        <th class="px-8 w-1/6">Process</th>
        <th class="px-8 w-1/6">Machine</th>
        <th class="px-8 w-1/6">Material</th>
        <th class="px-8 w-1/6">Traffic Signal</th>
        <th>&nbsp;</th>
      </thead>
      <tbody>
        <tr v-for="template in filteredTemplates" :key="template.uid" class="h-56 border-b border-gray-50">
          <td class="px-8 w-1/6">{{ template.name }}</td>
          <td class="px-8 w-1/6" v-if="template.owner_username">{{ template.owner_username }}</td>
          <td class="px-8 w-1/6" v-else>-</td>
          <td class="px-8 w-1/6" v-if="template.restriction">{{ template.restriction }}</td>
          <td class="px-8 w-1/6" v-else>-</td>
          <td class="px-8 w-1/6">{{ processSynonyms[template.prc_uid].prc_name }}</td>
          <td class="px-8 w-1/6">{{ template.machine }}</td>
          <td class="px-8 w-1/6">{{ template.material_name }}</td>
          <td class="px-8 w-1/6">
            <div v-if="template.traffic_signal_templates.length > 0">
              <div v-for="trafficSignal in template.traffic_signal_templates" :key="trafficSignal.uid">
                <div>
                  {{ trafficSignal.type.charAt(0).toUpperCase() + trafficSignal.type.slice(1) }}:
                  {{ trafficSignal.name }}
                </div>
              </div>
            </div>
            <div v-else>-</div>
          </td>
          <td class="px-8 w-1/6">
            <DropdownMenu
              :list-options="dropdownOptions[template.uid]"
              title-icon="fas fa-ellipsis-h"
              close-click-style="always"
              @update-option="dropdownEvent({ $event, template })"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else class="flex justify-center items-center h-full">
      <img class="w-24" src="@/assets/img/loading.gif" />
    </div>

    <EditProductionOptionModal
      :template="templateToEdit"
      :show="showAddTrafficLightModal"
      @close="closeAddTrafficLightModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import LibraryFilter from '../components/LibraryFilter.vue';

import EditProductionOptionModal from './components/EditProductionOptionModal.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'ProcessChainTemplateLibrary',

  components: {
    LibraryFilter,
    DropdownMenu,
    EditProductionOptionModal,
  },

  data() {
    return {
      templates: [],
      filteredTemplates: [],
      isMounted: false,
      searchQuery: '',
      loading: true,

      showAddTrafficLightModal: false,
      templateToEdit: {},

      ownDropdownOptions: [
        { text: 'Edit Production Option', value: 'edit-production-option', icon: 'fas fa-pen', disabled: false },
      ],

      defaultDropdownOptions: [
        { text: 'Edit Production Option', value: 'edit-production-option', icon: 'fas fa-pen', disabled: true },
      ],
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance']),
    ...mapState(['organization', 'user']),

    processSynonyms() {
      return this.organization.process_synonyms;
    },

    dropdownOptions() {
      let dropdownOptions = [];
      Object.keys(this.templates).forEach(key => {
        if (
          this.templates[key].restriction == 'owner' ||
          (this.templates[key].restriction == 'organization' && this.user.user_role == 'manager')
        ) {
          dropdownOptions[key] = this.ownDropdownOptions;
        } else {
          dropdownOptions[key] = this.defaultDropdownOptions;
        }
      });
      return dropdownOptions;
    },
  },

  created() {
    this.fetchTemplates();
  },

  mounted() {
    this.isMounted = true;
  },

  methods: {
    async fetchTemplates() {
      this.loading = true;
      try {
        const response = await this.axiosInstance.get('api/v1/process-chain-template-list/');
        this.templates = response.data;
        this.filteredTemplates = this.templates;
        this.loading = false;
      } catch (error) {
        this.$root.notify('error', 'Failed to fetch templates', '', 5000);
      } finally {
        this.loading = false;
      }
    },

    filterTemplates(event) {
      this.searchQuery = event;
      // Filter templates based on search query matching any field
      if (!this.templates) return [];
      // Convert object to array of values and filter
      this.filteredTemplates = Object.values(this.templates).filter(template => {
        const searchLower = this.searchQuery.toLowerCase();
        return (
          template.name?.toLowerCase().includes(searchLower) ||
          template.prc_name?.toLowerCase().includes(searchLower) ||
          template.owner_username?.toLowerCase().includes(searchLower) ||
          template.machine?.toLowerCase().includes(searchLower) ||
          template.material_name?.toLowerCase().includes(searchLower)
        );
      });
    },

    dropdownEvent({ $event, template }) {
      if ($event.value === 'edit-production-option') {
        this.showAddTrafficLightModal = true;
        this.templateToEdit = template;
      }
    },

    closeAddTrafficLightModal() {
      this.showAddTrafficLightModal = false;
      this.fetchTemplates();
    },
  },
};
</script>
