<template>
  <div>
    <SparkModal custom="sm:!max-w-[90rem] h-[90vh]" :model-value="show" @update:model-value="close">
      <template #default>
        <div class="flex justify-between items-center">
          <div class="flex flex-col gap-4">
            <div class="text-16 font-bold">Production options</div>
            <div class="text-13">
              <div v-if="lockedForUser">
                <i class="fas fa-lock" />
                <div>You can only add production options to your own part.</div>
              </div>
              <div>Select process chains to be added to comparison.</div>
            </div>
          </div>
          <div class="pr-8" @click="close">
            <i class="fas fa-xmark text-16 text-gray-700 hover:text-gray-900 hover:cursor-pointer" />
          </div>
        </div>
        <div>
          <div>
            <div
              class="flex my-12 items-center border text-gray-600 justify-between rounded-8 border-gray-50 p-16 text-13 h-[72px]"
            >
              <div class="flex gap-8">
                <div
                  class="p-8 rounded-6 cursor-pointer hover:bg-gray-50"
                  :class="selectedTab === 'template' ? ' bg-gray-50 text-primary-1000' : ''"
                  @click="switchTabs('template')"
                >
                  From Template
                </div>
                <div
                  class="p-8 rounded-6 cursor-pointer hover:bg-gray-50"
                  :class="selectedTab === 'material' ? ' bg-gray-50 text-primary-1000' : ''"
                  @click="switchTabs('material')"
                >
                  From Material
                </div>
              </div>

              <div class="flex gap-16 items-center">
                <template v-if="selectedTab === 'template'">
                  <SparkCheckbox v-model="initialRowsHidden" name="hidden-rows">
                    <div class="text-13" v-text="'Hide 3D Spark templates'" />
                  </SparkCheckbox>
                  <SparkInput v-model="searchString" placeholder="Search" name="search" custom="mb-0" />
                </template>
                <template v-else>
                  <SparkInput v-model="searchStringMaterials" placeholder="Search" name="search" custom="mb-0" />
                </template>
              </div>
            </div>
            <SuggestionTable
              v-if="selectedTab === 'template'"
              :initial-rows-hidden="initialRowsHidden"
              :search-string="searchString"
            />
            <ComparisonPageMaterialTable v-else :search-string="searchStringMaterials" type="add" />
          </div>
        </div>
      </template>
    </SparkModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import ComparisonPageMaterialTable from '../../Material/ComparisonPageMaterialTable.vue';

import SuggestionTable from './SuggestionTable.vue';

import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkModal from '@/components/SparkComponents/SparkModal.vue';

export default {
  name: 'CreateProcessChainModal',

  components: {
    SuggestionTable,
    SparkInput,
    ComparisonPageMaterialTable,
    SparkModal,
    SparkCheckbox,
  },

  props: {
    show: { type: Boolean },
  },

  emits: ['close'],

  data() {
    return { selectedTab: 'template', initialRowsHidden: true, searchString: '', searchStringMaterials: '' };
  },

  computed: {
    ...mapState(['part']),
    ...mapState('application', ['axiosInstance', 'lockedForUser']),
  },

  watch: {
    initialRowsHidden(value) {
      localStorage.setItem('hide-spark-templates', value);
    },
  },

  mounted() {
    this.initialRowsHidden = localStorage.getItem('hide-spark-templates') === 'true';
  },

  methods: {
    close() {
      this.$emit('close');
    },

    switchTabs(selection) {
      this.selectedTab = selection;
    },
  },
};
</script>
