<template>
  <div class="border rounded-8 border-gray-50 mt-16">
    
    <SparkSpinner v-if="loading" class="mx-auto my-16"/>
    
    <div v-else>
      <div v-if="materials.length > 0">
        <table id="resizable-table" class="w-full h-40 font-bold" :title="lockedForUser ? lockedTitle : ''" :disabled="processChainsComputationRunning || lockedForUser">
          <thead class="font-normal border-b border-gray-50">

        <tr class="h-40">
          <th>&nbsp;</th>
          <th v-if="hasFavourites" />
          <!-- favorite -->
          <th v-if="hasInHouse" />
          <!-- inhouse -->
          <th
            v-for="col in selectedMaterialLibraryColumns"
            :id="col[0]"
            :key="col.index"
            @click="sort(col.sorting)"
            @mouseover="hover[col.title] = true"
            @mouseleave="hover[col.title] = false"
          >
            <div class="flex items-center">
              <Sorting v-if="hover[col.title] || currentOrder === col.sorting" class="w-16 pr-2" :enabled="currentOrder === col.sorting" :reverse="currentOrderReverse" />
              <div v-else class="w-16">&nbsp;</div>
              <span>{{ col.title }}</span>
            </div>
          </th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody class="text-start !mt-0 !pt-0 font-normal">
          <tr
          v-for="material in materials"
            :key="material"
            class="h-40"
            :style="selectedMatId === material.mat_id ? 'background-color: var(--light-color); font-weight: bold' : ''"
          >
            <td class="cursor-pointer" @click="processChainAction(material)">
              <AnimationSuggestion :type="type" :creation-in-progress="(creationInProgress === material.mat_id)" :creation-finished="creationFinished == material.mat_id"  />
            </td>
            <td>
              <i v-if="material.favorite" class="fas fa-star px-4 text-gray-700" />
              <div v-else-if="hasFavourites" >&nbsp;</div>
            </td>

            <td  >
              <i v-if="material.inhouse" class="fa-solid fa-house px-4 text-gray-700" />
              <div v-else-if="hasInHouse">&nbsp;</div>
            </td>
            <td v-for="col in selectedMaterialLibraryColumns" :key="col" class="pl-16">
              {{ material[col['database']] }}
              <span v-if="material[col['database']] != null && col.unit">{{ col.unit }}</span>
             
            </td>
          </tr>
      </tbody>
      </table>
      </div>



    <div v-else class="m-auto" >
      <p>        
        Go to the <span class="font-bold cursor-pointer" @click="gotoLibrary"><i class="fas fa-arrow-right"/> Material Library</span> to select
        <ul class=" list-none">
          <li class="flex gap-8 items-center" >
            <i class="far fa-star" title="Set Favorite"/>
            <span v-text="'Favorite Materials'" />
            </li>
          <li class="flex gap-8 items-center">
            <img class="!w-16 !h-16 inline-block" src="@/assets/icons/in_house.svg"  />
            <span v-text="'In-House Materials'" />
          </li>
        </ul>
      </p>
    </div>

    <TablePagination :current-page="currentPage" :max-page-number="numberOfPages" @update-current-page="updateTable" />
  </div>
  
  </div>  
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import AnimationSuggestion from '../Comparison/components/AnimationSuggestion.vue';


import Sorting from '@/components/Reusable/Sorting.vue';
import TablePagination from '@/components/Reusable/TablePagination.vue';
import SparkSpinner from '@/components/SparkComponents/SparkSpinner.vue';


export default {
  name: 'ComparisonPageMaterialTable',

  components: {
    TablePagination,
    Sorting,
    AnimationSuggestion,
    SparkSpinner,
  },

  props: {
    searchString: { type: String, default: '' },
    type: { type: String, default: 'add' }
  },

  data() {
    return {
      materials: [],
      currentPage: undefined,
      numberOfPages: 1,
      filterParams: {},
      currentOrder: '',
      currentOrderReverse: true,
      hover: {},
      loading: false,
      creationFinished: null,
      creationInProgress: null,
      boolProhibitRapidMaterialChange: false,
    };
  },

  computed: {
    ...mapState(['part', 'user', 'investigationDetails', 'popup', 'organization']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    processChainsComputationRunning() {
      return this.$store.getters.processChainsComputationRunning;
    },

    activeInvestigationDetails() {
      return this.investigationDetails;
    },

    processUidOfActiveProcessChain() {
      if (this.activeInvestigationDetails != '') {
        return this.processChains[this.activeInvestigationDetails.uid]?.prc_uid;
      }
      return null;
    },

    hasFavourites() {
      return this.materials.some(material => material.favorite);
    },

    hasInHouse() {
      return this.materials.some(material => material.inhouse);
    },

    processChains() {
      return this.part.process_chains;
    },

    selectedMatId: {
      get() {
        return this.processChains[this.activeInvestigationDetails.uid]?.mat_id;
      },

      set(value) {
        return value;
      },
    },

    materialLibraryColumns: {
      get() {
        return this.user.user_settings.material_library_columns;
      },

      set(columns) {
        this.updateUserSettings({ material_library_columns: columns });
        this.putUserSettings({ material_library_columns: columns });
      },
    },

    // 2. get selected Part Library Columns
    selectedMaterialLibraryColumns() {
      let shown = [];
      for (let col in this.materialLibraryColumns) {
        if (this.materialLibraryColumns[col].selected === true) {
          shown.push(this.materialLibraryColumns[col]);
        }
      }
      // sort selected Columns
      shown.sort((a, b) => {
        return a.index - b.index;
      });
      return shown;
    },
  },

  watch: {
    searchString: {
      handler(newVal) {
        if (this.type === 'add') {
          this.materials = [];
          this.getFilteredMaterials(newVal);
        }
      },

      immediate: true
    },

    processUidOfActiveProcessChain: {
      handler(newVal) {
        if (newVal != null && newVal != undefined && newVal != '') {
          this.materials = [];
          this.filterParams['process_uid'] = newVal;
          this.getMaterials();
        }
      },

      immediate: true
      
      
    },

  },

  mounted() {
    this.creationInProgress = null;
    this.creationFinished = null;
  },

  methods: {
    ...mapMutations(['updateUserSettings', 'triggerPopup']),
    ...mapActions(['putUserSettings']),


    ...mapMutations({
      updateStorePart: 'updatePart',
    }),

    updateTable(pageNumber) {
      this.currentPage = pageNumber;
      this.getMaterials();
    },

    sort(str) {
      this.currentOrder = str;
      this.currentOrderReverse = !this.currentOrderReverse;
      this.filterParams['order_by'] = this.currentOrder;
      this.filterParams['order_reverse'] = this.currentOrderReverse;
      this.getMaterials();
    },

    getFilteredMaterials(event) {
      this.filterParams['filter_value'] = event;
      this.getMaterials();
    },

    gotoLibrary() {
      this.triggerPopup('');
      this.$router.push({ name: 'material-library' });
    },

    addPrcAcronym(obj) {
      Object.keys(obj).forEach(key => {
        let prc_uid = obj[key]['prc_uid'];
        obj[key]['prc_acronym'] = this.organization.process_synonyms[prc_uid].prc_acronym;
      });
    },

    getMaterials() {
      this.loading = true;
      let url = 'api/v1/material-library/';
      let params = { ...this.filterParams };
      params.inHouseFavorites = true;
      if (this.currentPage !== undefined) {
        url = `api/v1/material-library/?page=${this.currentPage}`;
      } else if (this.selectedMatId !== undefined) {
        url = `api/v1/material-library/${this.selectedMatId}/`;
      }
      // get paginated materials and not all via a prop
      this.axiosInstance
        .get(url, {
          params,
        })
        .then(response => {
          this.materials = response.data.results;
          this.addPrcAcronym(this.materials);
          this.numberOfPages = response.data.num_pages;
          this.currentPage = Number(response.data.page_number);
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
        });
    },

    resetSelectionStates() {
    this.creationInProgress = null;
    this.creationFinished = null;
  },
  
  handleError(error) {
    this.boolProhibitRapidMaterialChange = false;
    const errorMessage = error.response.data?.error_message || 'An unexpected error occurred';
    this.$root.notify('error', errorMessage, 4000);
    this.resetSelectionStates();
  },

  handleCreationSuccess(material, response) {
    this.updateStorePart(response.data);
    this.creationInProgress = null;
    this.creationFinished = material.mat_id;
    this.boolProhibitRapidMaterialChange = false;
    setTimeout(() => {
      this.creationFinished = null;
      }, 10000);
    },


  processChainAction(material) {
      if (this.boolProhibitRapidMaterialChange) {
      return;
    }
    if (this.type === 'add') {
      this.addProcessChain(material);
    } else if (this.type === 'change') {
      this.changeProcessChain(material);
    }
  },

  async addProcessChain(material) {
  try {
    this.boolProhibitRapidMaterialChange = true;
    this.creationInProgress = material.mat_id;

    const formData = {
      mat_id: material.mat_id,
      mat_name: material.mat_name,
      mat_prc: material.process_acronym,
    };
    
    const response = await this.axiosInstance.put(
      `/api/v1/part/${this.part.part_id}/`,
      formData
    );
    
    this.handleCreationSuccess(material, response);
  } catch (error) {
    this.handleError(error);
  } 
},

async changeProcessChain(material) {
  try {
    this.boolProhibitRapidMaterialChange = true;
    this.creationInProgress = material.mat_id;

    const formData = {
      mat_id: material.mat_id,
    };
    
    const response = await this.axiosInstance.put(
      `/api/v1/process-chain/${this.activeInvestigationDetails.uid}/`,
      formData
    );
    
    this.handleCreationSuccess(material, response);
  } catch (error) {
    this.handleError(error);
  }
},

  },
};
</script>
