<template>
  <SparkModal custom="w-[500px]" dismissable :model-value="show" @update:model-value="close">
    <template #header>
      <div class="text-18 font-bold" v-text="'Settings'" />
    </template>

    <template #default>
      <div class="flex flex-col">
        <div>
          <div :style="!analysisInputsComplete ? 'visibility:visible' : 'visibility:hidden'" class="warning-sub-text">
            Please upload a CAD and choose a material before changing the settings.
          </div>
        </div>
        <div class="flex">
          <div class="w-[40%]">
            <h5>General Settings</h5>
            <CostConfigSettings :chain="chain" :disabled-setting="disabledSetting" />
            <CarbonEquivalentSettings :chain="chain" :disabled-setting="disabledSetting" />
            <PricingConfigSettings :chain="chain" :disabled-setting="disabledSetting" />
          </div>
          <div class="w-[40%]">
            <h5>Geometry Settings</h5>
            <FeasibilitySettings :chain="chain" :disabled-setting="disabledSetting" />
          </div>
        </div>
      </div>
    </template>
  </SparkModal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import SparkModal from '../../../../../SparkComponents/SparkModal.vue';

import CarbonEquivalentSettings from './CarbonEquivalentSettings.vue';
import CostConfigSettings from './CostConfigSettings.vue';
import FeasibilitySettings from './FeasibilitySettings.vue';
import PricingConfigSettings from './PricingConfigSettings.vue';

import { computationRunning, analysisInputsComplete } from '@/composables/processChainHandling.js';

export default {
  name: 'SettingsModal',

  components: { FeasibilitySettings, CarbonEquivalentSettings, CostConfigSettings, PricingConfigSettings, SparkModal },

  props: {
    show: { type: Boolean },
    chain: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['close'],

  computed: {
    ...mapState(['investigationDetails', 'part', 'user']),
    ...mapGetters(['lockedForUser']),

    disabledSetting() {
      return !this.analysisInputsComplete || computationRunning(this.chain.process_chain_id) || this.lockedForUser;
    },

    analysisInputsComplete() {
      return analysisInputsComplete(this.chain.process_chain_id);
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
