<template>
  <div class="border border-gray-50 rounded-8 min-w-0">
    <div v-if="Object.keys(sortedTemplatesAndProfiles).length != 0">
      <div class="flex w-full gap-4 h-40 border-b border-gray-50 items-center text-gray-600">
        <div class="w-40 px-12 mx-8">&nbsp;</div>
        <div class="w-1/5 text-left">Name</div>
        <div class="w-1/6 text-left">Restriction</div>
        <div class="w-1/6 text-left">Process</div>
        <div class="w-1/6 text-left">Material</div>
        <div class="w-1/6 text-left">Machine</div>
        <div class="w-1/12 justify-end">Avg. Cost</div>
        <div class="w-1/12 text-left">Score</div>
        <div class="w-1/12">Date</div>
        <div class="w-40 pr-16">&nbsp;</div>
      </div>
      <div class="h-[50vh] overflow-y-auto">
        <div
          v-for="uid in sortedTemplatesAndProfiles"
          :key="uid"
          class="border-b border-gray-50 flex items-center w-full"
        >
          <div class="flex-grow w-full">
            <TemplateRow
              v-if="templatesAndProfiles[uid]?.type == 'template'"
              :template="templatesAndProfiles[uid]"
              :loading-uid="loadingUid"
              :title="templatesAndProfiles[uid]?.name"
              :material-proposal-with-highest-similarity="materialProposalWithHighestSimilarity"
              @selected-template="createProcessChains(templatesAndProfiles[uid])"
            />
            <ProfileRow
              v-else-if="templatesAndProfiles[uid]?.type == 'profile'"
              :title="templatesAndProfiles[uid]?.name"
              :profile="templatesAndProfiles[uid]"
              :loading-uid="loadingUid"
              @selected-profile="createProcessChains(templatesAndProfiles[uid])"
            />
          </div>

          <div class="flex items-center pr-16 justify-end w-40">
            <DropdownMenu
              :list-options="createDropdownOptions(templatesAndProfiles[uid])"
              close-click-style="always"
              :show-caret="false"
              title-icon="fas fa-ellipsis-h"
              @update-option="choose($event, templatesAndProfiles[uid])"
            />
          </div>
        </div>
      </div>
    </div>

    <AddEditProfileModal
      :show="showEditModal"
      mode="edit"
      :external-profile="externalProfile"
      :current-uid="currentUid"
      :current-name="currentName"
      :current-option="currentOption"
      @save="editProfile"
      @close="closeEditModal"
    />
  </div>
</template>

<script>
import { parseISO, compareDesc } from 'date-fns';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

import AddEditProfileModal from '../modals/AddEditProfileModal.vue';

import ProfileRow from './ProfileRow.vue';
import TemplateRow from './TemplateRow.vue';

import DropdownMenu from '@/components/Reusable/DropdownMenu.vue';

export default {
  name: 'SuggestionTable',

  components: { DropdownMenu, TemplateRow, ProfileRow, AddEditProfileModal },

  props: {
    initialRowsHidden: {
      type: Boolean,
    },

    searchString: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      templatesAndProfiles: {},
      loadingUid: '',
      sortedTemplatesAndProfiles: [],
      externalProfile: {},
      showEditModal: false,
      currentUid: '',
      currentOption: '',
      currentName: '',
    };
  },

  computed: {
    ...mapState(['user', 'part', 'analysisProfiles', 'analysisTemplatesAndProfiles']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),

    username() {
      return this.user.username;
    },

    reloadTemplatesAndProfiles() {
      return this.$store.state.reloadTemplatesAndProfiles;
    },

    defaultAnalysisProfile() {
      return this.user.default_analysis_profile;
    },

    currentMatAnalysis() {
      return this.part.current_mat_analysis;
    },

    cadUploadedOrCadless() {
      return this.part.part_id != 0 && (this.part.basename != '' || this.part.is_cadless);
    },

    materialProposalWithHighestSimilarity() {
      if (Object.keys(this.currentMatAnalysis).length != 0) {
        return Object.values(this.currentMatAnalysis).reduce((maxEntry, entry) => {
          if (!maxEntry || (entry.similarity && entry.similarity > maxEntry.similarity)) {
            return entry;
          }
          return maxEntry;
        }, null)?.material_uid;
      } else {
        return null;
      }
    },
  },

  watch: {
    reloadTemplatesAndProfiles() {
      this.getSortedAndFilteredTemplatesAndProfiles();
    },

    currentMatAnalysis: {
      handler() {
        this.getSortedAndFilteredTemplatesAndProfiles();
      },

      deep: true,
    },

    initialRowsHidden() {
      this.getSortedAndFilteredTemplatesAndProfiles();
    },

    searchString() {
      this.getSortedAndFilteredTemplatesAndProfiles();
    },

    analysisTemplatesAndProfiles() {
      this.getSortedAndFilteredTemplatesAndProfiles();
    },
  },

  mounted() {
    this.fetchAnalysisTemplatesAndProfiles();
    this.getSortedAndFilteredTemplatesAndProfiles();
  },

  methods: {
    ...mapActions([
      'fetchAnalysisProfileList',
      'fetchAnalysisTemplateList',
      'fetchAnalysisTemplatesAndProfiles',
      'fetchOrganization',
    ]),

    ...mapMutations(['updateTemplatesAndProfiles']),
    ...mapMutations('calibration', [
      'setCalibrationConfigUid',
      'setActualPage',
      'loadExistingCalibration',
      'setSelectedMode',
    ]),

    ...mapMutations({
      updateStorePart: 'updatePart',
    }),

    getSortedAndFilteredTemplatesAndProfiles() {
      if (this.analysisTemplatesAndProfiles != undefined) {
        this.templatesAndProfiles = { ...this.analysisTemplatesAndProfiles, ...this.currentMatAnalysis };
        this.templatesAndProfiles = this.filterBySearchString(this.templatesAndProfiles);
        this.templatesAndProfiles = this.filterByRestriction(this.templatesAndProfiles);
        this.sortedTemplatesAndProfiles = this.orderBySimilarityAndDate(this.templatesAndProfiles);
      }
    },

    filterBySearchString(templatesAndProfiles) {
      if (!this.searchString) {
        return templatesAndProfiles;
      }
      const searchLower = this.searchString.toLowerCase();
      return Object.fromEntries(
        Object.entries(templatesAndProfiles).filter(([, value]) => {
          return Object.values(value).some(field => {
            if (typeof field === 'string') {
              return field.toLowerCase().includes(searchLower);
            } else if (typeof field === 'number') {
              return field.toString().includes(searchLower);
            }
            return false;
          });
        })
      );
    },

    orderBySimilarityAndDate(obj) {
      return Object.keys(obj).sort((keyA, keyB) => {
        const similarityA = obj[keyA].similarity;
        const similarityB = obj[keyB].similarity;

        // If either has no similarity, they go after items with similarity
        if (similarityA === undefined || similarityA === null) {
          if (similarityB === undefined || similarityB === null) {
            // Neither has similarity, sort by date
            const dateA = parseISO(obj[keyA].updated);
            const dateB = parseISO(obj[keyB].updated);
            return compareDesc(dateA, dateB);
          }
          return 1; // B has similarity, A doesn't - B goes first
        }
        if (similarityB === undefined || similarityB === null) {
          return -1; // A has similarity, B doesn't - A goes first
        }

        // Both have similarity values
        if (similarityA !== similarityB) {
          return compareDesc(similarityA, similarityB);
        }

        // Equal similarities, sort by date
        const dateA = parseISO(obj[keyA].updated);
        const dateB = parseISO(obj[keyB].updated);
        return compareDesc(dateA, dateB);
      });
    },

    filterByRestriction(obj) {
      if (this.initialRowsHidden) {
        return Object.fromEntries(Object.entries(obj).filter(([, value]) => value.restriction !== null));
      } else {
        return obj;
      }
    },

    choose(event, item) {
      if (event.value === 'delete' && event.owner === this.username) {
        this.delete(item);
        this.fetchAnalysisTemplatesAndProfiles();
      } else if (event.value == 'delete') {
        this.$root.notify('error', 'Authorization Error', 'You have no permission to delete this entry.', 6000);
      } else if (event.value == 'edit') {
        this.openEditModal(item);
      } else if (event.value == 'set-default') {
        this.setDefaultAnalysisProfile(item.uid);
      } else if (event.value == 'unset-default') {
        this.setDefaultAnalysisProfile('undefined');
      } else if (event.value.includes('calibration')) {
        let index = event.value.split(':')[1];
        this.setCalibrationConfigUid(item.calibration_config_uids[index]);
        this.setActualPage('CalibrationResults');
        this.loadExistingCalibration(true);
        this.setSelectedMode(item.calibration_config_types[index]);
        this.$router.push({ path: '/calibration' });
      }
    },

    createDropdownOptions(item) {
      let options = [];
      options.push({
        text: 'Delete',
        value: 'delete',
        icon: 'fas fa-trash',
        owner: item?.owner_username,
        disabled: item?.owner_username != this.username,
      });
      if (
        item?.type == 'template' &&
        item.calibration_config_names != undefined &&
        item.calibration_config_names?.length != 0
      ) {
        item.calibration_config_names.forEach(function (name, i) {
          options.push({
            text: 'Show ' + name + ' [' + item.calibration_config_types[i].toUpperCase() + ']',
            value: 'calibration:' + i,
            icon: 'fas fa-circle-half-stroke',
            disabled: false,
          });
        });
      }
      if (item?.type == 'profile') {
        options.push({
          text: 'Edit',
          value: 'edit',
          icon: 'fas fa-pen',
          disabled: false,
        });
      }
      if (item?.type == 'profile' && this.defaultAnalysisProfile != item.uid) {
        options.push({
          text: 'Set to default',
          value: 'set-default',
          icon: 'fas fa-compass',
          owner: item?.owner_username,
          disabled: false,
        });
      } else if (item?.type == 'profile' && this.defaultAnalysisProfile == item.uid) {
        options.push({
          text: 'Remove as default',
          value: 'unset-default',
          icon: 'fas fa-compass',
          owner: item?.owner_username,
          disabled: false,
        });
      }
      options.push({ text: 'Id: ' + item?.uid, disabled: true });
      return options;
    },

    setExternalProfile(profiles) {
      this.externalProfile = {};
      Object.keys(profiles).forEach(uid => {
        if (profiles[uid].restriction == 'external') {
          this.externalProfile = profiles[uid];
        }
      });
    },

    // This one should initialize a real process chain
    async createProcessChains(item) {
      if (this.cadUploadedOrCadless) {
        this.loadingUid = item.uid;
        if (item.type === 'template') {
          if (item.owner_username === 'material_proposal') {
            let formData = { mat_id: item.material_uid };
            this.axiosInstance
              .put(`/api/v1/part/${this.part.part_id}/`, formData)
              .then(response => {
                this.updateStorePart(response.data);
                this.boolProhibitRapidMaterialChange = false;
                this.loadingUid = '';
              })
              .catch(error => {
                this.$root.notifyError('Create Process Chain', error);
              });
          } else {
            let formData = { part_id: this.part.part_id, process_chain_template_id: item.uid };
            this.axiosInstance.post(`api/v1/process-chain/`, formData).then(() => {
              this.loadingUid = '';
            });
          }
        } else if (item.type === 'profile') {
          let formData = {
            part_id: this.part.part_id,
            profile_id: item.uid,
          };
          this.axiosInstance
            .post(`/api/v1/process-chains-from-analysis-profile/`, formData)
            .then(() => {
              this.loadingUid = '';
            })
            .catch(error => {
              console.log(JSON.stringify(error));
              if (error.response.status == 403) {
                this.$root.notify('warning', 'Permission Denied', error.response.data, 3000);
              }
              this.loadingUid = '';
            });
        }
      } else {
        this.loadingUid = 'abort';
        this.$root.notify(
          'warning',
          'CAD File or part dimensions missing',
          'Please go back to the input page to propose part dimension data.',
          6000
        );
      }
    },

    async setDefaultAnalysisProfile(uid) {
      this.axiosInstance.post(`/api/v1/default-analysis-profile/${uid}/`).then(() => {
        this.$getUser(this.$keycloak, this.axiosInstance, this.$store);
        this.fetchOrganization();
        this.fetchAnalysisTemplatesAndProfiles();
      });
    },

    async openEditModal(profile) {
      this.currentUid = profile.uid;
      this.currentName = profile.name;
      this.currentOption = profile.restriction;
      this.showEditModal = true;
    },

    closeEditModal() {
      this.showEditModal = false;
    },

    async editProfile(data) {
      await this.axiosInstance
        .put(`/api/v1/analysis-profile/${this.currentUid}/`, data)
        .then(async () => {
          this.fetchAnalysisTemplatesAndProfiles();
          this.setExternalProfile(this.currentUid);
        })
        .catch(error => {
          this.$root.notify('error', 'Error', error.response.data.error_message, 6000);
        });
      if (Object.keys(this.externalProfile).length != 0) {
        if (data?.restriction === 'external' && this.externalProfile.uid !== this.currentUid) {
          this.removeProfileAsExternal();
        }
      }
    },

    async removeProfileAsExternal() {
      let formData = {
        restriction: 'owner',
      };
      this.axiosInstance.put(`/api/v1/analysis-profile/${this.externalProfile.uid}/`, formData).then(() => {
        this.updateTemplatesAndProfiles(new Date());
      });
    },

    async delete(item) {
      delete this.templatesAndProfiles[item.uid];
      if (item.type === 'template') {
        await this.axiosInstance.delete(`api/v1/template-from-process-chain/${item.uid}/`);
        this.$root.notify('success', 'Template deleted', `Template '${item.name}' successfully deleted.`, 3000);
      } else if (item.type === 'profile') {
        this.axiosInstance.delete(`/api/v1/analysis-profile/${item.uid}/`);
      }
      this.updateTemplatesAndProfiles(new Date());
      this.fetchAnalysisTemplatesAndProfiles();
    },
  },
};
</script>
