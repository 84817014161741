<template>
  <div
    v-if="!disabled"
    class="text-18"
    :class="[active ? 'text-primary-500' : 'text-gray-1000 hover:cursor-pointer hover:text-primary-400']"
    @click="$router.push({ name: routeTo, params: params })"
  >
    {{ label }}
  </div>
  <div v-else class="text-gray-400 hover:cursor-default' text-18">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'NavigationItem',

  props: {
    label: { type: String, default: '' },
    routeTo: { type: String, default: '' },
    active: { type: Boolean },
    disabled: { type: Boolean },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
