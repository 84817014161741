<template>
  <div>
    <!-- Process Chain -->
    <div class="flex justify-between">
      <ProcessStepGroup
        v-if="preProcessSteps"
        class="w-1/3 mx-8"
        :steps="preProcessSteps"
        :editable="editable"
        title="Pre-Process"
        :level="selectedLevel"
        :property="property"
        show-button
        @add="showDialog($event, 'pre')"
      />
      <ProcessStepGroup
        class="w-1/3 mx-8"
        :property="property"
        :steps="mainProcessSteps"
        :editable="editable"
        title="In-Process"
        :level="selectedLevel"
        :show-button="false"
        @add="showDialog($event, 'main')"
      />
      <ProcessStepGroup
        v-if="postProcessSteps"
        class="w-1/3 mx-8"
        :property="property"
        :steps="postProcessSteps"
        :editable="editable"
        title="Post-Process"
        :level="selectedLevel"
        show-button
        @add="showDialog($event, 'post')"
      />
    </div>
    <ProcessStepDialog :show="showModal" :additional-data="additionalData" @close="closeModal()" />
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

import ProcessStepDialog from '@/components/PartWorkflow/ProcessChain/ProcessStepDialog.vue';
import ProcessStepGroup from '@/components/PartWorkflow/ProcessChain/ProcessStepGroup.vue';

export default {
  name: 'ProcessChain',

  components: {
    ProcessStepGroup,
    ProcessStepDialog,
  },

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },

    editable: {
      type: Boolean,
    },

    selectedLevel: {
      type: Object,
      default() {
        return { _pp: 'Part' };
      },
    },

    property: {
      type: String,
      default: 'cost',
    },
  },

  data() {
    return {
      preProcessSteps: null,
      mainProcessSteps: null,
      postProcessSteps: null,
      chartData: null,
      show: false,
      filterDialog: '',
      showModal: false,
      additionalData: null,
    };
  },

  computed: {
    ...mapState(['part', 'popup']),

    showIfTechnologyAM() {
      if (this.chain?.tech === 'additive_manufacturing') {
        return true;
      } else {
        return false;
      }
    },
  },

  watch: {
    chain: function () {
      this.calculateChartData();

      this.preProcessSteps = this.calculateProcessStepGroup(['pre', 'pre_custom']);
      this.postProcessSteps = this.calculateProcessStepGroup(['post', 'post_custom']);
      this.mainProcessSteps = this.calculateProcessStepGroup(['main']);
    },

    popup(popup) {
      if (popup?.key == this.$options.name) {
        if (popup?.clicked == 'ok' || popup?.clicked == 'close' || popup?.clicked == 'cancel') {
          this.triggerPopup('');
        }
      }
    },
  },

  mounted() {
    this.calculateChartData();
    this.show = this.showIfTechnologyAM;

    this.preProcessSteps = this.calculateProcessStepGroup(['pre', 'pre_custom']);
    this.postProcessSteps = this.calculateProcessStepGroup(['post', 'post_custom']);
    this.mainProcessSteps = this.calculateProcessStepGroup(['main']);
  },

  methods: {
    ...mapMutations(['triggerPopup']),

    closeModal() {
      this.showModal = false;
    },

    showDialog(event, type) {
      this.showModal = true;
      this.additionalData = {
        chain: this.chain,
        filterDialog: this.filterDialog,
        step_type: type,
      };
    },

    calculateProcessStepGroup(stepTypes) {
      if (this.chain?.process_steps !== undefined) {
        return Object.fromEntries(
          Object.entries(this.chain?.process_steps).filter(([, step]) => stepTypes.includes(step.step_type))
        );
      }
    },

    calculateChartData() {
      let a = [];
      for (let [, value] of Object.entries(this.chain?.process_steps)) {
        a.push({
          name: value.name,
          data: [value.cost.cost_pp / this.chain.cost.cost_pp],
        });
      }
      this.chartData = a;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: white;
  z-index: 10;
  padding: unset;
  margin-top: 5px;
  overflow-x: scroll;
}

.small {
  font-size: var(--11px);
  margin-top: 0px;
}
</style>
