<template>
  <div class="flex mr-4">
    <div v-if="activeLight" class="flex items-center">
      <div class="w-16 h-16 rounded-full mx-8" :style="{ backgroundColor: activeLight?.color }" />
      {{ activeLight?.name }}
    </div>
    <div v-else class="flex">
      <div class="w-16 h-16 rounded-full mx-8 bg-gray-50" />
      <div>No active light</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TrafficSignal',

  components: {},

  props: {
    chain: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  computed: {
    activeLight() {
      return this.chain.quoting_traffic_light.active_light;
    },
  },
};
</script>
