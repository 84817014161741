import { createRouter, createWebHistory } from 'vue-router';

import AuthenticatedNotAuthorized from '../components/General/AuthenticatedNotAuthorized.vue';
import AuthenticatedUnlicensed from '../components/General/AuthenticatedUnlicensed.vue';
import CookieError from '../components/General/CookieError.vue';
import DownloadLink from '../components/General/DownloadLink.vue';
import DownloadLinkCallToAction from '../components/General/DownloadLinkCallToAction.vue';
import NotAuthorized from '../components/General/NotAuthorized.vue';
import NotFound from '../components/General/NotFound.vue';
import SilentCheckSSO from '../components/General/SilentCheckSSO.vue';
import SomethingWentWrong from '../components/General/SomethingWentWrong.vue';
import GetStarted from '../components/LandingPage/GetStarted.vue';
import LandingPage from '../components/LandingPage/LandingPage.vue';
import Login from '../components/Login/Login.vue';
import ComparisonFrame from '../components/PartWorkflow/Comparison/ComparisonFrame.vue';
import ExternalViewFrame from '../components/PartWorkflow/ExternalView/ExternalViewFrame.vue';
import InputFrame from '../components/PartWorkflow/Input/InputFrame.vue';
import OverviewFrame from '../components/PartWorkflow/Overview/OverviewFrame.vue';
import PartFrame from '../components/PartWorkflow/PartFrame.vue';
import SupplierPanelFrame from '../components/PartWorkflow/SupplierPanel/SupplierPanelFrame.vue';
import RPRLayout from '../layouts/prp/PRPLayout.vue';
// import Invite from '../components/User/Invite.vue';
import store from '../store';
import AppLoader from '../views/AppLoader.vue';
import Calibration from '../views/Calibration/Calibration.vue';
import Dashboard from '../views/Dashboard/Dashboard.vue';
import EntryPoint from '../views/EntryPoint.vue';
// import Batch from '../views/Library/batch/Batch.vue';
// import PartRequestPortal from '../views/PartRequestPortal/PartRequestPortal.vue';
import PricingOptions from '../views/Pricing/components/PricingOptions.vue';
import Pricing from '../views/Pricing/Pricing.vue';
import Quotation from '../views/Quotation/Quotation.vue';
import TieredPricingOptions from '@/views/TieredPricing/components/TieredPricingOptions.vue';
import TieredPricingForm from '@/views/TieredPricing/components/TieredPricingForm.vue';

import accountRoutes from './routes/account';
import adminRoutes from './routes/admin';
import developerRoutes from './routes/developer';
import libraryRoutes from './routes/library';
import prpRoutes from './routes/prp';
import settingsRoutes from './routes/settings';

const routes = [
  {
    path: '/',
    name: 'loader',
    component: AppLoader,
    children: [
      ...libraryRoutes,
      ...settingsRoutes,
      ...prpRoutes,
      ...accountRoutes,
      ...adminRoutes,
      ...developerRoutes,
      {
        path: '/',
        name: 'entrypoint',
        component: EntryPoint,
        props: route => ({ query: route.query.r }),
      },
      {
        path: '/partframe/:part_id?',
        props: true,
        children: [
          // /partframe/:id - <router-view> in PartFrame
          {
            path: 'int',
            name: 'partframe',
            component: PartFrame,
            children: [
              { path: 'input', name: 'Input', component: InputFrame },
              { path: 'comp', name: 'Comparison', component: ComparisonFrame },
              { path: 'supplier', name: 'Supplier', component: SupplierPanelFrame },
              { path: 'ov/:process_chain_id', name: 'Overview', component: OverviewFrame },
            ],
            props: { default: true, sidebar: false },
          },
          {
            // when /partframe/:id/ext is matched
            path: 'ext',
            name: 'ExternalViewFrame',
            component: PartFrame,
            children: [
              // when /partframe/:id/ext/input is matched
              {
                path: 'input',
                name: 'ExternalInput',
                component: InputFrame,
                // when /partframe/:id/ext/input/view is matched
                children: [{ path: 'view', name: 'ExternalView', component: ExternalViewFrame }],
              },
            ],
          },
        ],
        meta: {
          title: 'Part | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
        },
      },
      {
        path: '/start',
        name: 'LandingPage',
        component: LandingPage,
        props: true,
        meta: {
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
        },
      },
      {
        path: '/quotation',
        name: 'Quotation',
        component: Quotation,
        props: true,
        meta: {
          requireLogin: true,
          requireStaff: false,
          showBackButton: true,
          showSubNavigation: true,
          subNavigationHeading: 'Quotation',
          title: 'Quotation | 3D Spark',
          requireInternal: false,
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
        },
      },
      {
        path: '/401',
        name: 'NotAuthorized',
        component: NotAuthorized,
        props: true,
        meta: {
          requireLogin: false,
          requireStaff: false,
          requireInternal: false,
        },
      },
      {
        path: '/new-user',
        name: 'New User',
        component: AuthenticatedNotAuthorized,
        props: true,
        meta: {
          requireLogin: false,
          requireStaff: false,
          requireInternal: false,
        },
      },
      {
        path: '/unlicensed',
        name: 'No Valid License',
        component: AuthenticatedUnlicensed,
        props: true,
        meta: {
          requireLogin: false,
          requireStaff: false,
          requireInternal: false,
        },
      },
      // {
      //   path: '/invite',
      //   name: 'Invite',
      //   component: Invite,
      //   props: true,
      //   meta: {
      //     requireLogin: true,
      //     requireStaff: false,
      //     requireInternal: false,
      //     requiredRole: ['sparkmanager'],
      //   },
      // },
      {
        path: '/silent-check-sso',
        name: 'silentchecksso',
        component: SilentCheckSSO,
        props: true,
      },
      {
        path: '/cookie-error',
        name: 'CookieError',
        component: CookieError,
        props: true,
      },
      {
        path: '/something-went-wrong',
        name: 'SomethingWentWrong',
        component: SomethingWentWrong,
        props: true,
      },
      {
        path: '/404',
        name: 'NotFound',
        component: NotFound,
        props: true,
      },
      {
        path: '/get-started',
        name: 'GetStarted',
        component: GetStarted,
        props: true,
      },
      {
        path: '/download-link-call-to-action/:download_link_uid',
        name: 'DownloadLinkCallToAction',
        component: DownloadLinkCallToAction,
        props: true,
        meta: {
          title: 'Download Page | 3D Spark',
          requireLogin: false,
          requireStaff: false,
          requireInternal: false,
          hideNavBar: true,
          showGtcFooter: true,
        },
      },
      {
        path: '/download-link/:download_link_uid',
        name: 'DownloadLink',
        component: DownloadLink,
        props: true,
        meta: {
          title: 'Download Page | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
        },
      },
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          title: 'Dashboard | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: true,
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
        },
      },
      {
        path: '/login',
        name: 'login',
        component: Login,
        props: route => ({ query: route.query.r }),
        meta: {
          title: 'Log In | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
        },
      },
      // will match everything else and put it under `$route.params.pathMatch` redirecting to Login for now. Should maybe redirect to a 404 Page?
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
        },
      },
      {
        path: '/pricing',
        name: 'pricing',
        redirect: { name: 'pricing-options' },
        meta: {
          title: 'Price Configuration | 3D Spark',
          layout: RPRLayout,
          showFooter: true,
          requireLogin: true,
          requireStaff: false,
          requireInternal: true,
          showSubNavigation: true,
          subNavigationHeading: 'Price Configuration',
          requiredRole: ['sparkmanager'],
        },
        children: [
          {
            path: 'options',
            name: 'pricing-options',
            component: PricingOptions,
          },
          {
            path: 'single/:configUid?',
            name: 'pricing-single',
            component: Pricing,
            meta: {
              title: 'Standard Price Configuration | 3D Spark',
              subNavigationHeading: 'Standard Price Configuration',
            },
          },
          {
            path: 'combined/:configUid?',
            name: 'pricing-combined',
            component: Pricing,
            meta: {
              title: 'Combined Price Configuration | 3D Spark',
              subNavigationHeading: 'Combined Price Configuration',
            },
          },
          {
            path: 'tiered',
            name: 'tiered-pricing',
            component: TieredPricingForm,
            meta: {
              title: 'Tiered Pricing Configuration | 3D Spark',
              subNavigationHeading: 'Tiered Pricing Configuration',
            },
          },
          {
            path: 'tiered/:configUid',
            name: 'tiered-pricing-edit',
            component: TieredPricingForm,
            meta: {
              title: 'Edit Tiered Pricing Configuration | 3D Spark',
              subNavigationHeading: 'Edit Tiered Pricing Configuration',
            },
          },
        ],
      },
      {
        path: '/calibration',
        name: 'calibration',
        component: Calibration,
        props: true,
        meta: {
          title: 'Calibration | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: true,
          requiredRole: ['sparkmanager'],
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

router.beforeEach(async to => {
  const showSidebar = store.state.application.showSidebar;
  if (showSidebar) {
    store.commit('application/toggleSidebar');
  }

  if (to.name === 'partframe') {
    // Check if user is set to external to send it to external view
    await waitForCondition(userIsLoaded);
    if (store.state.user.is_external) {
      router.push({ name: 'prp' });
    } else if (to.params.part_id == 0) {
      router.push({ name: 'Input', params: to.params });
    } else {
      let lastSavedPage = JSON.parse(localStorage.getItem(to.params.part_id));
      if (lastSavedPage != null) {
        if (lastSavedPage.name === 'ExternalView' && !store.state.user.is_external) {
          router.push({ name: 'Input', params: lastSavedPage.params });
        } else {
          router.push({ name: lastSavedPage.name, params: lastSavedPage.params });
        }
      } else {
        // In Future, when Information about analysis Status is in Library available
        // if analyzed --> Overview
        // if !analyzed + process_chains --> Comparison
        if (store.state.part.material_name != null) {
          router.push({ name: 'Comparison', params: to.params });
        } else {
          router.push({ name: 'Input', params: to.params });
        }
      }
    }
  } else if (to.name === 'NotFound' && to.path.includes('partframe')) {
    router.push({ name: 'partframe', params: { part_id: to.path.split('/')[2] } });
  }
});

export default router;

function userIsLoaded() {
  return store.state.user.is_external != undefined;
}

function waitForCondition(conditionCheck) {
  return new Promise(resolve => {
    const intervalId = setInterval(() => {
      if (conditionCheck()) {
        clearInterval(intervalId);
        resolve();
      }
    }, 100); // Adjust the interval as needed
  });
}
