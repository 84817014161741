<template>
  <div class="bg-white ml-10 rounded-8 border border-gray-50 text-13 mb-24">
    <div v-if="isLoading" class="flex justify-center items-center py-40">
      <img src="@/assets/img/loading.gif" alt="Loading..." class="h-48" />
      <span class="ml-8 text-gray-600">Loading suppliers...</span>
    </div>
    <table v-else class="w-full">
      <thead>
        <tr class="text-gray-600 text-13 border-b border-gray-50 align-middle h-48 font-normal">
          <td class="pl-8">
            <span class="cursor-pointer" @click.stop="toggleMasterSelect()">
              <i v-if="allSelected" class="far fa-check-square text-16" />
              <i v-else class="far fa-square text-16" />
            </span>
          </td>
          <td class="max-w-[110px] truncate">Supplier</td>
          <td class="max-w-[110px] truncate">Contact</td>
          <td class="max-w-[110px] truncate">Calculation</td>
          <td class="max-w-[110px] truncate">Process</td>
          <td class="max-w-[110px] truncate">Lot Size</td>
          <td class="text-right pr-32 max-w-[110px] truncate">Price per Lot</td>
          <td class="w-40 max-w-[110px]">&nbsp;</td>
        </tr>
      </thead>
      <tbody>
        <template v-for="supplier in selectedSuppliers" :key="supplier">
          <template v-for="price in supplier.priceInfo" :key="price">
            <tr class="align-middle h-56 border-b border-gray-50 hover:bg-gray-50">
              <td class="pl-8 max-w-[110px]">
                <ActionCheckbox
                :checked-suppliers="checkedSuppliers"
                  :supplier-id="supplier.uid"
                  @click.stop="toggleCheckSupplier(supplier.uid)"
                />
              </td>
              <td class="max-w-[110px] font-medium">
                <div class="flex items-center gap-8">
                  <img class="supplier-logo" :src="supplier.logoUrl" alt="supplier-logo" />
                  <div class="truncate" :title="supplier.name">{{ supplier.name }}</div>
                </div>
              </td>
              <td class="max-w-[110px]">
                <div class="flex flex-col">
                  <div
                    class="text-gray-1000 font-medium truncate"
                    :title="`${supplier.sales_contact?.first_name} ${supplier.sales_contact?.last_name}`"
                  >
                    {{ supplier.sales_contact?.first_name }} {{ supplier.sales_contact?.last_name }}
                  </div>
                  <div class="text-gray-400 truncate" :title="supplier.sales_contact?.email">
                    {{ supplier.sales_contact?.email }}
                  </div>
                </div>
              </td>
              <td class="max-w-[110px]">
                <div
                  v-if="price.calculation_method === 'spark2spark'"
                  class="flex items-center gap-4 truncate"
                  title="LIVE"
                >
                  <img src="@/assets/icons/supplier/line-chart-up-05.svg" /> LIVE
                </div>
                <div
                  v-if="price.calculation_method === 'machine_learning'"
                  class="flex items-center gap-4 truncate"
                  title="AI Estimated"
                >
                  <img src="@/assets/icons/supplier/stars-02.svg" />AI Estimated
                </div>
              </td>
              <td class="max-w-[110px]">
                <div class="text-gray-400 truncate" :title="price.technology">{{ price.technology }}</div>
              </td>
              <td class="max-w-[110px]">
                <SparkInput
                  v-model.lazy="editableLotSize"
                  name="lot_size"
                  type="number"
                  custom=""
                  class="w-full"
                  @change="updateLotSize"
                />
              </td>
              <td class="max-w-[110px]">
                <div class="flex flex-col">
                  <div
                    v-if="
                      $formatTwoDecimalPlaces(price.target_price_pl_min) ===
                      $formatTwoDecimalPlaces(price.target_price_pl_max)
                    "
                    class="font-normal text-right text-black mr-32 truncate"
                    :title="`${$formatTwoDecimalPlaces(price.target_price_pl_min)} €`"
                  >
                    {{ $formatTwoDecimalPlaces(price.target_price_pl_min) }}<span class="text-gray-400"> €</span>
                  </div>
                  <div
                    v-else
                    class="font-normal text-right text-black mr-32 truncate"
                    :title="`${$formatZeroDecimalPlaces(price.target_price_pl_min)} € - ${$formatZeroDecimalPlaces(
                      price.target_price_pl_max
                    )} €`"
                  >
                    {{ $formatZeroDecimalPlaces(price.target_price_pl_min) }}<span class="text-gray-400"> €</span> -
                    {{ $formatZeroDecimalPlaces(price.target_price_pl_max) }}<span class="text-gray-400"> €</span>
                  </div>
                  <div
                    class="text-gray-400 text-right mr-32 text-11 truncate"
                    :title="$dayjs(price.updated).locale('de').format('DD.MM.YYYY')"
                  >
                    {{ $dayjs(price.updated).locale('de').format('DD.MM.YYYY') }}
                  </div>
                </div>
              </td>
              <td class="w-40 max-w-[110px]">
                <SparkButtonIcon icon="fa-regular fa-paper-plane" @click.stop="shareSingleRFQViaMail(supplier.uid)" />
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { mapState, mapActions, mapMutations } from 'vuex';
import 'dayjs/locale/de';

import ActionCheckbox from './components/ActionCheckbox.vue';

import SparkButtonIcon from '@/components/SparkComponents/SparkButtonIcon.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'SupplierPanelFrame',

  components: {
    SparkButtonIcon,
    ActionCheckbox,
    SparkInput,
  },

  data() {
    return {
      allSelected: false,
      checkedSuppliers: {},
      isLoading: true,
      editableLotSize: this.part?.lot_size || 1,
    };
  },

  computed: {
    ...mapState('application', ['axiosInstance', 'axiosInstanceFileUpload']),
    ...mapState(['part', 'triggerRFQ']),
    ...mapState('supplier', ['selectedSuppliers', 'selectedFilters']),

    partId() {
      return this.part.part_id;
    },
  },

  watch: {
    // allParts: {
    //   handler() {
    //     const updatedCheckedParts = {};
    //     for (let partId in this.checkedParts) {
    //       const part = this.allParts.find(part => part.part_id === partId);
    //       if (part) {
    //         updatedCheckedParts[partId] = true;
    //       }
    //     }

    //     setTimeout(() => {
    //       this.checkedParts = updatedCheckedParts;
    //     }, 300);
    //   },
    // },

    // checkedParts: {
    //   handler() {
    //     if (Object.keys(this.checkedParts).length === 0) {
    //       this.allSelected = false;
    //     }
    //   },

    //   deep: true,
    // },

    selectedSuppliers: {
      handler(newVal) {
        if (newVal && newVal.length > 0) {
          this.isLoading = false;
        }
      },

      immediate: true,
    },

    partId: {
      handler(newPartId) {
        if (newPartId) {
          this.isLoading = true;
          this.getInstantPriceData({ partId: newPartId }).finally(() => {
            setTimeout(() => {
              this.isLoading = false;
            }, 500);
          });
        }
      },

      immediate: true,
    },

    triggerRFQ(newVal) {
      if (newVal != 0) {
        this.shareRFQViaMail();
      }
    },

    'part.lot_size': {
      handler(newVal, oldVal) {
        if (newVal) {
          this.editableLotSize = newVal;
        }

        if (newVal != oldVal) {
        }
      },

      immediate: true,
    },
  },

  methods: {
    ...mapMutations(['updatePart']),
    ...mapActions('supplier', ['getInstantPriceData', 'requestPriceData']),
    ...mapMutations('supplier', ['setSelectedFilters']),
    toggleMasterSelect() {
      this.allSelected = !this.allSelected;
      if (this.allSelected) {
        this.selectedSuppliers.map(supplier => {
          this.checkedSuppliers[supplier.uid] = true;
        });
      } else {
        this.checkedSuppliers = {};
      }
    },

    toggleCheckSupplier(uid) {
      if (uid in this.checkedSuppliers) {
        delete this.checkedSuppliers[uid];
      } else {
        this.checkedSuppliers[uid] = true;
      }
    },

    shareSingleRFQViaMail(uid) {
      this.checkedSuppliers[uid] = true;
      this.shareRFQViaMail();
    },

    async shareRFQViaMail() {
      this.$root.notify('success', 'RFQ Mail', 'Generating content', 3000);

      let formData = { uids: Object.keys(this.checkedSuppliers) };
      this.axiosInstance
        .post('/api/v1/part/' + this.part.part_id + '/mail-request-for-quotation/', formData)
        .then(response => {
          // take MailTo content from response data and put it in a fresh mail
          window.open(response.data.mailto);

          if (response.data.supplier_type == 'email') {
            this.axiosInstance
              .get('/api/v1/part/' + this.part.part_id + '/zip-request-for-quotation/', {
                responseType: 'blob',
              })
              .then(response => {
                const fileName = this.part.name.replace(/\s/g, '_') + '_data_for_RFQ.zip';
                saveAs(response.data, fileName);
              })
              .catch(error => {
                this.$root.notifyError('CAD Download', error);
              });
          }
        })
        .catch(error => {
          this.$root.notifyError('RFQ Mail', error);
        });

      this.checkedSuppliers = {};
      this.allSelected = false;
    },

    updateLotSize() {
      // Ensure value is a positive integer
      let lotSize = Math.max(1, Math.round(Number(this.editableLotSize)));

      // Limit to reasonable maximum
      if (lotSize > 1000000) {
        lotSize = 1000000;
        this.$root.notify(
          'warning',
          'Quantity limit reached',
          'You have reached the quantity limit of 1.000.000.',
          3000
        );
      }

      this.editableLotSize = lotSize;

      this.axiosInstance
        .put(`/api/v1/part/${this.partId}/`, { lot_size: lotSize })
        .then(response => {
          this.$root.notify('success', 'Lot Size', 'Lot size updated successfully', 3000);
          this.updatePart(response.data);
          this.getInstantPriceData({ partId: this.partId });
          this.requestPriceData({
            partId: this.part.part_id,
            data: { mat_id: this.selectedFilters.mat_id, prc_acronym: this.selectedFilters.prc_acronym },
          });
        })
        .catch(error => {
          this.$root.notifyError('Lot Size Update', error);
          this.editableLotSize = this.part.lot_size;
        });
    },
  },
};
</script>

<style>
.supplier-logo {
  height: 30px;
  width: auto;
  max-width: 80px;
  object-fit: contain;
}
</style>
