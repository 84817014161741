<template>
  <div>
    <div class="font-semibold text-13 mb-16" v-text="'Part Details'" />
    <div class="flex flex-wrap items-start">
      <div v-for="(cat, id) in allCategories" :key="id" class="w-full">
        <div v-if="cat.items" class="flex w-full">
          <div class="w-full mb-8">
            <SparkSelect
              v-model="cat.uid"
              :name="id"
              :label="cat.label + (cat.required ? '*' : '')"
              :options="getSelectOptions(cat.items)"
              :disabled="lockedForUser || loading"
              :title="lockedForUser ? lockedTitle : ''"
              :error="shouldShowError(id, cat.uid, cat.required)"
              @click="handleSelectClick(id)"
              @update:model-value="saveComboCategory(id, cat)"
            />
          </div>
        </div>
        <div v-else class="flex w-full">
          <div class="w-full">
            <SparkInput
              v-model="cat.value"
              :name="id"
              :label="cat.label + (cat.required ? '*' : '')"
              type="text"
              autocomplete="off"
              :disabled="lockedForUser || loading"
              :title="lockedForUser ? lockedTitle : ''"
              :error="shouldShowError(id, cat.value, cat.required)"
              @change="handleFieldInteraction(id); saveTextCategory(id, cat)"
              @blur="handleFieldInteraction(id)"
            />
          </div>
          <div v-if="validateThisUrl(cat.value)" title="Go to" class="external-link-icon" @click="goToUrl(cat.value)">
            <i class="fas fa-external-link-alt" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import SparkSelect from '@/components/SparkComponents/SparkSelect.vue';
import validateUrl from '@/composables/validateUrl.js';

export default {
  name: 'PrpCategoryInput',

  components: {
    SparkInput,
    SparkSelect,
  },

  props: {
    requiredCategories: { type: Boolean },
    part: { type: Object, default: () => {} },
  },

  data() {
    return {
      comboCategories: {},
      textCategories: {},
      filledCategories: [],
      loading: false,
      touchedFields: new Set(),
    };
  },

  computed: {
    ...mapState(['user', 'highlightCategories', 'partLibraryFilters']),
    ...mapState('application', ['lockedTitle', 'axiosInstance']),
    ...mapGetters(['lockedForUser']),

    allComboCategories() {
      return this.part?.categories?.combo_categories;
    },

    allCategories() {
      const combo = this.part?.categories?.combo_categories || {};
      const text = this.part?.categories?.text_categories || {};
      const combined = { ...combo, ...text };
      return Object.fromEntries(Object.entries(combined).sort((a, b) => a[1].order_index - b[1].order_index));
    },

    allTextCategories() {
      return this.part?.categories?.text_categories;
    },
  },

  watch: {
    allComboCategories: {
      handler() {
        this.getCategories();
      },

      immediate: true,
    },
  },

  mounted() {
    for (let [key, value] of Object.entries(this.allComboCategories)) {
      if (value.uid != '') {
        this.filledCategories.push(key);
      }
    }
    for (let [key, value] of Object.entries(this.allTextCategories)) {
      if (value.value != '') {
        this.filledCategories.push(key);
      }
    }
  },

  methods: {
    ...mapActions('prp', ['fetchPrpPartLibraryData']),

    validateThisUrl(url) {
      return validateUrl(url).validateBool;
    },

    goToUrl(url) {
      let prefix = 'https://';
      if (!url.includes(prefix) && !url.includes('http://')) {
        url = prefix + url;
      }
      window.open(url, '_blank');
    },

    getCategories() {
      for (let [key, value] of Object.entries(this.allComboCategories)) {
        if (this.user.is_external && !value.visible_external) continue;
        if (value.required === this.requiredCategories) this.comboCategories[key] = value;
      }
      for (let [key, value] of Object.entries(this.allTextCategories)) {
        if (this.user.is_external && !value.visible_external) continue;
        if (value.required === this.requiredCategories) this.textCategories[key] = value;
      }
    },

    handleFieldInteraction(fieldId) {
      this.touchedFields.add(fieldId);
    },

    handleSelectClick(fieldId) {
      this.touchedFields.add(fieldId);
    },

    shouldShowError(fieldId, value, isRequired) {
      return this.touchedFields.has(fieldId) && !value && isRequired ? 'This field is required' : '';
    },

    saveTextCategory(id, textcat) {
      let cat = {};
      cat[id] = {
        category: textcat.category,
        value: textcat.value,
        required: textcat.required,
      };
      if (textcat.value != '') {
        this.filledCategories.push(id);
      } else {
        this.filledCategories = this.filledCategories.filter(item => item !== id);
      }
      this.updatePartNewPart({ text_categories: cat });
    },

    saveComboCategory(id, combocat) {
      let cat = {};
      if (combocat.uid != '') {
        cat[id] = {
          uid: combocat.uid,
          value: combocat.items[combocat.uid],
        };
        this.touchedFields.delete(id);
      } else {
        cat[id] = {
          uid: '',
          value: '',
        };
      }
      if (combocat.uid != '') {
        this.filledCategories.push(id);
      } else {
        this.filledCategories = this.filledCategories.filter(item => item !== id);
      }
      this.updatePartNewPart({ combo_categories: cat });
    },

    updatePartNewPart(data) {
      if (Object.prototype.hasOwnProperty.call(data, 'combo_categories')) {
        // change data structure for backend
        for (const [key, value] of Object.entries(data['combo_categories'])) {
          data['combo_categories'][key] = value.uid;
        }
      }
      if (this.part.part_id != undefined && this.part.part_id != '' && this.part.part_id != 0) {
        this.savePart(data);
      }
    },

    async savePart(formData) {
      this.$track('prp_set_options', {
        actionType: 'input_change',
        result: 'success',
      });
      this.loading = true;
      // This function will parse the current 'part' instance to the database.
      await this.axiosInstance
        .put(`/api/v1/part/${this.part.part_id}/`, formData)
        .then(async () => {
          let formData = JSON.parse(JSON.stringify(this.partLibraryFilters));
          formData = { ...formData, ...{ page: this.currentPage }, ...{ not_checked_out: true } };
          await this.fetchPrpPartLibraryData(formData);
          this.loading = false;
        })
        .catch(error => {
          console.log(JSON.stringify(error));
        });
    },

    getSelectOptions(items) {
      return Object.entries(items).map(([value, label]) => ({
        value,
        label,
      }));
    },
  },
};
</script>
