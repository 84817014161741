<template>
  <div class="relative text-16">
    <div
      class="flex justify-between items-center p-8 rounded-8 border border-gray-300 cursor-pointer"
      @click="toggleDropdown"
    >
      <span class="text-16 px-8">Processes </span>
      <span class="text-16 px-8 text-primary-500"> {{ selectedProcessesText }} </span>
      <i class="fas fa-chevron-down" />
    </div>
    <div
      v-if="isOpen"
      class="absolute w-[300px] top-full rounded-8 right-0 bg-white border border-gray-100 shadow-md z-10"
    >
      <input
        v-model="searchQuery"
        type="text"
        placeholder="Search..."
        class="w-full border-none focus:outline-none focus:ring-0 focus:border-gray-300"
        @input="filterProcesses"
      />
      <div class="max-h-[500px] overflow-y-auto border-t border-gray-100">
        <div
          class="flex items-center justify-start px-8 py-4 hover:bg-gray-50 hover:cursor-pointer border-b border-gray-100"
          @click="selectAllProcesses"
        >
          <div class="flex items-center">
            <input
              id="select-all"
              :checked="isAllSelected"
              type="checkbox"
              class="mr-2 rounded-6 border border-gray-300 text-primary-500 focus:ring-primary-600 focus:ring-offset-0"
            />
            <div class="flex items-center font-medium">Select All</div>
          </div>
        </div>

        <div
          v-for="process in filteredAndOrderedProcesses"
          :key="process[0]"
          class="flex items-center px-8 py-4 hover:bg-gray-50 hover:cursor-pointer"
          @click="selectProcess(process[1])"
        >
          <div class="flex items-center">
            <input
              :id="`process-${process[0]}`"
              v-model="selectedProcesses"
              type="checkbox"
              :value="process[1]"
              class="mr-2 rounded-6 border border-gray-300 text-primary-500 focus:ring-primary-600 focus:ring-offset-0"
            />
            <div class="flex items-center">{{ process[1].prc_name }}</div>
          </div>
          <div class="flex items-center px-4 py-2 bg-gray-50 rounded-6 ml-auto">
            {{ countOfAvailableSteps(process[1].original_prc_acronym) }}
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="Object.keys(selectedProcesses).length > 0"
      class="absolute right-0 mb-2 hover:cursor-pointer hover:text-primary-500 text-11"
      @click="clearSelectedProcesses"
    >
      <span class="mb-4">Clear</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ProcessFilter',

  props: {
    processSteps: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  emits: ['update-selected-processes'],

  data() {
    return {
      isOpen: false,
      searchQuery: '',
      selectedProcesses: [],
      universalProcess: {
        prc_name: 'All Processes',
        original_prc_acronym: 'all_processes',
        prc_acronym: 'all_processes',
        isUniversal: true,
      },
    };
  },

  computed: {
    ...mapState(['processes', 'organization']),

    processSynonyms() {
      return this.organization.process_synonyms;
    },

    selectedProcessesText() {
      if (this.selectedProcesses.length === 0) {
        return '';
      } else if (this.selectedProcesses.length === 1) {
        return '|  ' + this.selectedProcesses[0].prc_name;
      } else {
        const firstProcess = this.selectedProcesses[0].prc_name;
        const remainingCount = this.selectedProcesses.length - 1;
        return '|  ' + `${firstProcess}, +${remainingCount} more`;
      }
    },

    filteredAndOrderedProcesses() {
      const query = this.searchQuery.toLowerCase();
      const filteredProcesses = Object.entries(this.processSynonyms).filter(([, value]) => {
        return (
          value?.original_prc_acronym?.toLowerCase().includes(query) ||
          value?.prc_acronym?.toLowerCase().includes(query) ||
          value?.prc_name?.toLowerCase().includes(query)
        );
      });
      filteredProcesses.push(['all_processes', this.universalProcess]);
      let result = this.orderProcesses(Object.fromEntries(filteredProcesses));
      return result;
    },

    isAllSelected() {
      if (this.filteredAndOrderedProcesses.length === 0) return false;

      return this.filteredAndOrderedProcesses.every(process =>
        this.selectedProcesses.some(p => p.original_prc_acronym === process[1].original_prc_acronym)
      );
    },

    isUniversalSelected() {
      return this.selectedProcesses.some(p => p.original_prc_acronym === 'all_processes');
    },

    countOfUniversalSteps() {
      return this.countOfAvailableSteps('all_processes');
    },
  },

  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },

  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },

    handleOutsideClick(event) {
      const dropdownEl = this.$el;
      if (this.isOpen && !dropdownEl.contains(event.target)) {
        this.isOpen = false;
      }
    },

    orderProcesses(processes) {
      let sortedEntries = [];
      if (processes != undefined) {
        sortedEntries = Object.entries(processes).sort(([, value1], [, value2]) => {
          const name1 = value1.prc_name.toLowerCase();
          const name2 = value2.prc_name.toLowerCase();
          return name1.localeCompare(name2);
        });
      }
      return sortedEntries;
    },

    selectAllProcesses() {
      if (this.isAllSelected) {
        this.filteredAndOrderedProcesses.forEach(process => {
          const index = this.selectedProcesses.findIndex(
            p => p.original_prc_acronym === process[1].original_prc_acronym
          );
          if (index > -1) {
            this.selectedProcesses.splice(index, 1);
          }
        });
      } else {
        this.filteredAndOrderedProcesses.forEach(process => {
          const isAlreadySelected = this.selectedProcesses.some(
            p => p.original_prc_acronym === process[1].original_prc_acronym
          );
          if (!isAlreadySelected) {
            this.selectedProcesses.push(process[1]);
          }
        });
      }

      this.$emit('update-selected-processes', this.selectedProcesses);
    },

    selectProcess(process) {
      const index = this.selectedProcesses.findIndex(p => p.original_prc_acronym === process.original_prc_acronym);
      if (index > -1) {
        this.selectedProcesses.splice(index, 1);
      } else {
        this.selectedProcesses.push(process);
      }

      this.$emit('update-selected-processes', this.selectedProcesses);
      this.searchQuery = '';
    },

    selectUniversalProcess() {
      const index = this.selectedProcesses.findIndex(p => p.original_prc_acronym === 'none');
      if (index > -1) {
        this.selectedProcesses.splice(index, 1);
      } else {
        this.selectedProcesses.push(this.universalProcess);
      }

      this.$emit('update-selected-processes', this.selectedProcesses);
    },

    countOfAvailableSteps(original_prc_acronym) {
      const filteredKeys = Object.keys(this.processSteps).filter(
        key => this.processSteps[key]['prc_acronym'] === original_prc_acronym
      );
      return filteredKeys.length;
    },

    clearSelectedProcesses() {
      this.selectedProcesses = [];
      this.$emit('update-selected-processes', this.selectedProcesses);
      this.isOpen = false;
      this.searchQuery = '';
    },
  },
};
</script>
