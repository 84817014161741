<template>
  <div class="flex flex-col pl-8" @click="selectProcessChain()">
    <div class="flex items-center font-bold">
      <div class="flex-1">
        <div class="truncate w-[300px]">{{ chain.name }}</div>
      </div>
      <MainBenchmarkBadge class="mr-4" :chain="chain" />
      <SparkBadge v-if="!analysisFinished" class="mr-4" variant="outlined">Estimation</SparkBadge>
      <ChainMenu class="ml-auto" :chain="chain" :class="computationRunning ? 'cursor-wait' : ''" />
    </div>
    <div v-if="mouseHover === 'name'">
      <div class="text-primary-500" @click="trigger()"><i class="fas fa-arrow-right pr-8" />Edit</div>
    </div>
    <div v-else class="text-gray-300 flex items-center truncate w-[300px]">
      {{ actualProcessSynonym }} <i class="far fa-circle text-[3px] mx-4" /> {{ chain.material_name }}
      <i class="far fa-circle text-[3px] mx-4" /> {{ chain.machine_name }}
    </div>

    <ChangeProcessChainPopup :show="showPopup" :chain="chain" @close="closePopup" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

import SparkBadge from '../../../../SparkComponents/SparkBadge.vue';

import ChainMenu from './components/ChainMenu.vue';
import ChangeProcessChainPopup from './components/ChangeProcessChainPopup.vue';
import MainBenchmarkBadge from './components/MainBenchmarkBadge.vue';

import { analysisFinished, computationRunning } from '@/composables/processChainHandling.js';

export default {
  name: 'ProcessChainName',

  components: {
    ChainMenu,
    MainBenchmarkBadge,
    SparkBadge,
    ChangeProcessChainPopup,
  },

  props: {
    chain: { type: Object, required: true },
    mouseHover: { type: String, default: '' },
  },

  data() {
    return {
      showPopup: false,
    };
  },

  computed: {
    ...mapState(['user', 'organization']),
    analysisFinished() {
      return analysisFinished(this.chain.process_chain_id);
    },

    processSynonyms() {
      return this.organization.process_synonyms;
    },

    actualProcessSynonym() {
      return this.processSynonyms[this.chain.prc_uid]?.prc_acronym;
    },

    computationRunning() {
      return computationRunning(this.chain.process_chain_id);
    },
  },

  methods: {
    ...mapMutations(['changeInvestigationDetails']),

    selectProcessChain() {
      this.changeInvestigationDetails({ uid: this.chain.process_chain_id });
    },

    trigger() {
      this.showPopup = true;
    },

    closePopup() {
      this.showPopup = false;
    },
  },
};
</script>
