<template>
  <div class="p-16">
    <div class="flex flex-col mb-12">
      <SparkInput v-model="batchName" label="Group Name" type="text" name="batch-name" />
      <SparkCheckbox
        v-model="checkBoxSkipDrawingAnalysis"
        class="my-2"
        name="skip-drawing-analysis"
        @change="newSkipDrawingAnalysisValue($event)"
      >
        <div v-text="'Skip Drawing Analysis'" />
      </SparkCheckbox>
      <SparkCheckbox v-model="checkBoxRipAssemblies" name="rip-assemblies" @change="newRipAssembliesValue($event)">
        <div v-text="'Rip Assemblies'" />
      </SparkCheckbox>
      <SparkCheckbox v-model="checkBoxMarketPrices" name="market-prices" @change="newMarketPricesValue($event)">
        <div v-text="'Save market prices'" />
      </SparkCheckbox>
    </div>
    <div
      class="text-right text-13 text-primary-700 hover:text-primary-500 cursor-pointer"
      @click="downloadMetadataTemplate"
    >
      Download Template
    </div>
    <div class="text-right text-11 text-gray-500">
      Download and fill in the template to include meta data with your group upload.
      <a
        href="https://support.3dspark.de/uploading-multiple-parts-batch-upload-part-groups"
        target="_blank"
        rel="noopener noreferrer"
        class="text-11 text-primary-500 cursor-pointer hover:text-primary-700"
        >Need help?</a
      >
    </div>
    <div class="div-dashed-upload mt-24">
      <div class="wrapper">
        <div class="drop">
          <div class="cont">
            <i class="fa fa-cloud-upload" />
            <div class="tit">Drag & Drop Files</div>
            <div class="desc">or</div>
            <SparkButton variant="secondary" class="spark-btn">Browse...</SparkButton>
          </div>
          <output id="list" />
          <input
            type="file"
            multiple
            accept=".stl,.stp,.step,.jpg,.jpeg,.png,.tif,.tiff,.pdf,.xlsx,.xlsm,.xlsb,.xltx,.xltm,.xls,.ppt,.pptx,.doc,.docx,.mp4,.mpg,.mpeg"
            name="cu-input-upload-folder"
            @input="uploadFile"
            @click="resetFileUpload"
            @drop="resetFileUpload"
          />
        </div>
        <div v-if="batchUploading" class="uploading loading-image-container">
          <img src="@/assets/img/Logo_Loop_Transparent.gif" class="logo-gif" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import to from 'await-to-js';
import readXlsxFile from 'read-excel-file';
import { mapState, mapActions } from 'vuex';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';
import { getExtension, isExcel } from '@/helpers.js';

export default {
  name: 'BatchFileDrop',

  components: { SparkButton, SparkCheckbox, SparkInput },

  emits: ['batch-upload-complete'],

  data() {
    return {
      batchUploading: false,
      batchName: '',
      checkBoxSkipDrawingAnalysis: false,
      checkBoxRipAssemblies: false,
      checkBoxMarketPrices: false,
    };
  },

  computed: {
    ...mapState(['part', 'popup']),
    ...mapState('application', ['axiosInstance']),
  },

  methods: {
    ...mapActions(['fetchBatches']),

    newSkipDrawingAnalysisValue(event) {
      this.checkBoxSkipDrawingAnalysis = event;
    },

    newRipAssembliesValue(event) {
      this.checkBoxRipAssemblies = event;
    },

    newMarketPricesValue(event) {
      this.checkBoxMarketPrices = event;
    },

    async uploadFile(event) {
      this.batchUploading = true;
      let files = event.target.files; // all files: excel, cads and assets
      let file_dict = {}; // dictionary for better management {STRING: 'my_cad.step' -> FILE: my_cad.step}
      let excel_file = null; // excel file, which holds all information

      // Create file list and check if an excel sheet is given
      for (let i = 0; i < files.length; ++i) {
        // Finding excel and append all files to the file dict
        let file = files[i];
        let filename = file.name;
        if (isExcel(getExtension(filename))) {
          excel_file = file; // excel file found
        }
        file_dict[filename] = file;
      }

      if (excel_file) {
        await this.processExcelFile(excel_file, file_dict);
      } else {
        await this.processCadFiles(file_dict);
      }

      this.batchUploading = false;
    },

    async processExcelFile(excel_file, file_dict) {
      // Read excel file with options to parse dates
      let rows = await readXlsxFile(excel_file, {
        dateFormat: 'DD.MM.YYYY',
        transformData: data => {
          return data.map(row => {
            return row.map(cell => {
              if (cell instanceof Date) {
                return cell.toISOString().split('T')[0];
              }
              return cell;
            });
          });
        },
      });
      let header_row = rows[0]; // The first row hold the header (dict keys)
      let data_dict = {}; // Dictionary with column names as key and values of the current row
      let header_list = []; // List of all column_names

      // Populate data_dict and header_list with column_names
      for (let i = 0; i < header_row.length; ++i) {
        let column_name = header_row[i];
        header_list.push(column_name);
        data_dict[column_name] = i; // in the future, all other rows have the [column_name] value on index i
      }

      // Populate value dict with values from all rows but the header
      let value_rows = rows.slice(1); // Remove header row
      let new_value_rows = [];
      for (let i = 0; i < value_rows.length; ++i) {
        let row = value_rows[i];
        let value_dict = {}; // Temporary dictionary to map column name and row values
        Object.assign(value_dict, data_dict);
        for (let j = 0; j < header_list.length; ++j) {
          let column_name = header_list[j]; // j: index of column_name
          value_dict[column_name] = row[data_dict[column_name]]; // and we use the column_name in our value_dict to save its value in the result
        }
        value_dict['errors'] = { keys: [] };
        if (data_dict['Analysis Profile']) {
          value_dict['analysis_profile'] = row[data_dict['Analysis Profile']];
        }
        // Include a flag for CAD-less parts
        if (!value_dict['cad']) {
          value_dict['cad_less'] = true;
        }
        new_value_rows.push(value_dict);
      }
      value_rows = new_value_rows; // value_rows are dictionaries for each part where all necessary infos are stored

      // Create batch to upload parts to
      let batch_name = this.batchName;
      let formData = new FormData();
      formData.append('name', batch_name);
      formData.append('size', value_rows.length);
      let [err, response] = await to(this.axiosInstance.post('/api/v1/batch/', formData));
      if (err) {
        this.handleError(err, 'Failed to create batch.');
        return;
      }
      // The response includes batch_pk and batch_name
      let batch_pk = response.data.batch_pk;
      this.fetchBatches();

      // Post each part to the part API
      await this.uploadParts(value_rows, file_dict, batch_pk);
    },

    async processCadFiles(file_dict) {
      let cad_files = Object.keys(file_dict).filter(filename => !isExcel(getExtension(filename)));

      // Create a batch for the CAD files
      let batch_name = this.batchName;
      let formData = new FormData();
      formData.append('name', batch_name);
      formData.append('size', cad_files.length);
      let [err, response] = await to(this.axiosInstance.post('/api/v1/batch/', formData));
      if (err) {
        this.handleError(err, 'Failed to create batch.');
        return;
      }
      let batch_pk = response.data.batch_pk;
      this.fetchBatches();

      // Create a default entry for each CAD file and upload them
      let value_rows = cad_files.map(cad => ({ cad, errors: { keys: [] } }));
      await this.uploadParts(value_rows, file_dict, batch_pk);
    },

    async uploadParts(value_rows, file_dict, batch_pk) {
      for (let i = 0; i < value_rows.length; ++i) {
        let part_data = value_rows[i];
        let form_data_part_upload = new FormData();
        form_data_part_upload.append('batch_pk', batch_pk);
        form_data_part_upload.append('batch_upload', true); // Let the backend know this request came from a batch upload
        form_data_part_upload.append('rip_assemblies', this.checkBoxRipAssemblies);
        form_data_part_upload.append('market_price_calculation', this.checkBoxMarketPrices);
        form_data_part_upload.append('queue', 'batch_queue'); // Use batch queue worker
        for (let property in part_data) {
          if (Object.prototype.hasOwnProperty.call(part_data, property) && part_data[property]) {
            form_data_part_upload.append(property, part_data[property]);
          }
        }

        // Check if the part is CAD-less
        if (!part_data.cad) {
          form_data_part_upload.append('is_cadless', true);
          // Include additional fields specific to CAD-less parts
          if (part_data['material']) {
            form_data_part_upload.append('current_material', part_data['mat_name']);
          }
          if (part_data['bb_x']) {
            form_data_part_upload.append('bb_x', part_data['bb_x']);
          }
          if (part_data['bb_y']) {
            form_data_part_upload.append('bb_y', part_data['bb_y']);
          }
          if (part_data['bb_z']) {
            form_data_part_upload.append('bb_z', part_data['bb_z']);
          }
          if (part_data['volume']) {
            form_data_part_upload.append('part_vol', part_data['volume']);
          }
          if (part_data['mass']) {
            form_data_part_upload.append('part_mass', part_data['mass']);
          }
        } else {
          form_data_part_upload.append('file', file_dict[part_data['cad']]);
        }

        if (part_data['analysis_profile_id']) {
          form_data_part_upload.append('analysis_profile_id', part_data['analysis_profile_id']);
        }

        let [err, upload_response] = await to(
          // Post part. Returns similar to this: {3316-1085_D.jt.ipt.stl: "811292c3d9db4783b5e90f078631423b", 312805ac3.stp: "6df6882cb0d54d749885d202ab21d701"}
          this.axiosInstance.post('/api/v1/part/', form_data_part_upload)
        );

        // Error handling
        if (err) {
          part_data['errors']['cad_error'] = JSON.stringify(err);
          part_data['errors']['keys'].push('cad_error');
        } else {
          part_data.part_id = upload_response.data.part_id;

          // Post image assets
          if (part_data.hasOwnProperty('images') && part_data['images']) {
            let images = part_data['images'];
            images = images.split(';');
            for (let image_index = 0; image_index < images.length; ++image_index) {
              let image = images[image_index];
              let formData_image_upload = new FormData();
              formData_image_upload.append('file', file_dict[image]);
              formData_image_upload.append('part_id', part_data.part_id);
              formData_image_upload.append('asset_type', 'image');
              let [err, upload_image_response] = await to(
                this.axiosInstance.post(`api/v1/part/${part_data.part_id}/assets/`, formData_image_upload)
              );
              if (err) {
                part_data['errors']['image_error'] = JSON.stringify(err);
                part_data['errors']['keys'].push('image_error');
              } else {
                // A file was actually transmitted
                if (!upload_image_response.data.message) {
                  //TODO: needs to change in cases where file is not supposed to be overwritten
                  part_data['image_uploaded'] = true;
                }
              }
            }
          }

          // Post drawing assets
          if (part_data.hasOwnProperty('drawings') && part_data['drawings']) {
            let drawings = part_data['drawings'];
            drawings = drawings.split(';');
            for (let drawing_index = 0; drawing_index < drawings.length; ++drawing_index) {
              let drawing = drawings[drawing_index];
              let formData_drawing_upload = new FormData();
              formData_drawing_upload.append('file', file_dict[drawing]);
              formData_drawing_upload.append('part_id', part_data.part_id);
              formData_drawing_upload.append('asset_type', 'drawing');
              formData_drawing_upload.append('skip_drawing_analysis', this.checkBoxSkipDrawingAnalysis);
              let [err, upload_drawing_response] = await to(
                this.axiosInstance.post(`api/v1/part/${part_data.part_id}/assets/`, formData_drawing_upload)
              );
              if (err) {
                part_data['errors']['drawing_error'] = JSON.stringify(err);
                part_data['errors']['keys'].push('drawing_error');
              } else {
                // A file was actually transmitted
                if (!upload_drawing_response.data.message) {
                  //TODO: needs to change in cases where file is not supposed to be overwritten
                  part_data['drawing_uploaded'] = true;
                }
              }
            }
          }
        }
      }

      this.$emit('batch-upload-complete');
    },

    handleError(err, message) {
      this.$root.notify('error', message, '', 5000);
      this.batchUploading = false;
    },

    resetFileUpload(event) {
      /* Reset the input field so that even multiple uploads of the same file in a row are detected as inputs
          and the upload event is handled properly */
      event.target.value = null;
    },

    async downloadMetadataTemplate() {
      try {
        const response = await this.axiosInstance.get('api/v1/metadata-template/', {
          responseType: 'blob',
        });

        // Create download link
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'metadata_template.xlsx');
        document.body.appendChild(link);
        link.click();

        // Cleanup
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error downloading template:', error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.spark-btn {
  align-self: flex-end;
  width: 100px;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

.drop:hover {
  border: 3px dashed var(--primary-color);
}

.drop {
  width: 100%;
  height: 220px;
  border: 3px dashed var(--light-color);
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  background: transparent;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin: 0px;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.uploading .upload-done {
  height: 220px;
  overflow: hidden;
  text-align: center;
  background: transparent;
  position: relative;
}

.drop .cont {
  width: 100%;
  height: 170px;
  color: #8e99a5;
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.drop .cont i {
  font-size: 40px;
  color: #787e85;
  position: relative;
}

.drop .cont .tit {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 900;
}

.drop .cont .desc {
  color: #787e85;
  font-size: 18px;
}

.drop .cont .browse {
  margin: 10px 25%;
  color: var(--light-color);
  padding: 8px 16px;
  border-radius: 4px;
  background: var(--primary-color-light);
}

.drop input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  background: red;
  opacity: 0;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#list {
  width: 100%;
  text-align: left;
  position: absolute;
  left: 0;
  top: 0;
}

.div-dashed-upload {
  margin-bottom: 10px;
}

.loading-image-container {
  height: 50px;
  width: 50px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-gif {
  max-width: 100%;
  max-height: 100%;
}

.margin-left {
  margin-left: 15px;
}
</style>
