<template>
  <div class="max-w-[750px] rounded-8 flex flex-col bg-white gap-20 min-w-[750px] p-20 my-48 mx-auto border border-gray-100">
    <Teleport v-if="isMounted" to="#footer-content">
      <div class="flex justify-self-start">
        <SparkButton
          variant="outlined"
          @click="$router.push({ name: 'organization-settings', query: { scrollToTieredPricing: 'true' } })"
        >
          <div v-text="'Back to Settings'" />
        </SparkButton>
      </div>
      <div class="flex justify-self-center" />
      <div class="flex justify-self-end gap-16">
        <SparkButton 
          variant="secondary" 
          :disabled="!isFormValid"
          @click="saveConfig"
        >
          <div v-text="isEditing ? 'Update' : 'Create'" />
        </SparkButton>
      </div>
    </Teleport>

    <div class="text-18 font-semibold mb-20">
      {{ isEditing ? 'Edit Tiered Pricing Configuration' : 'New Tiered Pricing Configuration' }}
    </div>

    <SparkForm ref="form" class="flex flex-col gap-16" @submit="saveConfig">
      <SparkInput
        v-model="form.name"
        label="Configuration Name"
        name="name"
        placeholder="Enter configuration name"
        required
        :error="errors.name"
        @change="validateField('name')"
      />
      <SparkCheckbox
        v-model="form.useSmartValues"
        name="use-smart-values"
        title="Automatically includes 'parts per job' and its multiples within the lot size range"
      >
        <div class="flex flex-col">
          <span class="text-13 font-medium leading-none">
            Add Smart Values
          </span>
        </div>
      </SparkCheckbox>

      <div class="flex flex-col gap-8">
        <span v-if="form.useSmartValues" class="text-11 text-gray-500 leading-tight">
          Automatically includes 'parts per job' and its multiples within the lot size range
        </span>
        <SparkInput
          v-model="form.lotSizes"
          label="Lot Sizes"
          name="lot-sizes"
          placeholder="Enter comma-separated lot sizes (e.g., 1, 5, 10, 50)"
          help-text="Enter positive integers separated by commas"
          required
          :error="errors.lotSizes"
          @input="sanitizeInput"
          @change="validateField('lotSizes')"
        />
      </div>
    </SparkForm>
  </div>
</template>

<script>
import { z } from 'zod';

import SparkButton from '@/components/SparkComponents/SparkButton.vue';
import SparkCheckbox from '@/components/SparkComponents/SparkCheckbox.vue';
import SparkForm from '@/components/SparkComponents/SparkForm.vue';
import SparkInput from '@/components/SparkComponents/SparkInput.vue';

export default {
  name: 'TieredPricingForm',

  components: {
    SparkButton,
    SparkForm,
    SparkInput,
    SparkCheckbox,
  },

  data() {
    return {
      isMounted: false,
      form: {
        name: '',
        lotSizes: '',
        useSmartValues: false,
      },

      errors: {
        name: '',
        lotSizes: '',
      },

      isFormValid: true,
    };
  },

  computed: {
    isEditing() {
      return !!this.$route.params.configUid;
    },

    configUid() {
      return this.$route.params.configUid;
    },

    schema() {
      return z.object({
        name: z.string().min(1, 'Name is required').max(255, 'Name is too long'),
        lotSizes: z.string().min(1, 'Lot sizes are required')
          .refine(
            value => /^[\d,\s]*$/.test(value),
            'Only numbers and commas are allowed'
          )
          .refine(
            value => value.split(',').every(num => {
              const val = parseInt(num.trim());
              return val > 0 && val <= 10000;
            }),
            'All values must be positive integers not exceeding 10.000'
          ),
      });
    },
  },

  async mounted() {
    this.isMounted = true;
    if (this.isEditing) {
      await this.loadConfig();
    }
  },

  methods: {
    sanitizeInput(event) {
      // Only allow numbers, commas, and spaces
      this.form.lotSizes = event.target.value.replace(/[^0-9,\s]/g, '');
    },

    validateField(field) {
      if (!this.schema.shape[field]) return;

      const result = this.schema.safeParse(this.form);
      const errors = result.error?.issues;
      
      if (errors) {
        this.errors[field] = errors.find(issue => issue.path[0] === field)?.message || '';
      } else {
        this.errors[field] = '';
      }

      this.isFormValid = !Object.values(this.errors).some(error => error);
    },

    validateForm() {
      const result = this.schema.safeParse(this.form);
      if (!result.success) {
        result.error.issues.forEach(issue => {
          this.errors[issue.path[0]] = issue.message;
        });
        return false;
      }
      return true;
    },

    async loadConfig() {
      try {
        const config = await this.$store.dispatch('fetchTieredPricingConfig', this.configUid);
        this.form.name = config.name;
        this.form.useSmartValues = config.use_smart_values;
        this.form.lotSizes = config.lot_sizes.join(', ');
      } catch (error) {
        this.$root.notifyError('Error loading configuration', error);
      }
    },

    async saveConfig() {
      if (!this.validateForm()) {
        return;
      }

      try {
        const lotSizes = this.form.lotSizes
          .split(',')
          .map(x => parseInt(x.trim()))
          .filter(x => !isNaN(x) && x > 0)
          .sort((a, b) => a - b);

        const payload = {
          name: this.form.name,
          lot_sizes: lotSizes,
          use_smart_values: this.form.useSmartValues,
        };

        if (this.isEditing) {
          await this.$store.dispatch('updateTieredPricingConfig', {
            uid: this.configUid,
            data: payload,
          });
          this.$root.notify('success', 'Configuration updated successfully');
        } else {
          await this.$store.dispatch('createTieredPricingConfig', payload);
          this.$root.notify('success', 'Configuration created successfully');
        }

        this.$router.push({
          name: 'organization-settings',
          query: { scrollToTieredPricing: 'true' },
        });
      } catch (error) {
        this.$root.notifyError('Error saving configuration', error);
      }
    },
  },
};
</script> 