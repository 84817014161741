<template>
  <div
    class="part relative bg-white border rounded-8 p-24 border-x border-gray-200 h-[12.5rem] w-full flex justify-between"
    data-test="prp-part-card"
    :class="{
      'border-l-8 border-primary-500 transition-all duration-300 ease-out':
        currentPartId === part.part_id && !isSummary,
      'border-red-500': shouldShowError && !currentPartCategoriesValid
    }"
  >
    <div v-if="!isSummary" class="absolute top-32 left-32 z-10" @click.stop="closeSidebar">
      <SparkCheckbox v-if="part" v-model="selected" name="select" class="absolute left-4" :part="part" />
    </div>
    <div class="flex gap-12">
      <PartImage v-if="part" :part="part" @selected="setSelected" />
      <div class="flex flex-col gap-12">
        <div class="flex items-center gap-4">
          <div :title="part.name" class="font-bold max-w-[20rem] truncate" data-test="prp-part-name" v-text="part.name" />
          <i
            v-if="shouldShowError && !currentPartCategoriesValid"
            class="fas fa-circle-exclamation text-red-500"
            title="Required information missing"
          />
        </div>

        <div v-if="partDescriptions.length">
          <table>
            <tr v-for="(descItem, index) in partDescriptions" :key="index">
              <td class="text-gray-800 pr-8" v-text="descItem.descriptionLabel" />
              <td class="text-gray-500" v-text="descItem.value" />
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div v-if="isSummary" class="text-gray-800">
      <div class="flex justify-between gap-120 mb-12">
        <div class="flex gap-12 items-center">
          <div v-text="'Quantity'" />
          <SparkInput
            v-model.lazy="quantity"
            :rules="fieldSchema"
            readonly
            name="quantity"
            custom="text-right"
            class="w-60"
          />
        </div>
        <div v-if="showPrice" class="flex flex-col items-end">
          <div class="text-primary-500 font-bold" v-text="marketPricePerPartRange" />
          <div
            v-if="quantity > 1 && marketPricePerLotRange !== marketPricePerPartRange"
            class="font-bold text-primary-500"
            v-text="marketPricePerLotRange"
          />
        </div>
      </div>
    </div>
    <div v-else class="flex flex-col justify-between items-end gap-12 h-full">
      <div v-if="loading && showPrice" class="self-center">
        <SparkSpinner class="!w-24 !h-24" />
      </div>
      <div v-else-if="showPrice" class="font-bold flex flex-col items-end">
        <div class="font-bold text-primary-500" v-text="marketPricePerPartRange" />
        <div
          v-if="quantity > 1 && marketPricePerLotRange !== marketPricePerPartRange"
          class="font-bold text-primary-500"
          v-text="marketPricePerLotRange"
        />
      </div>
      <div class="flex items-center gap-12">
        <div v-text="'Quantity'" />
        <SparkInput
          v-model="quantity"
          :rules="fieldSchema"
          :disabled="loading"
          type="number"
          name="quantity"
          custom="text-center text-16"
          class="w-120"
          @change="updateLotSize"
        />
      </div>
      <SparkBadge
        v-if="showFeasibility"
        class="cursor-default"
        :variant="isFeasible ? 'success' : 'warning'"
        custom="w-100 h-120"
      >
        <div class="flex items-center justify-center w-full gap-16">
          <i :class="`fas fa-${isFeasible ? 'check' : 'exclamation'}`" />
          <span v-text="'Feasibility'" />
        </div>
      </SparkBadge>
      <SparkButtonIcon icon="fas fa-trash" @click.stop="confirmDelete" />
    </div>
  </div>
</template>

<script>
import { toTypedSchema } from '@vee-validate/zod';
import { mapState, mapMutations, mapActions } from 'vuex';
import * as zod from 'zod';

import SparkBadge from '../../../components/SparkComponents/SparkBadge.vue';
import SparkButtonIcon from '../../../components/SparkComponents/SparkButtonIcon.vue';
import SparkCheckbox from '../../../components/SparkComponents/SparkCheckbox.vue';
import SparkInput from '../../../components/SparkComponents/SparkInput.vue';
import SparkSpinner from '../../../components/SparkComponents/SparkSpinner.vue';

import PartImage from './PartImage.vue';

import { totalFeasFromObj } from '@/helpers.js';

export default {
  name: 'Part',

  components: { PartImage, SparkBadge, SparkInput, SparkButtonIcon, SparkCheckbox, SparkSpinner },

  props: {
    part: { type: Object, default: () => {} },
    isSummary: { type: Boolean },
    properties: { type: Object, default: () => {} },
    currentPartId: { type: String, default: '' },
    showValidationErrors: {
      type: Boolean,
      default: false
    }
  },

  emits: ['update:price-per-part', 'update:price-per-lot', 'selected', 'price-per-part-updated', 'sorted-chains'],

  data() {
    return {
      quantity: '',
      processChains: [],
      partDescriptions: [],
      loading: false,
      updateTimeout: null,
    };
  },

  computed: {
    ...mapState(['popup', 'user', 'partLibraryFilters', 'organization']),
    ...mapState('application', ['axiosInstance', 'showPrpSidebar']),
    ...mapState('prp', ['selectedPrpParts', 'processChainUpdated', 'prpParts']),

    sortedChains() {
      let chains = Object.values(this.processChains);
      return chains.sort(
        (a, b) => a.market_price.market_price_pp.calculated - b.market_price.market_price_pp.calculated
      );
    },

    showPrice() {
      return this.organization.prp_show_price;
    },

    showFeasibility() {
      return this.organization.prp_show_feasibility;
    },

    marketPricePerLotRange() {
      const chainsLength = this.sortedChains.length;
      if (chainsLength === 0) {
        return "This option isn't available. Please adjust your filters.";
      }

      // Get all unique custom price texts
      const uniqueTexts = [...new Set(this.sortedChains
        .map(chain => chain.custom_price_text)
        .filter(text => text))];

      // If all chains have the same custom text, show it
      if (uniqueTexts.length === 1 && this.sortedChains.every(chain => chain.custom_price_text === uniqueTexts[0])) {
        return uniqueTexts[0];
      }

      // If multiple different texts exist
      if (uniqueTexts.length > 1) {
        return "Please refine your filters";
      }

      // Handle numeric prices (including mixed case with some custom texts)
      const numericChains = this.sortedChains.filter(chain => !chain.custom_price_text);
      if (numericChains.length) {
        const lowerRange = numericChains[0].market_price.market_price_pl.calculated;
        const higherRange = numericChains[numericChains.length - 1].market_price.market_price_pl.calculated;
        const formattedLowerRange = this.$formatPrice(lowerRange);
        const formattedHigherRange = this.$formatPrice(higherRange);

        return formattedLowerRange === formattedHigherRange 
          ? `${formattedLowerRange}/Lot`
          : `${formattedLowerRange} - ${formattedHigherRange}/Lot`;
      }

      return "Please refine your filters";
    },

    marketPricePerPartRange() {
      const chainsLength = this.sortedChains.length;
      if (chainsLength === 0) {
        return "This option isn't available. Please adjust your filters.";
      }

      // Get all unique custom price texts
      const uniqueTexts = [...new Set(this.sortedChains
        .map(chain => chain.custom_price_text)
        .filter(text => text))];

      // If all chains have the same custom text, show it
      if (uniqueTexts.length === 1 && this.sortedChains.every(chain => chain.custom_price_text === uniqueTexts[0])) {
        return uniqueTexts[0];
      }

      // If multiple different texts exist
      if (uniqueTexts.length > 1) {
        return "Please refine your filters";
      }

      // Handle numeric prices (including mixed case with some custom texts)
      const numericChains = this.sortedChains.filter(chain => !chain.custom_price_text);
      if (numericChains.length) {
        const lowerRange = numericChains[0].market_price.market_price_pp.calculated;
        const higherRange = numericChains[numericChains.length - 1].market_price.market_price_pp.calculated;
        const formattedLowerRange = this.$formatPrice(lowerRange);
        const formattedHigherRange = this.$formatPrice(higherRange);

        return formattedLowerRange === formattedHigherRange 
          ? `${formattedLowerRange}/Part`
          : `${formattedLowerRange} - ${formattedHigherRange}/Part`;
      }

      return "Please refine your filters";
    },

    isFeasible() {
      for (let chain of this.sortedChains) {
        if (this.feasibility(chain)) {
          return true;
        }
      }
      return false;
    },

    fieldSchema() {
      return toTypedSchema(zod.number().min(1, 'Must be at least 1').max(1000000, 'Must be at most 1000000'));
    },

    currentPartCategoriesValid() {
      const currentPart = this.prpParts.find(part => part.part_id === this.part.part_id);
      return (
        Object.values(currentPart.categories.combo_categories)
          .filter(category => category.required)
          .every(category => category.value) &&
        Object.values(currentPart.categories.text_categories)
          .filter(category => category.required)
          .every(category => category.value)
      );
    },

    selected: {
      get() {
        return this.selectedPrpParts[this.part.part_id];
      },

      set(value) {
        this.setSelectedPrpParts({ [this.part.part_id]: value });
        this.$emit('selected', value);
      },
    },

    shouldShowError() {
      return this.showValidationErrors && !this.isSummary;
    }
  },

  watch: {
    part: {
      handler(newValue, oldValue) {
        this.quantity = newValue.primary_lot_size;
        if (newValue !== oldValue) {
          this.updateLotSize();
        }
      },

      immediate: true,
      deep: true,
    },

    sortedChains: {
      handler(value) {
        this.$emit('sorted-chains', value);
      },

      immediate: true,
      deep: true,
    },

    properties: {
      async handler() {
        await this.fetchProcessChains();
        await this.fetchPartDetails();
      },

      deep: true,
    },

    deselect() {
      if (this.deselect) {
        this.selected = false;
      }
    },

    selected() {
      this.$emit('selected', this.selected);
    },

    popup: {
      handler(popup) {
        if (popup?.key === this.$options.name && popup?.formData === this.partId) {
          if (popup?.clicked === 'ok') {
            this.triggerPopup('');
            this.deletePart();
          } else if (popup?.clicked === 'cancel' || popup?.clicked === 'close') {
            this.triggerPopup('');
          }
        }
      },

      deep: true,
    },

    marketPricePerLotRange: {
      handler(value) {
        const parsedPriceRange = this.parsePriceRange(value);
        this.$emit('update:price-per-lot', parsedPriceRange);
      },

      immediate: true,
    },

    marketPricePerPartRange: {
      handler(value) {
        const parsedPriceRange = this.parsePriceRange(value);
        this.$emit('update:price-per-part', parsedPriceRange);
        this.$emit('price-per-part-updated', value);
      },

      immediate: true,
    },

    processChainUpdated: {
      handler(value) {
        if (value) {
          this.setProcessChainUpdated(false);
        }
      },
    },
  },

  async created() {
    this.fetchProcessChains();
    await this.fetchPartDetails();
  },

  methods: {
    ...mapMutations('application', ['togglePrpSidebar']),
    ...mapMutations(['triggerPopup']),
    ...mapMutations('prp', ['setSelectedPrpParts', 'setProcessChainUpdated']),
    ...mapActions('prp', ['fetchPrpPartLibraryData']),

    setSelected(selected) {
      this.selected = selected;
      this.$emit('selected', selected);
    },

    feasibility(chain) {
      if (chain == undefined) return undefined;
      if (chain.feasibility == undefined) return undefined;

      let feasibility_values = {};
      if (chain.feasibility && Object.keys(chain.feasibility).length > 0) {
        feasibility_values = totalFeasFromObj(chain.feasibility);
      }

      return feasibility_values;
    },

    async deletePart() {
      await this.axiosInstance
        .delete(`/api/v1/part/${this.part.part_id}/`)
        .then(async () => {
          this.$root.notify('success', 'Part deleted successfully', '', 6000);
          let formData = JSON.parse(JSON.stringify(this.partLibraryFilters));
          formData = { ...formData, ...{ page: this.currentPage }, ...{ not_checked_out: true } };
          await this.fetchPrpPartLibraryData(formData);
          if (this.showPrpSidebar) {
            this.togglePrpSidebar();
          }
        })
        .catch(error => {
          this.$root.notify('error', 'Delete part error', error, 6000);
        });
    },

    confirmDelete() {
      this.triggerPopup({
        key: this.$options.name,
        show: true,
        title: 'Delete Part',
        message: 'The part ' + this.part.name + ' will be deleted. Are you sure?',
        buttons: true,
        formData: this.partId,
        buttonContent: [
          { text: 'Cancel', type: 'outlined', value: 'cancel' },
          { text: 'Delete', type: 'secondary', value: 'ok' },
        ],
      });
    },

    updateLotSize() {
      if (this.updateTimeout) clearTimeout(this.updateTimeout);
      
      this.updateTimeout = setTimeout(async () => {
        const quantity = Math.round(Number(this.quantity));
        this.loading = true;
        
        try {
          await this.axiosInstance.put(`/api/v1/prp-part/${this.part.part_id}/`, { quantity });
          await this.fetchProcessChains();
        } catch (error) {
          console.error('Error updating lot size:', error);
        } finally {
          this.loading = false;
        }
      }, 500);
    },

    async fetchProcessChains() {
      let params = {};
      for (let key in this.properties) {
        if (this.properties[key]) {
          params[`${key}`] = this.properties[key];
        }
      }

      try {
        const response = await this.axiosInstance.get(`/api/v1/prp-price-range/${this.part.part_id}/`, { params });
        this.processChains = response.data.part.process_chains;
      } catch (error) {
        console.error('Error fetching process chains:', error);
      }
    },

    parsePriceRange(input) {
      function parseEuroNumber(euroNumber) {
        return parseFloat(euroNumber.replace(/\./g, '').replace(',', '.'));
      }

      let cleanedInput = (input || '').replace(/[^\d,.-]/g, '').trim();
      let values = cleanedInput.split('-').map(value => value.trim());

      let lowerValue = values.length > 0 ? parseEuroNumber(values[0]) : 0;
      let higherValue = values.length > 1 ? parseEuroNumber(values[1]) : 0;

      return {
        lowerValue: lowerValue,
        higherValue: higherValue,
      };
    },

    async fetchPartDetails() {
      try {
        const data = await this.axiosInstance.get(`/api/v1/prp-part/${this.part.part_id}/`);
        this.partDescriptions = data.data.part_description_items;
        if (this.partDescriptions.length > 4) {
          this.partDescriptions = this.partDescriptions.slice(0, 4);
        }
      } catch (error) {
        console.error('Error fetching part details:', error);
      }
    },

    closeSidebar() {
      if (this.showPrpSidebar) {
        this.togglePrpSidebar();
      }
    },
  },
};
</script>

<style>
.category-field.error {
  @apply border-red-500;
}
</style>
