<template>
  <div class="relative w-full mb-4">
    <label v-if="label" class="block text-13 text-gray-500 mb-4">{{ label }}</label>
    <div class="relative w-full" :class="{ 'border-b-transparent rounded-t-6': isOpen }">
      <div
        class="relative flex items-center justify-between w-full h-40 border border-gray-200 rounded-6 bg-white hover:border-primary-600"
        :class="{ 'rounded-b-none': isOpen, '!border-red-500': !!hasErrors }"
        @click="toggleDropdown"
      >
        <input
          type="text"
          class="flex-1 w-full h-40 border-none px-12 py-8 outline-none text-16 text-gray-900 bg-transparent focus:outline-none focus:ring-0"
          :class="{
            '!border-gray-200 !bg-gray-50 !text-gray-300': disabled,
          }"
          v-model="searchTerm"
          :placeholder="placeholder"
          @click.stop="openDropdown"
          @input="filterOptions"
          data-test="spark-select-input"
          ref="inputRef"
        />
        <div class="pr-12 flex items-center transition-transform duration-200">
          <i :class="['fas', isOpen ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
        </div>
      </div>

      <div
        class="absolute top-full left-0 w-full max-h-200 overflow-y-auto bg-white border border-gray-200 border-t-0 rounded-b-6 z-10 shadow-lg"
        v-show="isOpen"
      >
        <div
          v-for="option in filteredOptions"
          :key="option.value"
          class="px-12 py-8 cursor-pointer text-16 min-h-32 flex items-center hover:bg-primary-50"
          :class="{ 'bg-primary-50': selectedValue === option.value }"
          @click="selectOption(option)"
        >
          {{ option.label }}
        </div>
        <div
          v-if="filteredOptions.length === 0"
          class="px-12 py-8 text-gray-500 text-16 min-h-32 flex items-center justify-center italic"
        >
          No results found
        </div>
      </div>
    </div>
    <div v-if="error" class="min-h-16">
      <span class="text-11 text-red-500 block mt-2" v-text="error" />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
export default {
  name: 'SparkSelect',
  props: {
    options: { type: Array, required: true },
    placeholder: { type: String, default: 'Select an option' },
    modelValue: { type: [String, Number], default: '' },
    label: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
    error: { type: String, default: '' },
  },
  data() {
    return {
      isOpen: false,
      searchTerm: '',
      filteredOptions: [],
      selectedValue: this.modelValue,
    };
  },

  setup(props, { emit }) {
    const hasErrors = ref(false);
    watch(
      () => props.error,
      () => {
        hasErrors.value = props.error ? true : false;
      },
      { immediate: true }
    );

    return {
      hasErrors,
    };
  },

  watch: {
    modelValue(newValue) {
      this.selectedValue = newValue;
      this.updateInputValue();
    },
    options: {
      handler() {
        this.filteredOptions = [...this.options];
        this.updateInputValue();
      },
      immediate: true,
    },
  },
  mounted() {
    document.addEventListener('click', this.closeOnClickOutside);
    this.updateInputValue();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeOnClickOutside);
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;

      if (this.isOpen) {
        this.$nextTick(() => {
          this.$refs.inputRef.focus();
        });
      }
    },
    openDropdown() {
      if (!this.isOpen) {
        this.isOpen = true;
        this.filteredOptions = [...this.options];
      }
    },
    closeDropdown() {
      this.isOpen = false;
      this.updateInputValue();
    },
    updateInputValue() {
      if (this.selectedValue) {
        const selectedOption = this.options.find(option => option.value === this.selectedValue);
        if (selectedOption) {
          this.searchTerm = selectedOption.label;
        }
      }
    },
    selectOption(option) {
      this.selectedValue = option.value;
      this.searchTerm = option.label;
      this.$emit('update:modelValue', option.value);
      this.closeDropdown();
    },
    filterOptions() {
      if (this.searchTerm === '') {
        this.filteredOptions = [...this.options];
      } else {
        const searchTermLower = this.searchTerm.toLowerCase();
        this.filteredOptions = this.options.filter(option => option.label.toLowerCase().includes(searchTermLower));
      }
    },
    closeOnClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.closeDropdown();
      }
    },
  },
};
</script>
