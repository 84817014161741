import Admin from '@/views/Admin/Admin.vue';
import AuditLog from '@/views/Admin/AuditLog/AuditLog.vue';
import Team from '@/views/Account/team/Team.vue';

const subNavigations = {
  admin: [
    {
      label: 'Audit Log',
      value: 'audit-log',
      to: { name: `audit-log` },
    },
    {
      label: 'Team',
      value: 'team',
      to: { name: `team` },
    },
    {
      label: 'Customer Portal',
      value: 'customer-portal',
      to: { name: `customer-portal` },
    },
  ],
};

const routes = [
  {
    path: '/admin-view',
    redirect: '/admin-view/audit-log',
    component: Admin,
    meta: {
      title: 'Audit Log',
      showSubNavigation: true,
      subNavigationHeading: 'Audit Log',
      requireLogin: true,
      requireStaff: false,
      requireInternal: false,
      requiredRole: ['sparkmanager', 'sparkpoweruser', 'sparkuser'],
    },
    children: [
      {
        path: 'audit-log',
        name: 'audit-log',
        component: AuditLog,
        props: true,
        meta: {
          title: 'Audit Log | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.admin,
        },
      },
      {
        path: 'https://billing.stripe.com/p/login/4gw7vKcwEahBawEeUU',
        name: 'customer-portal',
        beforeEnter() {
          window.open('https://billing.stripe.com/p/login/4gw7vKcwEahBawEeUU', '_blank');
        },
        meta: {
          title: 'Customer Portal | 3D Spark',
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          requiredRole: ['sparkmanager'],
          subNavigation: subNavigations.admin,
        },
      },
      {
        path: 'team',
        name: 'team',
        component: Team,
        meta: {
          requireLogin: true,
          requireStaff: false,
          requireInternal: false,
          title: 'Team',
          requiredRole: ['sparkmanager', 'usermanager'],
          subNavigation: subNavigations.admin,
        },
      },
    ],
  },
];

export default routes;
